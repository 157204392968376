import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserSettingsComponent } from './user-settings.component';
import { CurrentUserResolver } from '../resolvers/resolves/user.resolve';
import { AuthGuard } from '../auth/auth-guard';

const routes: Routes = [
  {
    path: '',
    component: UserSettingsComponent,
    resolve: {
      user: CurrentUserResolver
    },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserSettingsRoutingModule {
}
