<div class="modal-header">
  <button
    (click)="hide()"
    type="button"
    class="btn btn-sm btn-light btn-cancel"
  >
    <img
      src="/assets/images/book-appointment/icon-modal-close.svg"
      alt="Cancel"
    />
  </button>
</div>
<div class="modal-body">
  <h3 class="modal-title">{{ confirmTitle || "Confirm" }}</h3>
  <br />
  <ng-container *ngIf="confirmMsg?.constructor?.name !== 'Array'; else msgGrid">
    <div>{{ confirmMsg }}</div>
  </ng-container>
  <ng-template #msgGrid>
    <div class="row">
      <div
        *ngFor="let msgItem of confirmMsg"
        class="col-md-12 col-lg-6 col-xl-4"
      >
        <div>{{ msgItem }}</div>
      </div>
    </div>
  </ng-template>
  <ng-container *ngIf="itemList?.length">
    <br />
    <ol>
      <li *ngFor="let item of itemList">{{ item }}</li>
    </ol>
  </ng-container>
  <ng-container *ngIf="typeConfirm">
    <br />
    <br />
    <app-input
      [appFocus]="typeConfirm"
      [label]="'Type &quot;' + typeConfirm + '&quot; to confirm.'"
      [control]="confirmInput"
    ></app-input>
  </ng-container>
</div>
<div class="modal-footer">
  <div class="row w-100">
    <div class="col">
      <button
        #okBtn
        *ngIf="formValid"
        (click)="confirm()"
        [appFocus]="!typeConfirm"
        class="btn btn-waiting w-100 mb-2"
        type="button"
      >
        {{ ok }}
      </button>
    </div>
    <div *ngIf="confirmed$" class="col">
      <button
        *ngIf="confirmed$"
        (click)="confirm(false)"
        type="button"
        class="btn btn-danger w-100 mb-2"
      >
        Leave Without Saving
      </button>
    </div>
    <div *ngIf="cancel" class="col">
      <button
        #cancelBtn
        (click)="hide()"
        [appFocus]="!formValid"
        class="btn btn-default w-100"
        type="button"
      >
        {{ cancel }}
      </button>
    </div>
  </div>
</div>
