import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PhotoRoutingModule} from './photo-routing.module';
import { PhotoComponent } from './photo.component';
import {ReactiveFormsModule, FormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    PhotoRoutingModule,
    FormsModule
  ],
  declarations: [PhotoComponent]
})
export class PhotoModule {
}
