import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Notification } from '../../models/notification';

@Component({
  selector: 'app-notifications-view',
  templateUrl: './notifications-view.component.html',
  styleUrls: ['./notifications-view.component.scss']
})
export class NotificationsViewComponent implements OnInit {
  notifications: Notification[];

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.notifications = [];
    this.notifications = this.activatedRoute.snapshot.data['notifications'];
  }

}
