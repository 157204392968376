import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, AuthGuardChild } from "../auth/auth-guard";
import { UserNotificationResolver } from "../resolvers/resolves/notification.resolve";
import { NotificationsViewComponent } from "./notifications-view/notifications-view.component";

const routes: Routes = [
  {
    path: 'notifications',
    component: NotificationsViewComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuardChild],
    resolve: {
      notifications: UserNotificationResolver
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationsRoutingModule { }
