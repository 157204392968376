import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {CreateAccountRoutingModule} from './create-account-routing.module';
import {CreateAccountComponent} from './create-account.component';
import {SharedModule} from '../shared/shared.module';
import {InputModule} from '@shared/input/input.module';

@NgModule({
  imports: [
    CommonModule,
    CreateAccountRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    InputModule
  ],
  declarations: [CreateAccountComponent]
})
export class CreateAccountModule {
}
