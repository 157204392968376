<div class="container-fluid">
    <div class="row daytime-selector">
        <div *ngIf="hasAnyAvailableAppointments()"
             class="col-md-1 button-col-left">
            <button (click)="nextAvailable()"
                    class="next btn btn-primary">
                Next Available
            </button>
        </div>
        <div class="col-md-7 date-selector-col">
            <app-date-selector class="full-width"></app-date-selector>
        </div>
        <div class="col-md-4 button-col">
            <button (click)="selectRange('mornings')"
                    [class.active]="selectedTimeGrouping === 'morning'"
                    *ngIf="getAvailableApptsCount('mornings') > 0"
                    class="btn btn-primary">
                Morning ({{getAvailableApptsCount('mornings')}})
            </button>

            <button (click)="selectRange('afternoons')"
                    *ngIf="getAvailableApptsCount('afternoons') > 0"
                    [class.active]="selectedTimeGrouping === 'afternoon'"
                    class="btn btn-primary md-vert-margin">
                Afternoon ({{getAvailableApptsCount('afternoons')}})
            </button>

            <button (click)="selectRange('evenings')"
                    *ngIf="getAvailableApptsCount('evenings') > 0"
                    [class.active]="selectedTimeGrouping === 'evening'"
                    class="btn btn-primary">
                Evening ({{getAvailableApptsCount('evenings')}})
            </button>
        </div>
    </div>
    <div *ngIf="selectedTimeRange?.length > 0"
         class="row slots">
        <div class="col-sm-3 col-md-2 slot"
             *ngFor="let time of selectedTimeRange">
            <div style="width: 100%;"
                 *ngIf="selectedTimeRange?.length > 0">
                <div class="slot-wrapper"
                     [class.active]="time === theTime"
                     [class.disabled]="time.disabled"
                     (click)="select(time)">
                    {{time.val | momentTime}}
                </div>
            </div>
        </div>
    </div>
    <div style="margin-top: 40px; margin-bottom: 40px;"
         *ngIf="selectedTimeRange?.length === 0"
         class="row slots justify-content-center">
        <div class="col-md-9 offset-md-1 text-center text-danger no-appts">
            <div>
                Unfortunately, there are no time slots available for the
                {{selectedTimeGrouping}} of {{date.format('MM-DD-YYYY')}}.
            </div>

            <div style="margin-top: 15px;">
                <app-button [disableOnClick]="false"
                            (click)="selectRange('mornings')"
                            style="margin-right: 10px;"
                            *ngIf="mornings.length"
                            [classNames]="'btn btn-primary btn-sm btn-link'">
                    {{mornings.length}} Morning Appointments Available
                </app-button>
                <app-button [disableOnClick]="false"
                            (click)="selectRange('afternoons')"
                            *ngIf="afternoons.length"
                            style="margin-right: 10px;"
                            [classNames]="'btn btn-primary btn-sm btn-link'">
                    {{afternoons.length}} Afternoon Appointments Available
                </app-button>
                <app-button [disableOnClick]="false"
                            (click)="selectRange('evenings')"
                            *ngIf="evenings.length"
                            style="margin-right: 10px;"
                            [classNames]="'btn btn-primary btn-sm btn-link'">
                    {{evenings.length}} Evening Appointments Available
                </app-button>
            </div>
        </div>
    </div>
</div>
