import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Toast } from '@shared/toasts/toast';
import { filter as _filter } from 'lodash';

@Injectable({ providedIn: 'root' })
export class NotifyService {

  public toasts$ = new BehaviorSubject<Toast[]>([]);
  public loading$ = new BehaviorSubject<boolean>(false);
  private _btnLoader = new Subject<boolean>();

  btnLoader$ = this._btnLoader.asObservable();

  get toasts(): Toast[] {
    return this.toasts$.getValue();
  }

  getToast(id: string) {
    const toasts = this.toasts$.getValue();
    return toasts.find(t => t.id === id);
  }

  toast(toast: Partial<Toast>) {
    const toasts = this.toasts$.getValue();
    const newToast = new Toast(toast);

    if (this.isShown(newToast, toasts)) {
      if (newToast.update) { // toast exists, update toast value
        toasts.forEach((t, i) => {
          if (t.id === newToast.id) {
            t.visible = false;
            setTimeout(() => {
              const index = this.toasts$.getValue().findIndex(remove => remove.id === t.id);
              if (index !== -1) toasts.splice(index, 1)[0];
              toasts.unshift(newToast);
            }, 400);
          }
        });
      } else {
        toasts.forEach((t, i) => {
          if (t.id === newToast.id) {
            newToast.visible = true;
            Object.assign(t, newToast);
          }
        });
      }
    } else {
      toasts.unshift(newToast); // display new toast
    }
    this.toasts$.next(toasts);
  }

  cancel(id: string) {
    const toasts = this.toasts$.getValue();
    const toast = toasts.find(t => t.id === id);

    if (toast) {
      toast.visible = false;
      setTimeout(() => this.toasts$.next(_filter(toasts, t => t.id !== id)), 400);
    }
  }

  isShown(toast: Partial<Toast>, toasts: Partial<Toast>[]) {
    return Boolean(toasts.filter((t) => {
      return t.id !== undefined && t.id === toast.id;
    }).length);
  }

  set loading(load: boolean) {
    this.loading$.next(load);
  }

  get loading(): boolean {
    return this.loading$.getValue();
  }

  set btnLoading(load: boolean) {
    this._btnLoader.next(load);
  }

  update(toasts: Toast[]) {
    this.toasts$.next(toasts);
  }
}
