import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Injector,
  ElementRef,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { MainInputComponent } from '../main-input';

@Component({
  selector: 'app-number-input',
  template:
    `<div [formGroup]="formGroup" class="input-container">
      <div #group class="input-group">
          <ng-content select=".input-group-prepend"></ng-content>
          <input #input
              type="number"
              [formControlName]="controlName"
              [required]="required"
              [attr.min]="min"
              [attr.max]="max"
              [attr.step]="step"
              [attr.readonly]= "(readonly || readonly === '') ? '' : undefined"
              [ngClass]="{
                set: control.value || control.value === 0,
                first: first,
                last: last
              }"
              (keydown)="keydown($event)"
              class="form-control">
          <label for="number" *ngIf="!hideLabel && hideLabel !== ''" [ngStyle]="{ left: left }">
              {{ label || niceName }}<span *ngIf="required" class="required">*</span>
          </label>    
        </div>
   </div>
    <div *ngIf="!hideErrors && hideErrors !== ''" class="error">
      <ng-container *ngIf="control?.errors?.required && control?.touched">
        {{ requiredName || label || niceName }} is required
      </ng-container>
    </div>`
})
export class NumberInputComponent extends MainInputComponent implements OnInit, OnChanges {
  @Input() appFocus: string | boolean = false;
  @Input() prefix = '';
  @Input() min?: string;
  @Input() max?: string;
  @Input() step?: string;
  @Input() integer?: boolean | '';
  numberMask: any;

  constructor(injector: Injector, controlContainer: ControlContainer, elementRef: ElementRef) {
    super(injector, controlContainer, elementRef);
  }

  onInit = () => {
    setTimeout(() => this.setLabelPosition(), 0);
  }

  keydown(event: KeyboardEvent) {
    if (this.min && +this.min >= 0 && event.key === '-') {
      event.preventDefault();
    }

    if ((this.integer || this.integer === '') && event.key === '.') {
      event.preventDefault();
    }
  }
}