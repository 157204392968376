import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-support-form",
  templateUrl: "./support-form.component.html",
  styleUrls: ["./support-form.component.scss"],
})
export class SupportFormComponent {
  customerSupport: FormGroup;

  constructor(private fb: FormBuilder) {
    const v = Validators,
      vc = Validators.compose.bind(Validators);
    this.customerSupport = fb.group({
      firstName: ["", v.required],
      lastName: ["", v.required],
      email: ["", vc([v.required, v.email])],
      phoneNumber: ["", v.required],
      selectTopic: [""],
      message: [""],
    });
  }
}
