import { Component, ElementRef, Injector } from "@angular/core";
import { ControlContainer } from "@angular/forms";
import { MainInputComponent } from "../main-input";

const noop = () => {};

@Component({
  selector: "app-email-input",
  template: `<div [formGroup]="formGroup" class="input-container">
      <div class="control input-group" [ngClass]="{ valid: valid }">
        <input
          #input
          type="text"
          [formControlName]="controlName"
          (blur)="doBlur($event)"
          minlength="5"
          [maxlength]="maxlength"
          [required]="required"
          [ngClass]="{
            set: control.value,
            first: first,
            last: last
          }"
          [attr.autocomplete]="autocomplete || undefined"
          [attr.readonly]="readonly || readonly === '' ? '' : undefined"
          pattern="[a-zA-Z0-9.!#$%&’*+/=?^_\`{|}~-]+@[a-zA-Z0-9-]+(?:.+[a-zA-Z0-9-]+)"
          class="form-control"
        />
        <label for="text" *ngIf="!hideLabel && hideLabel !== ''">
          {{ label || niceName
          }}<span *ngIf="required" class="required">*</span>
        </label>
        <div class="input-group-append">
          <span
            class="input-group-text hms-right-radius"
            [ngClass]="{
              valid: control.valid,
              invalid: !control.valid && control.touched
            }"
            ><i class="fa fa-envelope"></i
          ></span>
        </div>
      </div>
    </div>
    <div *ngIf="!hideErrors && hideErrors !== ''" class="error">
      <ng-container *ngIf="control.touched">
        <ng-container *ngIf="control?.errors?.required; else emailFormatError">
          {{ requiredName || label || niceName }} is required
        </ng-container>
        <ng-template #emailFormatError>
          <ng-container *ngIf="control?.errors?.pattern">
            {{ requiredName || label || niceName }} format should be
            name@email.com
          </ng-container>
        </ng-template>
      </ng-container>
    </div>`,
  styleUrls: ["./email-input.component.scss"],
})
export class EmailInputComponent extends MainInputComponent {
  constructor(
    injector: Injector,
    controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super(injector, controlContainer, elementRef);
  }
}
