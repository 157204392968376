import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: '[appFadeImg]'
})
export class ImgDirective {

  constructor(private el: ElementRef) {
    Object.assign(el.nativeElement.style, {
      opacity: '0',
      transition: 'opacity .4s ease-in-out' 
    });
  }

  @HostListener('load')
  onLoad() {
    this.el.nativeElement.style.opacity = '1';
  }
}