import { Component, ElementRef, Injector, Input } from "@angular/core";
import { MainInputComponent } from "../main-input";
import { ControlContainer } from "@angular/forms";

const noop = () => {};

@Component({
  selector: "app-password-input",
  template: `<div [formGroup]="formGroup" class="input-container">
      <div class="control input-group" [ngClass]="{ valid: valid }">
        <input
          #input
          [type]="inputType"
          [formControlName]="controlName"
          [autocomplete]="autocomplete"
          ngDefaultControl
          [required]="required"
          [ngClass]="{
            set: control.value,
            last: ![true, ''].includes(this.allowShow)
          }"
          [attr.autocomplete]="autocomplete || undefined"
          [attr.readonly]="readonly || readonly === '' ? '' : undefined"
          class="form-control"
        />
        <label for="text" *ngIf="!hideLabel && hideLabel !== ''">
          {{ label || niceName
          }}<span *ngIf="required" class="required">*</span>
        </label>
        <div
          *ngIf="[true, ''].includes(this.allowShow)"
          class="input-group-append"
        >
          <span
            class="input-group-text hms-right-radius"
            [ngClass]="{
              valid: control.valid,
              invalid: !control.valid && control.touched
            }"
          >
            <button type="button" (click)="toggleVisible()" class="pw-show p-2">
              <i
                class="fa {{
                  inputType === 'text' ? 'fa-eye' : 'fa-eye-slash'
                }}"
              ></i>
            </button>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="!hideErrors && hideErrors !== ''" class="error">
      <ng-container *ngIf="control?.errors?.minlength && control?.touched">
        {{ requiredName || label || niceName }} must be a minimum of
        {{ control.errors?.minlength?.requiredLength }} characters.
      </ng-container>
      <ng-container *ngIf="control?.errors?.required && control?.touched">
        {{ requiredName || label || niceName }} is required
      </ng-container>
    </div>`,
  styleUrls: ["./password-input.component.scss"],
})
export class PasswordInputComponent extends MainInputComponent {
  @Input() allowShow: boolean | "";
  inputType: "text" | "password" = "password";

  constructor(
    injector: Injector,
    controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super(injector, controlContainer, elementRef);
  }

  toggleVisible() {
    if ([true, ""].includes(this.allowShow)) {
      this.inputType = this.inputType === "text" ? "password" : "text";
    }
  }
}
