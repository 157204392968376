import { Pipe, PipeTransform } from '@angular/core';
import { get as _get } from 'lodash';

@Pipe({ name: 'appPhoneTo' })
export class PhoneToPipe implements PipeTransform {
  transform(record: any, path?: string) {
    if (path) {
      const phone = _get(record, path);
      return phone ? `tel:${(phone).replace(/[^\d]/g, '')}` : '';
    }
    return '';
  }
}
