<div class="noselect position-relative image-uploader">
  <label *ngIf="label">{{ label }}</label>
  <div class="d-flex margin-bottom-15 {{ flexAlign }}">
    <div
      (click)="open()"
      class="upload-img-container d-flex w-100"
      (dragover)="enableDrop($event)"
      (dragleave)="resetDrop($event)"
      (drop)="select($event)"
      [ngStyle]="{'pointer-events': control.disabled ? 'none' : 'all'}"
    >
      <ng-container *ngIf="!blobUrl && !preview">
        <div *ngIf="!placeholderImg" [ngStyle]="phDimension" class="img-placeholder d-flex">
          <div class="m-auto">Click here to select image.</div>
        </div>
        <img
          appFadeImg
          *ngIf="placeholderImg"
          class="upload-img m-auto placeholder"
          [ngClass]="{ 'profile-img': useCamera === '' || useCamera === true }"
          [src]="placeholderImg"
          [ngStyle]="phDimension"
          alt="placeholder"
        />
      </ng-container>
      <button
        *ngIf="!required && (control?.value || preview) && !control.disabled"
        (click)="delete($event)"
        [ngClass]="{ touch: touch }"
        type="button"
        class="btn btn-danger delete-img"
      >
        <i class="fa fa-times"></i>
      </button>
      <img
        appFadeImg
        *ngIf="blobUrl || preview"
        class="upload-img m-auto"
        [ngClass]="{ 'profile-img': useCamera === '' || useCamera === true }"
        [src]="blobUrl || preview"
        name="upload"
        [ngStyle]="dimension"
      />
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <input
      #file
      type="file"
      name="file-{{ label }}"
      id="file-{{ label }}"
      class="inputfile inputfile-6"
      [accept]="accept"
      (change)="select($event, file)"
      [ngStyle]="{ display: 'none' }"
    />
  </div>
  <ng-container *ngIf="!control.disabled">
    <div *ngIf="useCamera === '' || useCamera === true" class="image-btn-container">
      <div class="d-flex justify-content-between">
        <button class="btn btn-primary btn-sm" data-testid="getCameraBtn" type="button" (click)="getCamera(cameraModal)">
          &nbsp;<i class="fa fa-camera"></i>&nbsp;
        </button>
        <button #last class="btn btn-primary btn-sm" type="button" (click)="open()">
          &nbsp;<i class="fa fa-photo"></i>&nbsp;
        </button>
      </div>
      <br />
    </div>
    <div
      *ngIf="!(useCamera === '' || useCamera === true)"
      class="d-flex flex-container {{ flexAlign }} add-photo-btn"
      [ngStyle]="{ opacity: !control?.value && !preview ? 1 : undefined }"
    >
      <button (click)="open()" type="button" class="btn add-image btn-white" [style.width]="dimension.width">
        {{ blobUrl || preview ? 'Edit' : 'Add' }} Photo
      </button>
    </div>
  </ng-container>
</div>

<ng-template #cameraModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Camera</h4>
    <a href="#" (focus)="last.focus()" class="tab-loop">to last</a>
    <button #first type="button" class="close pull-right" (click)="hide()" aria-label="Close">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="camera-container modal-body">
    <div class="flex-container flex-center">
      <app-camera #camera (photo)="getPhoto($event)" [showProfile]="showProfile"></app-camera>
    </div>
    <br />
    <div
      class="d-flex"
      [ngClass]="{
        'justify-content-end': !camera.savedPhoto,
        'justify-content-between': camera.savedPhoto
      }"
    >
      <button *ngIf="camera.savedPhoto" class="btn btn-default" type="button" (click)="camera.reset()">
        &nbsp;<i class="fa fa-undo"></i>&nbsp;
      </button>
      <button
        #last
        class="btn btn-success"
        [disabled]="!camera.isStreaming"
        [ngClass]="{
          'btn-primary': !camera.savedPhoto,
          'btn-success': camera.savedPhoto
        }"
        type="button"
        (click)="camera.savedPhoto ? camera.getPhoto() : camera.snapPhoto()"
      >
        &nbsp;
        <i *ngIf="!camera.savedPhoto" class="fa fa-camera"></i>
        <i *ngIf="camera.savedPhoto" class="fa fa-check"></i>
        &nbsp;
      </button>
    </div>
    <a href="#" (focus)="first.focus()" class="tab-loop">to first</a>
  </div>
</ng-template>
