import { DateTime } from 'luxon';

function times(hour: number) {
  let date = DateTime.now();
  date = date.startOf('day').set({ hour, minute: 0, second: 0 });

  return Array(12).fill(null).map((_, index) => {
    if (index !== 0) {
      date = date.plus({ minutes: 5 });
    }

    return {
      name: date.toFormat('h:mma'),
      value: date.toFormat('HH:mm:ss'),
      disabled: true
    }
  });
}

export const timeSlots = [
  {
    name: 'Morning',
    value: [
      { name: '12:00am to 1:00am', value: times(0) },
      { name: '1:00am to 2:00am', value: times(1) },
      { name: '2:00am to 3:00am', value: times(2) },
      { name: '3:00am to 4:00am', value: times(3) },
      { name: '4:00am to 5:00am', value: times(4) },
      { name: '5:00am to 6:00am', value: times(5) },
      { name: '6:00am to 7:00am', value: times(6) },
      { name: '7:00am to 8:00am', value: times(7) },
      { name: '8:00am to 9:00am', value: times(8) },
      { name: '9:00am to 10:00am', value: times(9) },
      { name: '10:00am to 11:00am', value: times(10) },
      { name: '11:00am to Noon', value: times(11) },
    ]
  }, {
    name: 'Afternoon',
    value: [
      { name: 'Noon to 1:00pm', value: times(12) },
      { name: '1:00pm to 2:00pm', value: times(13) },
      { name: '2:00pm to 3:00pm', value: times(14) },
      { name: '3:00pm to 4:00pm', value: times(15) },
      { name: '4:00pm to 5:00pm', value: times(16) },
      { name: '5:00pm to 6:00pm', value: times(17) },
    ]
  },
  {
    name: 'Evening',
    value: [
      { name: '6:00pm to 7:00pm', value: times(18) },
      { name: '7:00pm to 8:00pm', value: times(19) },
      { name: '8:00pm to 9:00pm', value: times(20) },
      { name: '9:00pm to 10:00pm', value: times(21) },
      { name: '10:00pm to 11:00pm', value: times(22) },
      { name: '11:00pm to 12:00pm', value: times(23) }
    ]
  }
];