import {
  Input,
  Injector,
  ViewChild,
  ElementRef,
  OnInit,
  OnChanges,
  OnDestroy,
  Directive,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormGroup,
  UntypedFormControl,
  ControlContainer
} from '@angular/forms';
import { Subject } from 'rxjs';
import { get as _get } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { setNiceName } from 'app/utils/format-string';

@Directive({ selector: '[appMainInput]' })
// tslint:disable-next-line:directive-class-suffix
export class MainInputComponent implements OnInit, OnChanges, OnDestroy {
  _control = new UntypedFormControl();
  _formGroup = new UntypedFormGroup({ '': this._control });
  @Input()
  set control(control) {
    if (control) {
      this._control = control;
    }
  };
  @Input() controlName: string = '';
  @Input() autocomplete!: string;
  @Input() placeholder = '';
  @ViewChild('input', { static: true }) public input!: ElementRef;
  @Input() appFocus!: string | boolean;
  @Input() hideLabel!: '' | boolean;
  @Input() hideErrors?: '' | boolean;
  @Input() maxlength!: string;
  @Input() label!: string;
  @Input() requiredName!: string;
  @Input() readonly?: boolean | '';
  @Output() blur = new EventEmitter<Event>();
  @ViewChild('group', { static: true }) group!: ElementRef;
  niceName = '';
  mask: string = '';
  valid: boolean | undefined = false;
  left: string = '';
  destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor
    (
      public injector: Injector,
      public controlContainer: ControlContainer,
      public elementRef: ElementRef
    ) { }

  ngOnInit() {
    this.niceName = setNiceName(this.controlName);
    this.valid = _get(this.control, 'valid');

    if (this.appFocus || this.appFocus === '') setTimeout(() => this.input?.nativeElement.focus(), 0);
    if (this.control) {
      this.control.statusChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe((status: string) => this.valid = status === 'VALID');
    }
    this.onInit();
  }

  get control() {
    return this.formGroup?.get(this.controlName) as UntypedFormControl || this._control;
  }

  get required() {
    if (this.control?.validator) {
      return _get(this.control?.validator({} as AbstractControl), 'required', false);
    }
    return false;
  }

  get formGroup() {
    return this.controlContainer.control as UntypedFormGroup || this._formGroup;
  }

  get first() {
    return !this.elementRef?.nativeElement?.getElementsByClassName('input-group-prepend')?.length;
  }

  get last() {
    return !this.elementRef?.nativeElement?.getElementsByClassName('input-group-append')?.length;
  }

  onInit = () => { };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.name) setTimeout(() => this.niceName = setNiceName(this.controlName), 0);
  }

  focus() {
    this.input?.nativeElement?.focus();
  }

  doBlur(event: Event) {
    if (!/\S/.test(this.control.value)) this.control.setValue('');
    else this.control.setValue((this.control.value || '').trim());
    this.blur.emit(event);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  setLabelPosition() {
    if (this.group?.nativeElement) {
      const children = this.group.nativeElement.getElementsByClassName('input-group-prepend');
      let width = 0;
      [].forEach.call(children, (child: HTMLElement) => {
        width = +child.offsetWidth;
      });
      this.left = Math.max(width + 5, 9) + 'px';
    }
  }
}
