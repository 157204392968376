import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { CreateAccountModule } from './create-account/create-account.module';
import { AboutModule } from './about/about.module';
import { SharedModule } from './shared/shared.module';
import { PhotoModule } from './photo/photo.module';
import { UserSettingsModule } from './user-settings/user-settings.module';
import { ResolversModule } from './resolvers/resolvers.module';
import { NotificationsModule } from './notifications/notifications.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpRequestInterceptor } from "./interceptors/http-request.interceptor";
 
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CreateAccountModule,
    AboutModule,
    SharedModule,
    PhotoModule,
    ResolversModule,
    UserSettingsModule,
    NotificationsModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule { }
