import { Component, OnInit, Input } from '@angular/core';
import {AppointmentService} from '../../services/appointment.service';
import {Appointment} from '../../models/appointment';

@Component({
  selector: 'app-session-photos-small',
  templateUrl: './session-photos-small.component.html',
  styleUrls: ['./session-photos-small.component.scss']
})
export class SessionPhotosSmallComponent implements OnInit {
  @Input()
  appointment: Appointment;
  photos: any[];

  constructor(private appt: AppointmentService) { }

  ngOnInit() {
    this.appt.getPhotosForAppointment(this.appointment?.id)
      .then(appt => {
        this.photos = appt.photos.filter(photo => photo);
      });
  }

}
