import { Injectable, inject } from '@angular/core';
import {
  Resolve,
  ActivatedRoute,
  Router,
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot
} from '@angular/router';

import { UserService } from '@services/user.service';
import { AppointmentService } from '@services/appointment.service';
import { CheckinService } from '@services/checkin.service';
import { FulfillmentService } from "@services/fulfillment.service";
import { tap } from 'rxjs/operators';
import { DateTime } from 'luxon';

export const CheckinAppointmentsResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  checkin: CheckinService = inject(CheckinService)

) => {
  const { search, location, date, checkedIn, flagged, page } = route.queryParams;
  return checkin.searchAppointments(
    {
      search,
      location: location || 1,
      date: date || DateTime.now().toFormat('yyyy-MM-dd'),
      checkedIn: checkedIn || false,
      flagged: flagged || false
    }, 25, page - 1 || 0);
}

export const SingleApptResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  appointment: AppointmentService = inject(AppointmentService)
) => {
  const appointmentId = route.params.id;
  return appointment.getAppointment(appointmentId);
}


@Injectable()
export class UserAppointmentResolver implements Resolve<any> {
  constructor(
    private appointmentService: AppointmentService,
    private userService: UserService
  ) {
  }

  resolve() {
    return this.appointmentService.getUserAppointments(this.userService.getUserId());
  }
}

export const UpcomingAppointmentsResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  appointmentService: AppointmentService = inject(AppointmentService),
  userService: UserService = inject(UserService)
) => {
  const asUser = +route.params.as_user;
  return appointmentService.getUpcomingAppointments(asUser || userService.getUserId());
}


@Injectable()
export class PackageResolver implements Resolve<any> {
  constructor(private appointmentService: AppointmentService,
    private route: ActivatedRoute) {
  }

  resolve() {
    return this.appointmentService.getAllPackages();
  }
}


@Injectable()
export class SinglePhotoResolver implements Resolve<any> {
  constructor(private appointmentService: AppointmentService,
    private route: ActivatedRoute, private router: Router) {
  }

  resolve(snapshot: ActivatedRouteSnapshot) {

    let photoId = snapshot.params['id'];
    return this.appointmentService.getPhotoById(photoId);
  }
}


@Injectable()
export class PhotoSizesResolver implements Resolve<any> {
  constructor(private appointmentService: AppointmentService,
    private route: ActivatedRoute) {
  }

  resolve() {
    return this.appointmentService.getPhotoSizes();
  }
}

@Injectable()
export class AlaCarteResolver implements Resolve<any> {
  constructor(private appointmentService: AppointmentService,
    private route: ActivatedRoute) {
  }

  resolve(snapshot: ActivatedRouteSnapshot) {
    let data = {
      appId: snapshot.params['appId'],
      photoId: snapshot.params['photoId']
    };
    return this.appointmentService.getCarteAppointments(data);
  }
}


@Injectable()
export class FulfillmentResolver implements Resolve<any> {
  constructor(private appointmentService: AppointmentService,
    private route: ActivatedRoute) {
  }

  resolve() {
    return this.appointmentService.getFulfillment();
  }
}

@Injectable()
export class CartResolver implements Resolve<any> {
  constructor(private fulfillmentService: FulfillmentService, private user: UserService) {
  }

  resolve(snapshot: ActivatedRouteSnapshot) {
    let user_id = this.user.getUserId();
    return this.fulfillmentService.setCart(user_id);
  }
}


@Injectable()
export class CartAppsResolver implements Resolve<any> {
  constructor(private fulfillmentService: FulfillmentService, private user: UserService) {
  }

  resolve(snapshot: ActivatedRouteSnapshot) {
    let user_id = this.user.getUserId();
    return this.fulfillmentService.getCartAppointments(user_id);
  }
}








