<div class="notification"
     (click)="view(notification)"
     *ngIf="notification">
    <span class="fa-stack fa-lg">
      <i [class.viewed]="notification.read"
         class="fa fa-circle fa-stack-2x"></i>
      <i class="fa fa-bell fa-stack-1x fa-inverse"></i>
    </span>
    <div class="row justify-content-center">
        <div class="col-md-12 col-lg-11 offset-md-1">
            <div class="title">{{notification.subject | ellipsis: 23: false}}
            </div>
            <div class="body">
                {{notification.message | ellipsis: 25}}
            </div>
        </div>
    </div>
</div>
