import { pick as _pick } from 'lodash';
import { Base } from './base';

export class PackagePhotoSize extends Base {
  name: string;
  quantity: number;
  package_id: number;
  photo_size_id: number;

  constructor(data?: Partial<PackagePhotoSize>) {
    super(data);

    Object.assign(this, _pick(data, [
      'name',
      'quantity',
      'package_id',
      'photo_size_id'
    ]));
  }
}
