<div class="time-container pt-3">
  <h1>
    {{ heading }}
    <div
      *ngIf="error"
      class="d-inline-block"
      [popover]="
        'Google calendar sync not currently active. Re-authorize account to start syncing calendars again.'
      "
      triggers="mouseenter:mouseleave"
    >
      <i class="fa fa-warning p-2 text-danger error-icon"></i>
    </div>
  </h1>
  <p *ngIf="location">{{ location }}</p>
  <p class="text-secondary">{{ description }}</p>
  <div class="row">
    <div class="col-xl-5 pb-3">
      <bs-datepicker-inline
        [(bsValue)]="date"
        [minDate]="isOverrides ? undefined : currentDate"
        (bsValueChange)="setDate()"
        [bsConfig]="{
          containerClass: 'theme-default',
          showWeekNumbers: false,
          customTodayClass: 'today'
        }"
      ></bs-datepicker-inline>
    </div>
    <div class="col-xl-7">
      <div
        *ngIf="openHoursForm && isOverrides"
        [formGroup]="openHoursForm"
        class="card p-3 mb-3"
      >
        <h3>Operating hours</h3>
        <ng-container
          *ngIf="openHoursForm.value[dateString]"
          [formGroupName]="dateString"
        >
          <div class="row">
            <div class="col-md-6">
              <app-time-input controlName="open" />
            </div>
            <div class="col-md-6">
              <app-time-input controlName="close" />
            </div>
            <div class="col-12">
              <app-slider-input
                controlName="blockAll"
                label="Block all of today's sessions"
              />
            </div>
          </div>
        </ng-container>
      </div>
      <div class="card">
        <tabset #dayPartTabs [justified]="true">
          <ng-container *ngFor="let slot of timeSlots">
            <tab *ngIf="!slot.disabled" [heading]="slot.name">
              <ng-container *ngFor="let times of slot.value">
                <div *ngIf="!times.disabled" class="p-3">
                  <h2>{{ times.name }}</h2>
                  <div class="row">
                    <div
                      *ngFor="let time of times.value"
                      class="col-lg-3 col-md-4 col-sm-6 pb-2"
                    >
                      <button
                        (click)="
                          isOverrides ? setOverride(time) : setTime(time)
                        "
                        type="button"
                        class="btn btn-sm btn-light w-100"
                        [ngClass]="{
                          selected:
                            time.value === selectedTime?.value ||
                            (formOverrides
                              | appOverrideSelected
                                : dateString + 'T' + time.value),
                          delete:
                            formOverrides
                            | appDeleteOverride : dateString + 'T' + time.value,
                          override: isOverrides
                        }"
                        [disabled]="!date || time.disabled"
                      >
                        {{ time.name }}
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </tab>
          </ng-container>
        </tabset>
      </div>
    </div>
    <div
      *ngIf="reschedule || isOverrides"
      class="col d-flex justify-content-end nav-container btn-bar"
      [ngClass]="{
        complete: bookingForm.valid || overrides || overrides === ''
      }"
    >
      <app-submit-button
        *ngIf="reschedule; else overrideBtn"
        (clicked)="rescheduleAppointment()"
        text="Reschedule"
        classNames="btn btn-danger"
        [loading]="loading"
        [disabled]="!bookingForm?.valid"
        disabledStyle
      />
      <ng-template #overrideBtn>
        <app-submit-button
          *ngIf="isOverrides"
          (clicked)="saveOverrides()"
          text="Save overrides"
          classNames="btn btn-danger"
          [loading]="loading"
          [disabled]="!bookingForm?.get('overrides')?.valid"
        />
      </ng-template>
    </div>
  </div>
</div>
