import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from "../shared/shared.module";
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  declarations: [MainLayoutComponent, AdminLayoutComponent],
  exports: [MainLayoutComponent, AdminLayoutComponent]
})
export class LayoutModule {
}
