import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserSettingsRoutingModule } from './user-settings-routing.module';
import {UserSettingsComponent} from './user-settings.component';
import {SharedModule} from '../shared/shared.module';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {InputModule} from '@shared/input/input.module';

@NgModule({
  imports: [
    CommonModule,
    UserSettingsRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    InputModule
  ],
  declarations: [UserSettingsComponent]
})
export class UserSettingsModule { }
