import { Input, EventEmitter, Output, HostListener, Directive, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import {
  get as _get,
  set as _set
} from 'lodash';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TableColumn } from './table-column';
import { TableAction } from '@interface/table-action';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmComponent } from '@shared/confirm/confirm.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotifyService } from '@services/notify.service';

@Directive({
  selector: '[mainTableDirective]'
})
export class MainTableComponent implements OnInit, OnDestroy {

  @Input() records: any[] = [];
  @Input() columns: TableColumn[] = [];
  @Input() actions: TableAction[] = [];
  @Input() headers: boolean = true;
  @Input() condensed: boolean = false;
  @Input() notStriped: boolean | '' = false;
  @Input() sort?: string;
  @Input() flagged?: string;
  @Input() trackBy = 'id';
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() hover = true;
  @Input() totalItems: number = 0;
  @Input() pageSize: number = 10;
  @Input() pageControl?: UntypedFormControl;
  @Input() emptyTitle!: string;
  @Input() emptyMessage!: string;
  @Input() maxSize = 14;
  @Output() update = new EventEmitter<any>();
  @Output() actionChange = new EventEmitter<{ action: string, record: any }>();
  @ViewChild('scroll', { static: true }) scroll!: ElementRef;
  height!: string;
  DDOpen = false;
  defaultDDOpen?: string;
  loading = false;
  _get = _get;

  destroyed$ = new Subject();

  constructor(
    public modalService: BsModalService,
    public notify: NotifyService
  ) { }

  ngOnInit() {
    if (this.pageControl) {
      this.pageControl.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe(page => setTimeout(() => this.loading = true, 0));
      this.notify.loading$
        .pipe(takeUntil(this.destroyed$))
        .subscribe(loading => setTimeout(() => this.loading = false, 500));
    }
  }

  do(event: Event, value: { action: string, record: any }) {
    if (value.action !== 'row-action') {
      event.stopPropagation();
    }

    if (!this.DDOpen) {
      if (value.action === 'sort') {
        if (this.sort === value.record.sort) {
          this.sort = `-${value.record.sort}`;
        } else if (this.sort === `-${value.record.sort}`) {
          this.sort = undefined;
        } else {
          this.sort = value.record.sort;
        }
        this.actionChange.emit({ action: 'sort', record: { sort: this.sort } });
      } else {
        this.actionChange.emit(value);
      }
    }
  }

  @HostListener('window:click')
  setDDOpen(open = false, event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }

    this.DDOpen = open;
  }

  confirmUpdateColValue(event: MouseEvent, record: any, col: TableColumn, value: string | number, index: number) {
    event.stopPropagation();
    const { name, value: key, options } = col;

    const displayValue = options?.find(option => option.value === value)?.name || value;
    const initialState: Partial<ConfirmComponent> = {
      confirmTitle: `Set ${name} to ${displayValue}`,
      confirmMsg: `Are you sure you want to update ${name} to ${displayValue}?`,
      record: { record, key, value, index },
      cb: this.updateColValue
    };

    this.modalService.show(ConfirmComponent, { initialState });
  }

  updateColValue = ({ record, key, value, index }: { record: any, key: string | undefined, value: string | number, index: number }) => {
    if (key) {
      const control = this.form.get(`${index}.${key}`);

      if (control) {
        control.setValue(value);
      } else {
        _set(record, key, value);
        this.records[index] = { ...record };
      }

      this.update.emit(record);
    }
  }

  @HostListener('document:click')
  toggleDefaults(event?: Event, value?: string) {
    event?.stopPropagation();
    if (!value && this.defaultDDOpen) {
      this.defaultDDOpen = undefined;
    } else if (this.defaultDDOpen === value) {
      this.defaultDDOpen = undefined;
    } else {
      this.defaultDDOpen = value;
    }
  }

  setDefault(value: any, index: number) {
    const record = this.records[index];
    Object.keys(value).forEach(key => {
      const control = this.form.get(`${index}.${key}`);
      if (control) {
        control.setValue(value[key]);
      } else {
        record[key] = value[key];
        this.records[index] = { ...record };
      }
    });

    this.update.emit(record);
    this.toggleDefaults();
  }

  refreshValue(value: string, index: number) {
    const control = this.form.get(`${index}.${value}`);
    control?.patchValue(control.value);
  }

  trackByFn = (index: number, item: any) => _get(item, this.trackBy);
  columnTrackByFn = (index: number, item: any) => item.name + item.value;

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}