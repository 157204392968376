import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'appMimeToType' })
export class MimeToTypePipe implements PipeTransform {
  transform(mimeTypes: string[] | string) {
    if (typeof mimeTypes === 'string') mimeTypes = mimeTypes.split(',');
    return mimeTypes.map((value) => {
      switch (value) {
        case 'application/pdf':
          return 'PDF';
        case 'application/json':
          return 'JSON';
        case 'text/javascript':
          return 'Javascript';
        case 'text/html':
          return 'HTML';
        case 'text/csv':
          return 'CSV';
        case 'text/plain':
        case 'text/txt':
          return 'Text';
        case 'image/jpeg':
          return 'JPEG';
        case 'image/png':
          return 'PNG';
        case 'application/vnd.ms-excel':
        case '.xlsx':
          return 'Excel';
        case '.doc':
          return 'Word';
        case 'video/mp4':
          return 'MP4'
        default:
          return null;
      }
    }).filter(type => !!type);
  }
}