import {Injectable} from '@angular/core';
import {CapitalizePipe} from '../shared/capitalize.pipe';
import {HttpService} from './api.service';
import {environment} from '../../environments/environment';
import { NotifyService } from './notify.service';

@Injectable({ providedIn: 'root' })
export class SocialLoginService {
  socialDetails: any = {
    firstName: '',
    lastName: '',
    email: '',
    id: ''
  };
  socialLoginErrs: any = {};

  constructor(private capitalize: CapitalizePipe, private http: HttpService, private notify: NotifyService) {
  }

  /**
   * Find a login strategy for a given type.
   *
   * @param {string} type
   * @returns {() => void}
   */
  findStrategy(type: string) {
    const strat = `with${this.capitalize.transform(type)}`;
    if (typeof this[strat] !== 'function') {
      return () => this.notify.toast({
        id: 'strategy-not-implemented',
        msg: `This login strategy, ${strat} is not implemented.`,
        type: 'warning',
        update: true
      });
    }
    return this[strat].bind(this);
  }

  /**
   * Facilitate a login with Google.
   */
  withGoogle() {
    window.location.href = (`${environment.api}/v1/auth/google`);
  }

  /**
   * Facilitate a login with Facebook.
   */
  withFacebook() {
    window.location.href = (`${environment.api}/v1/auth/facebook`);
  }

  /**
   *
   * @param token
   * Takes temporary token returned from social media details and requests a clean
   * token from our server.
   */
  socialLogin(token: string): Promise<any> {
    return this.http.post(`/user/login-token`, {token: token})
      .toPromise()
      .then((user) => {
        return user;
      });
  }

  /**
   * Handle errors when they occur.
   *
   * @param err
   */
  // TODO: Is there even a good reason to have a handleError function in a service?  Unless there is a unfied way then the calling code should handle the errors, and not squash them like this.
  handleError(err: any) {
    console.log('the error', JSON.parse(err._body));
    return err;
  }
}
