import { pick as _pick } from 'lodash';
import { Base } from "./base";

export class Role extends Base {
  public description: string;
  public nice_name: string;
  public access: number;

  constructor(data?: Partial<Role>) {
    super(data);

    Object.assign(this, _pick(data, [
      'description',
      'nice_name',
      'access'
    ]));
  }
}
