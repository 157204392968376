import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { AppointmentService } from '../services/appointment.service';
import { Photo } from '../models/photo';
import { FulfillmentService } from '../services/fulfillment.service';
import { User } from '../models/user';
import { UserService } from '../services/user.service';
import { AlaCarte } from '../models/ala-carte';
import { Appointment } from '../models/appointment';
import {
  find as _find,
  filter as _filter,
  merge as _merge,
  remove as _remove
} from 'lodash';
import { Cart } from '../models/cart';
import { Subscription } from 'rxjs';
import { ErrorResponse } from '../services/api.service';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit {
  photo: Photo;
  photoSizes: any[];
  carteForm: any[];
  noPrints = false;
  order: any;
  alert: any;
  user: User;
  showAddress: boolean = false;
  alaCarteApps: AlaCarte[];
  appointment: Appointment;
  cart: Cart[];
  subscription: Subscription;
  hideSetPhoto: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private router: Router,
    private userService: UserService,
    private fulfillmentService: FulfillmentService,
    private appointmentService: AppointmentService
  ) { }

  ngOnInit() {
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

    this.user = this.route.snapshot.data['user'];

    this.route.params.subscribe(params => {
      this.appointmentService.findPhoto(params.id)
        .subscribe(data => {
          this.photo = data;
        });
    });

    this.alert = { type: null, message: null };
    this.photo = this.route.snapshot.data['photo'];
    this.photoSizes = this.route.snapshot.data['photoSizes'];
    this.carteForm = [];
    this.cart = this.route.snapshot.data['cart'];
    this.setOrder();
    this.setAppt();
    this.setCarteForm();
    this.checkCartItems();
    this.checkFulfill();
  }

  /**
   *
   * @returns {Promise<Cart[]>}
   */
  checkFulfill() {
    const query = { user_id: this.user?.id };
    return this.fulfillmentService.searchCart(query)
      .then((res) => {
        const cart = _find(res, {
          appointment_id: this.appointment.id,
          package_order: true
        });
        if (cart && cart.ready_for_fulfillment) {
          this.hideSetPhoto = true;
        }
      });
  }

  checkCartItems() {
    // look to see if the current cart has items that are a la carte items
    const alaCart = _filter(this.cart, {
      appointment_id: this.appointment?.id,
      photo_id: this.photo?.id,
      package_order: false
    });
    if (alaCart.length > 0) {
      //  match cart items with current form
      alaCart.forEach((a) => {
        const cartItem = _find(this.carteForm, { photo_size_id: a.photo_size_id });
        cartItem ? _merge(cartItem, a) : cartItem;
      });
    }

  }

  /**
   * Get the order from the server.
   */
  setOrder() {
    this.fulfillmentService.getApptOrder(this.photo?.appointment_id)
      .then((res) => {
        this.order = res[0];
      });
  }

  /**
   * Find the appointment from the route resolver.
   */
  setAppt() {
    const appts = this.route.snapshot.data['appt'];
    this.appointment = _find(appts, { id: this.photo?.appointment_id });
  }

  /**
   * Setup the cartform object.
   */
  setCarteForm() {
    (this.photoSizes || []).forEach((p) => {
      const cart = {
        quantity: 0,
        size: p.size,
        price: p.price,
        photo_id: this.photo.id,
        photo_size_id: p.id,
        appointment_id: this.photo?.appointment_id,
        package_order: false,
        user_id: this.user['id']
      };
      this.carteForm.push(cart);
    });
  }

  createAlaCarte() {
    const data: any = [];
    // Filter out where no quantity provided
    this.carteForm.forEach((p) => {
      if (p.quantity > 0) {
        data.push(p);
      }
    });

    // Check for quantity form
    if (data.length === 0) {
      this.noPrints = true;
      setTimeout(() => {
        this.noPrints = false;
      }, 3000);
      return;
    } else {
      this.saveCartItems(data);
    }
  }

  /*
  * Creates new cart items
  * and updates cart items
  * */
  saveCartItems(cart: any) {
    const newCart = [];
    const _cart = [];
    cart.forEach((c) => {
      if (!c.id) {
        newCart.push(c);
      } else {
        _cart.push(c);
      }
    });

    if (newCart.length > 0) {
      // Save and update the new cart items
      this.fulfillmentService.createCart(newCart)
        .then((res) => {
          if (res['err']) {
            console.log(res);
          } else {
            res.map((r) => {
              _cart.push(r);
            });

            // Update the cart items
            this.fulfillmentService.updateCart(_cart)
              .then(() => {
                this.setAlert('alert-success', 'Your changes have been saved!');
              });
          }
        });
    } else {
      // Just Update the cart items
      this.fulfillmentService.updateCart(_cart)
        .then((res) => {
          this.setAlert('alert-success', 'Your changes have been saved!');
        });
    }

  }

  setPhoto() {
    this.order.photo_id = this.photo.id;
    return this.fulfillmentService.updateOrder(this.order)
      .then((res) => {
        if (res['err']) {
          this.setAlert('alert-danger', 'Uh Oh! There was an issue saving the data');
        } else {
          this.showAddress = false;
          this.setAlert('alert-success', 'Your changes have been saved!');
        }
      });
  }

  /*
  * Create Cart items from appointment package photo
  * */
  createCart() {
    const data = [];
    const _cart = {
      appointment_id: this.appointment.id,
      order_id: this.order.id,
      photo_id: this.photo.id,
      user_id: this.user['id'],
      package_order: true,
    };
    data.push(_cart);
    this.saveCart(data);
  }

  /*
  * Send Cart items to fulfillment service to save in db
  * */
  saveCart(data: any) {
    return this.fulfillmentService.createCart(data)
      .then((res) => {
        if (res['err']) {
          // TODO: How to handle this error?
          console.log(res);
        } else {
          this.cart.push(res[0]);
          this.setPhoto();
        }
      });
  }

  /*
  * Checks to see if there is currently a package photo
  * If there is it replaces it with photo on this view and updates cart
  * If not it creates a new cart item
  * */
  updatePhoto() {
    if (this.order.originating_order && this.order.photo_id) {
      // find the cart items that package_order and then update them with the new photo_id

      const data = [];
      const _cart = _find(this.cart, {
        package_order: true,
        appointment_id: this.appointment.id
      });
      _cart.photo_id = this.photo['id'];
      data.push(_cart);

      this.fulfillmentService.updateCart(data)
        .then((res) => {
          this.setPhoto();
        });
    } else {
      this.createCart();
    }
  }

  /**
   * Alert message on succesful db changes
   * */
  setAlert(type: string, msg: string) {
    this.alert = { type: type, message: msg };
    setTimeout(() => {
      this.alert = { type: null, message: null };
    }, 2000);
  }

  goBack() {
    this.router.navigateByUrl(`/home/session/${this.photo.appointment_id}`);
  }

  /*
  * Method to remove photo as package photo
  * then rmove cart items from DB
  * */
  removePhoto() {
    this.order.photo_id = null;
    this.fulfillmentService.updateOrder(this.order)
      .then((res) => {
        if (res['err']) {
          // TODO: How to handle this error?
          console.log(res);
        } else {
          const ord = _find(this.cart, {
            appointment_id: this.appointment.id,
            package_order: true
          }),
            data = [ord];

          _remove(this.cart, { id: ord.id });
          this.removeCartItems(data);
        }
      });
  }

  /*
  * Service method to remove cart items
  *
  * */
  removeCartItems(data: any) {
    //Send back an array of the cart items to remove
    this.fulfillmentService.removeCartItems(data)
      .then((res) => {
        this.setAlert('alert-success', 'Your changes have been saved!');
      });
  }

  /*
  * Save a person's address before saving photo as package photo
  *
  * */
  savePersonAddress() {
    this.userService.updatePerson(this.user.person)
      .then((res) => {
        if (res['err']) {
          // TODO: How to handle this error?
          console.log(res);
        } else {
          this.updatePhoto();
        }
      })
      .catch((err: ErrorResponse) => {
        this.setAlert('alert-danger', 'Uh Oh! There an error occurred. Please try again');
      });
  }
}
