import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { Notification } from '../models/notification';
import { NotificationsService } from '../services/notifications.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  notifications: Notification[];
  isLoggedIn: boolean;
  isLoggedOut: boolean;

  constructor(
    private userService: UserService,
    private notificationService: NotificationsService,
  ) {
    this.isLoggedIn = userService.getToken() && !userService.tokenExpired();
    this.isLoggedOut = !userService.getToken() || userService.tokenExpired();
  }

  ngOnInit() {
    if (this.userService.getToken() && !this.userService.tokenExpired()) {
      this.notificationService.getUserNotifications(this.userService.getUserId())
        .then((res) => {
          this.notifications = res;
        })
    }
  }
}
