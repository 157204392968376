import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  UserAppointmentResolver,
  PackageResolver,
  SinglePhotoResolver,
  PhotoSizesResolver,
  AlaCarteResolver,
  FulfillmentResolver,
  CartResolver,
  CartAppsResolver,
} from "./resolves/appointment.resolve";

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    AlaCarteResolver,
    CartResolver,
    CartAppsResolver,
    FulfillmentResolver,
    PackageResolver,
    SinglePhotoResolver,
    PhotoSizesResolver,
    UserAppointmentResolver,
  ],
})
export class ResolversModule {}
