import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { Cart } from '../../models/cart';
import { FulfillmentService } from '../../services/fulfillment.service';
import { Subscription } from 'rxjs';
import { NavigationComponent } from '@shared/navigation/navigation.component';
import { User } from '@models/user';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  cart: Cart[];
  subscription: Subscription;
  interval: any;
  hasRefreshed = 0;

  preLogout = () => {
    this.navigation?.signOut();
  };

  navTop = [
    {
      name: "Book Appointment",
      url: "/book",
      icon: "/assets/images/navigation/icon-book-appointment.svg",
    },
    {
      name: "My Appointments",
      url: "/home",
      icon: "/assets/images/navigation/icon-my-appointments.svg",
    },
    {
      name: "My Photos",
      url: "/my-photos",
      icon: "/assets/images/navigation/icon-my-photos.svg",
    },
  ];
  navBottom = [
    {
      name: "My Account",
      url: "/user-settings",
    },
    {
      name: "FAQ",
      url: "/help",
    },
    {
      name: "Customer Support",
      url: "/support",
    },
    {
      name: "Share Feedback",
      url: undefined,
    },
    {
      name: "Log Out",
      fn: this.preLogout,
    },
  ];
  minNavHeight = 500;
  asUser?: User;

  @ViewChild('navigation') public navigation: NavigationComponent;

  constructor(
    private route: ActivatedRoute,
    private fulfill: FulfillmentService,
    private user: UserService
  ) {
    this.asUser = this.route.snapshot.data?.asUser;
  }

  ngOnInit() {
    this.cart = [];

    // this.getCart();
    this.interval = setInterval(() => {
      if (this.hasRefreshed !== 1) {
        if (this.user.getToken() && !this.user.tokenExpired()) {
          this.getCart();
          this.hasRefreshed++;
        }
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  /**
   * Get a users cart objects.
   */
  getCart() {
    this.fulfill.setCart(this.user.getUserId())
      .then((res) => {
        this.subscription = this.fulfill.cart$
          .subscribe((carts) => {
            this.cart = carts;
          });
      });
  }
}
