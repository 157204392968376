<div class="container-fluid">
    <div class="row" *ngIf="photos?.length">
        <div *ngFor="let photo of photos" class="col-md-3">
            <img src="{{photo.signedUrlThumb}}"
                 alt="">
        </div>
    </div>
    <div class="row" *ngIf="!photos?.length">
        <div class="col">
            <h3>No photos for this session.</h3>
        </div>
    </div>
</div>