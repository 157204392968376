import { pick as _pick } from 'lodash';
import { Base } from './base';

export class Photo extends Base {
  original: string;
  thumbnail: string;
  appointment_id: number;
  local_created_at: Date;
  signedUrl: string;

  constructor(data?: any) {
    super(data);

    Object.assign(this, _pick(data, [
      'original',
      'thumbnail',
      'appointment_id',
      'local_created_at',
      'signedUrl'
    ]));
  }
}
