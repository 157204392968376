<button
  [disabled]="disabled && disabledStyle === false"
  (click)="onClick()"
  type="button"
  class="{{ classNames }}"
  [ngClass]="{
    loading: loading,
    disabled: disabled
  }"
>
  <span class="text">{{ text }}</span>
</button>
