import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  HostListener,
  TemplateRef,
  OnDestroy,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {
  get as _get,
  clone as _clone
} from 'lodash';
import { NotifyService } from '@services/notify.service';
import {
  BsModalService,
  BsModalRef
} from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import {
  DomSanitizer,
  SafeResourceUrl
} from '@angular/platform-browser';
import { UntypedFormGroup, AbstractControl, ControlContainer, UntypedFormControl } from '@angular/forms';
import { ConfirmComponent } from '@shared/confirm/confirm.component';
import { HttpService } from '@services/api.service';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  providers: [BsModalService]
})
export class ImageUploaderComponent implements OnInit, OnChanges, OnDestroy {
  _control = new UntypedFormControl();
  _formGroup = new UntypedFormGroup({ '': this._control });
  @Input()
  set control(control) {
    if (control) {
      this._control = control;
    }
  };
  @Input() label?: string;
  @Input() placeholder!: string;
  @Input() controlName!: string;
  @Input() parentGroup!: UntypedFormGroup;
  @Input() showProfile: boolean = false;
  @Input() width!: number | 'full';
  @Input() height!: number;
  @Input() useCamera?: boolean | '';
  @Input() confirmSave: boolean | '' = false;
  @Input() align: 'left' | 'center' | 'right' = 'center';
  @Input() preview?: string | SafeResourceUrl | null;
  @Output() previewChange = new EventEmitter<string | null>();
  @ViewChild('file', { static: true })
  public file!: ElementRef;
  @Output() imageUploaded = new EventEmitter<any>();
  @Output() imageSelected = new EventEmitter();
  blobUrl!: SafeResourceUrl | null;
  clone!: SafeResourceUrl;
  dimension: any = {};
  phDimension: any = {};
  placeholderImg?: string | null;
  accept = '.jpg,.jpeg,.png,.svg,.gif';
  maxSize = 700;
  modalRef?: BsModalRef | null;
  modal_sub!: Subscription;
  flexAlign: 'justify-content-start' | 'justify-content-end' | 'justify-content-center' |
    'justify-content-between' | 'justify-content-around' = 'justify-content-center';
  touch = false;
  public editing = false;

  constructor(
    private api: HttpService,
    private notify: NotifyService,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    private controlContainer: ControlContainer
  ) {
  }

  ngOnInit() {
    this.touch = 'ontouchstart' in document.documentElement || false,
    this.dimension = this.setDimension();
    this.phDimension = this.setDimension(true);
    this.placeholderImg = this.placeholder || (this.width && this.width !== 'full' && this.height ? `https://via.placeholder.com/${this.width}x${this.height}` : null);

    switch (this.align) {
      case 'left':
        this.flexAlign = 'justify-content-start';
        break;
      case 'right':
        this.flexAlign = 'justify-content-end';
        break;
      default:
        this.flexAlign = 'justify-content-center';
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.height || changes.width) {
      this.dimension = this.setDimension();
      this.phDimension = this.setDimension(true);  
    }
  }

  get control() {
    return this.formGroup?.get(this.controlName) as UntypedFormControl || this._control;
  }

  get required() {
    if (this.control?.validator) {
      return _get(this.control?.validator({} as AbstractControl), 'required', false);
    }
    return false;
  }

  get formGroup() {
    return this.controlContainer.control as UntypedFormGroup || this._formGroup;
  }

  public open() {
    this.file.nativeElement.click();
  }

  private setDimension(isPlaceholder = false) {
    return {
      width: this.width === 'full' ? '100%' : this.width + 'px',
      height: this.height + 'px',
      maxWidth: '100%',
      objectFit: 'cover',
      padding: isPlaceholder && this.placeholder ? '10px' : '0',
      objectPosition: '50% 50%'
    };
  }

  enableDrop(event: DragEvent) {
    if (event.target) {
      (event.target as HTMLElement).style.filter = 'brightness(90%)';
    }
    if (event.dataTransfer) {
      event.dataTransfer.effectAllowed = 'copy';
      event.dataTransfer.dropEffect = 'copy';
    }
  }

  resetDrop(event: any) {
    event.target.style.filter = null;
  }

  select(event: Event, input?: HTMLInputElement) {
    if (event.target) {
      ((event.target as HTMLElement).style.filter as string | null) = null;
      const fileList: FileList = _get(event, 'target.files', _get(event, 'dataTransfer.files', {})) as FileList;
      if (!fileList.length) { return; }

      this.getBase64(fileList);
      if (input) input.value = '';
    }
  }

  @HostListener('window:drop', ['$event']) preventDrop(event: Event) {
    event.preventDefault();
  }

  @HostListener('window:dragover', ['$event']) preventDrag(event: Event) {
    event.preventDefault();
  }

  public getBase64(files: any) {
    const ext = files[0].name.substr(files[0].name.lastIndexOf('.'));

    if (this.accept.includes(ext)) {
      this.clone = _clone(this.control.value);
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        // limit by image width if portrait
        if (width > height) {
          if (width > this.maxSize) {
            height *= this.maxSize / width;
            width = this.maxSize;
          }
          // limit by image height if landscape
        } else {
          if (height > this.maxSize) {
            width *= this.maxSize / height;
            height = this.maxSize;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const context = canvas.getContext('2d');

        if (context) {
          context.drawImage(img, 0, 0, canvas.width, canvas.height);
        }

        // Get scaled image data
        this.control.setValue(canvas.toDataURL('image/jpeg'));
        const blob = this.api.toBlob(this.control.value);
        if (blob) {
          this.blobUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
        }
      };

      const url = URL.createObjectURL(files[0]);
      img.src = url;
    } else {
      this.notify.toast({
        msg: 'File type not allowed.',
        type: 'warning',
        id: 'file-not-allowed'
      });
    }
  }

  getPhoto(image: { photo: string; }) {
    this.clone = _clone(this.control.value);
    this.control.setValue(image.photo);
    const blob = this.api.toBlob(image.photo);
    if (blob) {
      this.blobUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
    }
    this.hide();
  }

  cancel() {
    this.editing = false;
    if (this.clone) {
      this.control.setValue(this.clone);
    }
  }

  getCamera(cameraModal: TemplateRef<any>) {
    if (!this.modalRef) {
      this.modalRef = this.modalService.show(
        cameraModal,
        Object.assign({}, { class: 'modal-camera' })
      );
      this.modal_sub = this.modalService.onHidden
        .subscribe(() => {
          this.modalRef = null;
          this.modal_sub.unsubscribe();
        });
    }
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  deleteImage = () => {
    this.control.setValue(null);
    this.blobUrl = null;
    this.previewChange.emit(null);
  }

  delete(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const initialState: Partial<ConfirmComponent> = {
      confirmTitle: 'Remove Image',
      confirmMsg: `Are you sure you want to permanently remove this image?`,
      record: null,
      cb: this.deleteImage
    };
    this.modalService.show(ConfirmComponent, { initialState, class: 'confirm-modal' });
  }

  ngOnDestroy() {
    this.hide();
  }
}
