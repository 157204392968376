import { Pipe, PipeTransform } from '@angular/core';
import { MimeToTypePipe } from './mime-to-type.pipe';
import { get as _get, isArray as _isArray } from 'lodash';
import { DateTime } from 'luxon';
import { TableColumn } from './table-column';

@Pipe({ name: 'appValue' })
export class ValuePipe implements PipeTransform {
  transform(data: any, col: TableColumn = { type: 'string', wrap: false }) {
    let value = data;
    let wrap = col.wrap;

    if (window.innerWidth < 810) {
      wrap = true;
    }

    if (col.value && _isArray(col.value)) {
      const values = (col.value as string[]).map((cv, index) => {
        const rawValue = _get(data, cv);
        const type = col.type?.[index] || 'string';

        return this.getValue(rawValue, type, col);
      });
      return values.join('\r\n');
    } else if (col.value) {
      const rawValue = _get(data, col.value);
      const type = col.condition ? (col.type as Function)(value[col.condition]) : col.type;
      return this.getValue(rawValue, type as string, col);
    }
  }

  getValue(value: string, type: string, col: TableColumn) {
    let wrap = col.wrap;
    let returnValue;

    if (window.innerWidth < 810) {
      wrap = true;
    }

    switch (type) {
      case 'number':
        returnValue = ![undefined, null].includes(value) ? value : '';
        break;
      case 'string':
        returnValue = value && !wrap ? value.split(' ').join('\xa0') : (value || col.emptyValue || '');
        break;
      case 'boolean':
        returnValue = value ? 'Yes' : 'No';
        break;
      case 'date':
        if (!value) return col.emptyValue || '';
        returnValue = DateTime.fromISO(value).setZone('utc').toFormat(`MMM${wrap ? ' ' : '\xa0'}d,${wrap ? ' ' : '\xa0'}yyyy`);
        break;
      case 'datetime':
        if (!value) return col.emptyValue || '';
        returnValue = DateTime.fromISO(value).toFormat(`MMM${wrap ? ' ' : '\xa0'}d,${wrap ? ' ' : '\xa0'}yyyy${wrap ? ' ' : '\xa0'}h:mm${wrap ? ' ' : '\xa0'}a`);
        break;
      case 'time':
        if (!value) return col.emptyValue || '';
        returnValue = DateTime.fromISO(value).toFormat(`h:mm${wrap ? ' ' : '\xa0'}a`);
        break;
      case 'abbr':
        returnValue = value ? value.match(/\b\w/g).join('') : (col.emptyValue || '');
        break;
      case 'phone':
        if (value) {
          const clean = value.replace(/[^\d]/g, '')
          const code = clean.slice(0, 3);
          const prefix = clean.slice(3, 6);
          const number = clean.slice(6);
          returnValue = `(${code}) ${prefix}-${number}`;
        } else {
          returnValue = '';
        }
        break;
      case 'miles':
        if ([null, undefined, ''].includes(value)) return col.emptyValue || '';
        returnValue = `${Math.round((+value + Number.EPSILON) * 100) / 100}${wrap ? ' ' : '\xa0'}mi`;
        break;
      case 'percent':
        returnValue = value ? `${value}%` : (col.emptyValue || '0%');
        break;
      case 'filetype':
        returnValue = new MimeToTypePipe().transform(value);
        break;
      default:
        returnValue = typeof value === 'string' && !wrap ? value.split(' ').join('\xa0') : (col.emptyValue || value);
        break;
    }
    return `${col.prepend || ''}${returnValue}${col.append || ''}`;
  }
}
