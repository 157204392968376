import { Component, OnDestroy, ViewChild, EventEmitter, AfterViewInit } from '@angular/core';
import { SchedulingService } from '../../services/scheduling.service';
import { DateSelectorComponent } from '../date-selector/date-selector.component';
import * as R from 'ramda';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnDestroy, AfterViewInit {
  @ViewChild(DateSelectorComponent)
  private dateSelector: DateSelectorComponent;
  private subscriptions: Subscription[] = [];

  // Time Groupings
  mornings: any[];
  afternoons: any[];
  evenings: any[];

  // The currently selected time grouping
  selectedTimeRange: any;
  selectedTimeGrouping: string;

  // Our staring point
  date: any = moment();
  theTime: any;
  selectedTime: EventEmitter<any> = new EventEmitter();

  constructor(private scheduling: SchedulingService) {
    this.setRanges();
    this.scheduling.buildHours(); // Call this so that our setRanges subscription will work
  }

  public ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  /**
   * Determines if any appointments are available for the day.
   * @returns {boolean}
   */
  hasAnyAvailableAppointments(): boolean {
    return this.getAvailableApptsCount('mornings') > 0 ||
      this.getAvailableApptsCount('afternoons') > 0 ||
      this.getAvailableApptsCount('evenings') > 0;
  }

  /**
   * Finds the available appts and returns the length;
   * @param range
   * @returns {any[]}
   */
  getAvailableApptsCount(range): number {
    const r = this[range];

    if (!r || !r.length) {
      return 0;
    }

    return r.filter(a => !a.disabled).length;
  }

  /**
   * Builds a subscription to the date selector service, so when the date
   * changes, the available appts update themselves.
   *
   * @param {string} range
   */
  setRanges(range: string = null) {
    if (this.subscriptions.length > 0)
      return;

    this.subscriptions.push(this.scheduling.hoursBuilt.subscribe(() => {
      // TODO: investigate this; we subscribe to a recast subject as observable so we can only then inspect the service's public members...
      this.mornings = this.scheduling.mornings;
      this.afternoons = this.scheduling.afternoons;
      this.evenings = this.scheduling.evenings;

      // ... and here we perform additional processing... shouldn't this sort of code be in the pipeline?
      const group = range && this[range] ? range : this.scheduling.findGroup(moment().hours()) + 's';

      this.selectedTimeRange = this[group];
      this.setTimeGroupingString(group);
    }));
  }

  /**
   * Sets a reference to the selected time range for reference in the UI.
   *
   * @param {string} group
   */
  setTimeGroupingString(group: string) {
    this.selectedTimeGrouping = group.replace(/s$/, '');
  }

  ngAfterViewInit() {
    this.dateSelector?.selectedDate.subscribe(moment => {
      this.setRanges('mornings');
      this.scheduling.buildHours(moment);

      this.date = moment;
      this.theTime = null;
    });
  }

  /**
   * Sets the visible range (Morning, Afternoon, or Evening)
   *
   * @param {string} range
   */
  selectRange(range: string) {
    this.selectedTimeRange = this[range];
    this.setTimeGroupingString(range);
  }

  /**
   * Select a time.  Should find the selectedDate, set the proper time, and emit
   * the newly scheduled date / time.
   *
   * @param time
   */
  select(time) {
    if (time.disabled) {
      return;
    }

    this.theTime = time;
    const d = this.date.clone();
    d.hours(time.val.hours());
    d.minutes(time.val.minutes());
    d.seconds(0);
    d.milliseconds(0);

    this.selectedTime.emit(d);
    this.scheduling.publishSchedule(d);
  }

  /**
   * Find the next available slot.
   */
  nextAvailable() {
    const hours = this.scheduling.hours,
      nowOnOut = R.filter(item => item.val > moment(), hours),
      first = R.find(R.propEq('disabled', false), nowOnOut),
      range = this.scheduling.findGroup(first.val.hours());

    this.selectRange(`${range}s`);
    this.select(first);
  }
}
