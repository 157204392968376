import { Component, Input } from "@angular/core";
import { User } from "@models/user";
import { UserService } from "@services/user.service";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent {
  open = false;
  transition?: string;
  accessLevel: number;
  @Input() minHeight = 500;
  @Input() asUser?: User;

  preLogout = () => {
    // this.show = false;
    this.signOut();
  };

  @Input() navTop: { name: string, url: string, icon: string }[] = [];
  @Input() navBottom: { name: string, url?: string, fn?: Function }[] = [];

  constructor(private userService: UserService) {
    const userAccessLevels = (this.userService.getUser()?.userroles || []).map(userRole => userRole.roles?.access);
    this.accessLevel = Math.max(...userAccessLevels);
   }

  /**
   * Sign a user out.
   */
  signOut() {
    this.toggleNav(false);
    this.userService.logout();
  }

  toggleNav(open: boolean) {
    this.transition = "transform .2s ease-in-out";
    setTimeout(() => {
      this.open = open;
      setTimeout(() => {
        this.transition = undefined;
      }, 200);
    }, 0);
  }
}
