<div class="container-fluid main">
  <div class="row">
    <div class="col-md-3" *ngIf="isLoggedIn">
      <app-weather></app-weather>
      <app-notifications [notifications]="notifications"></app-notifications>
    </div>
    <div [ngClass]="{
    'col-md-9 order-first-xs': isLoggedIn,
    'col-md-12': isLoggedOut
    }">
      <div class="about">
        <div class="row">
          <div class="col-md-8">
            <h1>About Us</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor eum libero non quas? Aperiam ea eligendi eum facere fuga fugit iusto modi nihil possimus unde! Aspernatur atque magni rem similique. Consectetur adipisicing elit. Dolor eum libero non quas? Aperiam ea eligendi eum facere fuga fugit iusto modi nihil possimus unde! Aspernatur atque magni rem similique.</p>
          </div>
          <div class="col-md-4">
            <div class="row justify-content-center">
            <span class="fa-stack fa-lg fa-5x">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-camera fa-stack-1x fa-inverse"></i>
          </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
