<div [formGroup]="dateGroup" class="input-container">
  <div class="control date-group input-group">
    <ng-container *ngIf="dateTimeSupport || type === 'date'">
      <input
        #dateInput
        [type]="type"
        formControlName="_dateTime"
        (blur)="onBlur($event)"
        [ngClass]="{
          open: datePicker.isOpen,
          set: control.value,
          first: first,
          last: last
        }"
        [required]="required"
        [attr.max]="max || '9999-12-31'"
        [attr.min]="min || '1900-01-01'"
        class="form-control"
      />
      <label *ngIf="!hideLabel && hideLabel !== ''">
        {{ label || niceName }}<span *ngIf="required" class="required">*</span>
      </label>
    </ng-container>
    <div
      *ngIf="!dateTimeSupport && type !== 'date'"
      class="input-group date-time-group"
    >
      <input
        #dateInput
        type="date"
        formControlName="_date"
        (blur)="onBlur($event)"
        [required]="required"
        [attr.max]="max || '9999-12-31'"
        [ngClass]="{
          open: datePicker.isOpen,
          set: control.value
        }"
        [attr.min]="min || '1900-01-01'"
        class="form-control date-control"
      />
      <label *ngIf="!hideLabel && hideLabel !== ''">
        {{ label || niceName }}<span *ngIf="required" class="required">*</span>
      </label>
      <input
        #timeInput
        type="time"
        formControlName="_time"
        (blur)="onBlur($event)"
        [required]="required"
        [attr.max]="max || '9999-12-31'"
        [ngClass]="{ open: datePicker.isOpen }"
        [attr.min]="min || '1900-01-01'"
        class="form-control time-control"
      />
    </div>
    <input
      class="hidden"
      type="text"
      #datePicker="bsDatepicker"
      [placement]="placement"
      tabindex="-1"
      (onHidden)="dpBlur()"
      formControlName="_pickDate"
      bsDatepicker
      [bsConfig]="{
        customTodayClass: 'cal-today',
        containerClass: 'theme-default',
        showWeekNumbers: false,
        adaptivePosition: true,
        isAnimated: true
      }"
    />
    <div class="input-group-append">
      <button
        class="input-group-text date-btn"
        type="button"
        (click)="toggleDate(datePicker, dateInput)"
        [disabled]="control.disabled"
        [ngClass]="{
          valid: control.valid,
          invalid: !control.valid && control.touched
        }"
      >
        <i class="fa fa-calendar"></i>
      </button>
    </div>
  </div>
</div>
<div *ngIf="!hideErrors && hideErrors !== ''" class="error">
  <ng-container *ngIf="control?.errors?.required && control?.touched"
    >{{ niceName }} is required.</ng-container
  >
</div>
