<div *ngIf="appts?.length" class="upcoming-appts container-fluid">
    <div class="row">
        <div class="col">
            <h2>Upcoming Appointments</h2>
        </div>
    </div>
    <div *ngFor="let a of appts" class="row date-row">
        <div class="col">
            {{a.mdate.format('MMM')}}. {{a.mdate.format('Do')}},
            {{a.mdate.format('YYYY')}} @ {{a.mdate.format('h:mm a')}}
        </div>
    </div>
</div>
