import { Injectable } from '@angular/core';
import { HttpService } from './api.service';
import { User } from '../models/user';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DateTime } from 'luxon';



@Injectable({ providedIn: 'root' })
export class AdminService {

  constructor(private http: HttpService) {
  }

  getAllUsers(
    { itemsPerPage, offset, search, location, role, csv, customers }:
      { itemsPerPage?: number, offset?: number, search?: string, location?: number, role?: number, csv?: boolean, customers?: boolean }
  ): Observable<{ users: User[], total: number, itemsPerPage: number, page: number }> {
    const params = JSON.parse(JSON.stringify({
      itemsPerPage,
      offset,
      search,
      location,
      role,
      csv,
      customers: false
    }));

    return this.http.get('/person/search', params)
      .pipe(map((res: any) => {
        if (res.csv) {
          this.downloadFile(res.csv);
        }

        return {
          users: (res.users || []).map(user => new User(user)),
          total: res.total,
          itemsPerPage: res.itemsPerPage,
          page: res.page
        }
      }));
  }

  downloadFile(data: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.download = `customers ${DateTime.now().toFormat('yyyy-MM-dd hh:mm')}.csv`;
    anchor.href = url;
    anchor.click();
  }
}
