import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, ResolveFn } from '@angular/router';
import { UserService } from '../../services/user.service';
import { NotificationsService } from "../../services/notifications.service";

export const UserNotificationResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  notificationsService: NotificationsService = inject(NotificationsService),
  userService: UserService = inject(UserService)
) => {
  const userId = userService.getUserId();
  return notificationsService.getUserNotifications(userId);
}

export const SingleNotificationResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  notificationsService: NotificationsService = inject(NotificationsService)
) => {
  const notId = route.params['id'];
  return notificationsService.getNotification(notId);
}