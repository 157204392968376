import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CapitalizePipe } from "./capitalize.pipe";
import { ButtonComponent } from "./button/button.component";
import { WeatherComponent } from "./weather/weather.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { NotificationComponent } from "./notification/notification.component";
import { SupportComponent } from "./support/support.component";
import { RouterModule } from "@angular/router";
import { EllipsisPipe } from "./ellipsis.pipe";
import { SingleImageComponent } from "./single-image/single-image.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ScheduleComponent } from "./schedule/schedule.component";
import { DateSelectorComponent } from "./date-selector/date-selector.component";
import { MomentTimePipe } from "./moment-time.pipe";
import { ClickOutside } from "../directives/click-outside.directive";
import { DropdownMenuComponent } from "./dropdown-menu/dropdown-menu.component";
import { UpcomingApptComponent } from "./upcoming-appt/upcoming-appt.component";
import { SessionPhotosSmallComponent } from "./session-photos-small/session-photos-small.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TabsModule } from "ngx-bootstrap/tabs";
import { AgmCoreModule } from "@agm/core";
import { environment } from "@environment";
import { ToastsComponent } from "./toasts/toasts.component";
import { ToastComponent } from "./toasts/toast/toast.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { DynamicTableDirective } from "./table/dynamic-table.directive";
import { TableMobileComponent } from "./table/table-mobile/table-mobile.component";
import { TableComponent } from "./table/table.component";
import { ValuePipe } from "./table/value.pipe";
import { PhoneToPipe } from "./table/phone-to.pipe";
import { MailToPipe } from "./table/mail-to.pipe";
import { GetPipe } from "./table/get.pipe";
import { IsArrayPipe } from "./table/is-array.pipe";
import { BsTypePipe } from "./table/bs-type.pipe";
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ConfirmComponent } from "./confirm/confirm.component";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { OptionNamePipe } from "./table/option-name.pipe";
import { NoRecordsComponent } from "./no-records/no-records.component";
import { InputModule } from "./input/input.module";
import { ContactCardComponent } from './support/contact-card/contact-card.component';
import { SupportFormComponent } from './support/support-form/support-form.component';
import { BookTimeComponent } from './book-time/book-time.component';
import { DeleteOverridePipe } from "./book-time/delete-override.pipe";
import { OverrideSelectedPipe } from "./book-time/override-selected.pipe";
import { SubmitButtonComponent } from "./submit-button/submit-button.component";
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CameraComponent } from "./camera/camera.component";
import { ImageUploaderComponent } from "./image-uploader/image-uploader.component";
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PackageCardsComponent } from './package-cards/package-cards.component';
import { ImgDirective } from "@directives/fade-in-image.directive";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    InputModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gApiKey,
    })
  ],
  declarations: [
    CapitalizePipe,
    CameraComponent,
    ButtonComponent,
    SubmitButtonComponent,
    WeatherComponent,
    BookTimeComponent,
    ClickOutside,
    ConfirmComponent,
    ImgDirective,
    OverrideSelectedPipe,
    DeleteOverridePipe,
    NotificationsComponent,
    NotificationComponent,
    SupportComponent,
    EllipsisPipe,
    ImageUploaderComponent,
    SingleImageComponent,
    DynamicTableDirective,
    NoRecordsComponent,
    TableComponent,
    TableMobileComponent,
    ToastComponent,
    ToastsComponent,
    OptionNamePipe,
    ValuePipe,
    GetPipe,
    IsArrayPipe,
    PhoneToPipe,
    MailToPipe,
    BsTypePipe,
    NavigationComponent,
    ScheduleComponent,
    DateSelectorComponent,
    MomentTimePipe,
    DropdownMenuComponent,
    UpcomingApptComponent,
    SessionPhotosSmallComponent,
    ContactCardComponent,
    SupportFormComponent,
    PackageCardsComponent,
  ],
  providers: [CapitalizePipe, MomentTimePipe],
  exports: [
    AgmCoreModule,
    AccordionModule,
    PopoverModule,
    BsDatepickerModule,
    BsDropdownModule,
    BookTimeComponent,
    OverrideSelectedPipe,
    DeleteOverridePipe,
    DynamicTableDirective,
    TableComponent,
    TableMobileComponent,
    TabsModule,
    ValuePipe,
    GetPipe,
    ImgDirective,
    IsArrayPipe,
    BsTypePipe,
    ImageUploaderComponent,
    ButtonComponent,
    SubmitButtonComponent,
    WeatherComponent,
    NavigationComponent,
    NotificationsComponent,
    NotificationComponent,
    SupportComponent,
    EllipsisPipe,
    SingleImageComponent,
    ToastComponent,
    ToastsComponent,
    ScheduleComponent,
    DateSelectorComponent,
    DropdownMenuComponent,
    UpcomingApptComponent,
    SessionPhotosSmallComponent,
    PackageCardsComponent,
  ],
})
export class SharedModule { }
