import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Toast } from '../toast';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements AfterViewInit {
  timer!: NodeJS.Timer;
  hovered: boolean = false;
  icon = {
    danger: 'fa-exclamation',
    success: 'fa-check',
    warning: 'fa-exclamation-triangle',
    info: 'fa-info-circle',
    bomb: 'fa-bomb',
  };

  @Input() toast = new Toast();
  @Output() closed = new EventEmitter<Toast>();

  constructor(
    private router: Router
  ) { }

  ngAfterViewInit() {
    if (!this.toast.visible) {
      setTimeout(() => this.toast.visible = true, 30);
    }

    const timeout = this.toast.timeout;

    if (timeout) {
      this.timer = setTimeout(() => this.close(), timeout);
    }
  }

  setHovered(hovered: boolean) {
    this.hovered = hovered;
    const timeout = this.toast.timeout;

    if (timeout && !this.hovered) {
      this.timer = setTimeout(() => this.close(), timeout);
    } else {
      clearTimeout(this.timer);
    }
  }

  do(action: Function) {
    action(this.toast.data);
    this.close();
  }

  viewLink() {
    if (this.toast.link) {
      this.router.navigateByUrl(this.toast.link);
    }

    this.close();
  }

  close() {
    clearTimeout(this.timer);
    this.toast.visible = false;
    setTimeout(() => this.closed.emit(this.toast), 400);
  }

  trackByFn = (index: number) => index;
}
