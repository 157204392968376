import { Injectable } from '@angular/core';
import { HttpService } from './api.service';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FreeBusy } from '@models/free-busy';

@Injectable({
  providedIn: 'root'
})
export class FreeBusyService {

  constructor(
    private http: HttpService
  ) { }

  getFreeBusy(start: string, end: string, location: number): Observable<any> {
    const query = {
      get: ['*'],
      where: {
        condition: [
          { and: ['location_id', '=', location] },
          { and: ['date', '>=', start] },
          { and: ['date', '<=', end] }
        ]
      }
    };

    return this.http.post('/freebusy/get', query)
      .pipe(map((res) => res.data.map(freeBusy => new FreeBusy(freeBusy))));
  }

  bulkSaveFreeBusy(freeBusy: FreeBusy[], locationId: number): Observable<{
    message: string,
    statusCode: number,
    timestamp: number,
    data: { newFreeBusy: FreeBusy[], updatedFreeBusy: FreeBusy[], deletedFreeBusy: FreeBusy[] }
  }> {
    const deleteIDs = freeBusy.filter(fb => fb.delete === true)
      .map(fb => fb.id);
    const newFreeBusy = freeBusy.filter(fb => [null, false].includes(fb.delete) && !fb.id);
    const updateIDs = freeBusy.filter(fb => [null, false].includes(fb.delete) && fb.id)
      .map(fb => fb.id);;

    if (freeBusy.length || updateIDs.length || deleteIDs.length) {
      return this.http.post(`/freebusy/save/${locationId}`, {
        freeBusy: newFreeBusy,
        updateIDs,
        deleteIDs
      });
    } else {
      return of({
        message: 'No bulk updates',
        statusCode: 304,
        timestamp: new Date().valueOf(),
        data: {
          newFreeBusy: [],
          updatedFreeBusy: [],
          deletedFreeBusy: [],
        }
      });
    }
  }
}
