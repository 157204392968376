import {Component, OnInit} from '@angular/core';
import {AppointmentService} from '../../services/appointment.service';
import * as moment from 'moment';

@Component({
  selector: 'app-upcoming-appt',
  templateUrl: './upcoming-appt.component.html',
  styleUrls: ['./upcoming-appt.component.scss']
})
export class UpcomingApptComponent implements OnInit {
  appts: any[];

  constructor(private appt: AppointmentService) {
  }

  ngOnInit() {
    this.appt.getUpcoming()
      .then(appts => {
        this.appts = (appts ||[]).map(appt => {
          appt.mdate = moment(appt.date);
          return appt;
        });
      });
  }
}
