import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../../services/notifications.service';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent implements OnInit {
  temp: string;
  day: string;
  time: string;
  user: any = {};
  greetings: any;

  getGreetingTime(m) {
    let g = null;
    const afternoon = 12;
    const evening = 17;
    const currentHour = parseFloat(m.toFormat('HH'));

    if (currentHour >= afternoon && currentHour <= evening) {
      g = 'Afternoon';
    } else if (currentHour >= evening) {
      g = 'Evening';
    } else {
      g = 'Morning';
    }
    return g;
  }

  constructor(
    private userService: UserService,
    private notifications: NotificationsService
  ) {
    const m = DateTime.now();
    this.day = m.toFormat('dddd');
    this.time = m.toFormat('h:mm a');
    this.user.name = '';
    this.greetings = this.getGreetingTime(m);
  }

  ngOnInit() {
    this.notifications.getWeather()
      .subscribe(temp => {
        this.temp = temp;
      });

    this.userService.getCurrentUser()
      .subscribe((data: User) => {
        this.user.name = data.person.first_name;
      });
  }
}
