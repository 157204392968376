import {
  Component,
  Injector,
  Input,
  ElementRef,
  SimpleChanges,
} from '@angular/core';
import {
  ControlContainer,
} from '@angular/forms';
import { MainInputComponent } from '../main-input';
import { setNiceName } from 'app/utils/format-string';

@Component({
  selector: 'app-boolean-input',
  template:
    `<label for="number" *ngIf="!hideLabel && hideLabel !== ''">
      {{ label || niceName }}
    </label>
    <div>
      <button [disabled]="control?.disabled" type="button" class="btn btn-{{control?.value ? 'yes' : 'light'}}" (click)="toggle()"
              [ngClass]="{'has-icon': config.hasIcon}">
        <span *ngIf="config.hasIcon || config.hasIcon === ''" class="icon-container"><ng-content select=".icon"></ng-content></span>
        {{control?.value ? (config.trueValue || 'Yes') : (config.falseValue || 'No')}}
      </button>
    </div>
    <div *ngIf="!hideLabel && hideLabel !== ''" class="error">
    <ng-container *ngIf="control?.errors?.required && control?.touched">
      {{ requiredName || label || niceName }} is required
    </ng-container>
    </div>`,
  styleUrls: ['./boolean-input.component.scss'],
})
export class BooleanInputComponent extends MainInputComponent {

  @Input() config: { trueValue?: string, falseValue?: string, hasIcon?: boolean | '' } = { trueValue: null, falseValue: null, hasIcon: false };

  constructor(
    injector: Injector,
    controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super(injector, controlContainer, elementRef);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.name) setTimeout(() => this.niceName = setNiceName(this.controlName), 0);
  }

  toggle() {
    this.control.setValue(!this.control.value);
  }
}
