import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output
} from '@angular/core';

@Component({
  selector: 'app-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent implements OnInit {
  @Input() classNames = 'btn btn-light';
  @Input() text = '';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() disabledStyle: boolean | '' = false;
  @Output() clicked = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  /**
   * When our button is clicked.
   */
  onClick() {
    if (!this.loading) {
      this.clicked.emit(true);
    }
  }
}
