import { DateTime } from "luxon";

export function formatTime(time: string, format: string = 'HH:mm') {
  if (!time) return null;
  const h = DateTime.fromFormat(time, 'HH');
  const hm = DateTime.fromFormat(time, 'HH:mm');
  const hms = DateTime.fromFormat(time, 'HH:mm:ss');
  const hmsz = DateTime.fromFormat(time, 'HH:mm:ssZ');

  return [h, hm, hms, hmsz].find(t => t.isValid).toFormat(format);
}
