import { Pipe, PipeTransform } from '@angular/core';
import { get as _get } from 'lodash';
import { TableColumn } from './table-column';

@Pipe({ name: 'appOptionName' })
export class OptionNamePipe implements PipeTransform {
  transform(data: any, col: TableColumn = { type: 'string', wrap: false }) {
    return col.options.find(option => option.value === _get(data, col.value) || '')?.name || 'none selected';
  }
}
