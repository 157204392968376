import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { tap, catchError, retryWhen, concatMap, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { get as _get } from 'lodash';
import { environment } from '@environment';
import { NotifyService } from '@services/notify.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  updatedAt!: number;
  connectSub?: Subscription;
  noServer: boolean = false;
  lastType = '';
  count: number = 0;

  constructor(
    private notify: NotifyService,
    private router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retryWhen(error =>
        error.pipe(
          concatMap((error, count) => {
            // retry failed
            if (
              count <= 16 &&
              [0, 504].includes(error.status) &&
              error.url.includes(environment.api) &&
              !error.url.includes('/print/status')
            ) {
              if (count > 3) {
                this.count++;
                let type: 'info' | 'warning' | 'danger' | 'bomb' = 'info';
                switch (true) {
                  case this.count > 11:
                    type = 'bomb';
                    break;
                  case this.count > 5:
                    type = 'danger';
                    break;
                  case this.count > 2:
                    type = 'warning';
                    break;
                }

                if (this.count > 0 && error.url.includes(environment.api)) {
                  this.notify.toast({
                    msg: `Server not responding. Reconnect attempt: #${this.count}\r\n${error.url}`,
                    id: 'no-server-error',
                    type,
                    timeout: 0,
                  });
                  this.lastType = type;
                }
              }

              return of(error);
            }
            return throwError(error);
          }),
          concatMap((value, index) => of(value).pipe(delay(1000 * (index + 1))))
        )
      ),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.notify.loading = false;
          if (this.notify.getToast('no-server-error')) {
            this.count = 0;
            this.notify.cancel('no-server-error');

            this.notify.toast({
              msg: `Server connection restored.`,
              id: 'connect-restored',
              type: 'success',
              update: true,
            });
          }

          if (event.url.includes('/user/login')) {
            if (!event.body.user) {
              this.notify.toast({
                msg: `Login failed. Please check that email and password have been entered correctly.`,
                id: 'login-error',
                type: 'warning',
                update: true,
                timeout: 10000,
              });
            } else {
              this.notify.cancel('login-error');
            }
          }

          if (this.noServer) {
            this.noServer = false;
          }

          if (this.connectSub) {
            this.connectSub.unsubscribe();
            this.connectSub = undefined;
          }

          // const xUpdate = +(event.headers.get('x-update') || 0);
          // if (xUpdate) {
          //   if (!this.updatedAt || this.updatedAt < xUpdate) {
          //     this.updatedAt = xUpdate;
          //     this.usersService.currentUser().subscribe((user) => user);
          //   }
          // }
        }
      }),
      catchError((error: any): Promise<any> => {
        this.notify.loading = false;
        if (error.url.includes('/user/reset-password')) {
          if ([401].includes(error.status)) {
            this.notify.toast({ msg: error.error?.message, id: 'invalid-request-payload', type: 'danger' });
            this.router.navigate(['login']);
          }
          this.notify.toast({ msg: 'Invalid verification code', id: 'invalid-request-payload', type: 'danger' });
        } else if ([401].includes(error.status)) {
          this.notify.toast({ msg: 'User logged out.', id: 'user-logged-out', type: 'warning' });
          this.router.navigate(['login']);

          if (error.url.includes('/users/current')) {
            return of({}).toPromise();
          }
        } else if (
          [0, 504].includes(error.status &&
            error.url.includes(environment.api)
          )) {
          this.notify.toast({
            msg: `Server not responding.`,
            id: 'no-server-error',
            type: 'bomb',
            update: true,
            timeout: 10000
          });
        } else if (error.error?.message) {
          this.notify.toast({
            msg: error.error.message,
            type: 'warning',
            update: true
          });
        } else if (error.message) {
          this.notify.toast({
            msg: error.message,
            type: 'warning',
            update: true
          });
        }

        return Promise.reject(error);
      }),
    );
  }
}
