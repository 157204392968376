import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges
} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss']
})
export class DateSelectorComponent implements OnInit, OnChanges {
  @Output()
  selectedDate: EventEmitter<any> = new EventEmitter();

  @Input()
  dateLock = moment().startOf('day');

  m = this.dateLock.clone();
  date = this.m.toDate();

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  /**
   * Move the date selector.
   *
   * @param {number} direction
   */
  move(direction: number) {
    const dest = this.m.clone().add(direction, 'day');

    if (dest < this.dateLock) {
      return;
    }

    this.m.add(direction, 'day');
    this.date = this.m.toDate();
    this.selectedDate.emit(this.m);
  }
}
