import {
  Component,
  ViewChild,
  Output,
  Input,
  EventEmitter,
  OnInit,
  OnDestroy
} from '@angular/core';
import { StreamService } from './stream.service';
import { get as _get } from 'lodash';

@Component({
  selector: 'app-camera',
  templateUrl: 'camera.component.html',
  styleUrls: ['camera.component.scss']
})
export class CameraComponent implements OnInit, OnDestroy {
  isStreaming = false;
  @ViewChild('container', { static: true }) private container!: { nativeElement: HTMLDivElement };
  @ViewChild('canvas', { static: true }) private canvas!: { nativeElement: HTMLCanvasElement };
  @ViewChild('canvas2', { static: true }) private canvas2!: { nativeElement: HTMLCanvasElement };
  @ViewChild('video', { static: true }) private video!: { nativeElement: HTMLVideoElement };
  @Output() photo = new EventEmitter<any>();
  @Output() isPlaying = new EventEmitter<boolean>();
  @Input() promptVideo = false;
  @Input() showProfile = false;
  @Output() promptVideoChange = new EventEmitter<boolean>();
  public savedPhoto!: { photo: string, postfix: string, size: number } | null;
  done = false;

  constructor(public stream: StreamService) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.getCamera();
    }, 0);
  }

  private getCamera() {
    navigator.mediaDevices.getUserMedia({
      video: true
    }).then((stream: any) => {
      const v = this.video.nativeElement;

      if (this.stream.track) {
        this.stream.track.stop();
      }
      this.stream.track = stream.getTracks()[0];
      try {
        v.srcObject = stream;
      } catch (error) {
        v.src = URL.createObjectURL(stream);
      }
      v.onplaying = () => {
        this.isStreaming = true;
      };
    }).catch((err) => {
      this.promptVideoChange.emit(true);
    });
  }

  public snapPhoto() {
    let w: number, h: number, ctx: CanvasRenderingContext2D | null;
    const mobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
    if (
      _get(window, 'screen.orientation.type', '').includes('portrait') ||
      ( mobile && Math.abs(_get(window, 'orientation', 0) as number) !== 90 )
    ) {
      w = 240;
      h = 320;
      ctx = this.canvas2.nativeElement.getContext('2d');
    } else {
      w = 320;
      h = 240;
      ctx = this.canvas.nativeElement.getContext('2d');
    }

    if (ctx) {
      ctx.drawImage(this.video.nativeElement, 0, 0, w, h);
      const photo = this[w === 320 ? 'canvas' : 'canvas2'].nativeElement.toDataURL('image/jpeg', .8);
      const size = photo.length;
  
      this.done = true;
      this.savedPhoto = {photo, postfix: '.jpg', size};
    }
  }

  public getPhoto() {
    this.photo.emit(this.savedPhoto);
    this.savedPhoto = null;
  }

  public reset() {
    const ctx = this.canvas.nativeElement.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, 320, 240);
      this.done = false;
      this.savedPhoto = null;
    }
  }

  onPlaying() {
    this.isPlaying.emit(true);
    // this.eventService.broadcast('loading', false);
  }

  ngOnDestroy() {
    if (this.stream.track) {
      this.isPlaying.emit(false);
      this.stream.track.stop();
    }
  }
}
