import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Notification } from '../../models/notification';
import { Router } from '@angular/router';
import { NotificationsService } from '../../services/notifications.service';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnChanges {
	@Input() notification: Notification;

	constructor(
		private router: Router,
		private notificationService: NotificationsService,
		private userService: UserService
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.notification) return;

		const currentUserId = this.userService.getUserId();

		this.notification = this.notification.user_id && this.notification.user_id !== currentUserId
			? null
			: this.notification;
	}

	view(notification: Notification) {
		notification.read = true;

		this.notificationService.updateNotification(notification)
			.then(() => {
				this.router.navigate(['/home/notification', notification?.id]);
			});
	}
}
