import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AdminService } from '@services/admin.service';

export const CurrentUserResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  userService: UserService = inject(UserService)
) => {
  const id = route.params.as_user;
  if (id) return userService.fetchUser(id);
  else return userService.getCurrentUser();
}

export const UserResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  userService: UserService = inject(UserService)
) => {
  const id = route.params.id;
  return userService.fetchUser(id);
}

export const AsUserResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  userService: UserService = inject(UserService)
) => {
  const id = route.params.as_user;
  return userService.fetchUser(id);
}

export const UsersResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  admin: AdminService = inject(AdminService)
) => {
  const { page, pageSize, search, location } = route.queryParams;
  const itemsPerPage = +pageSize || 25;
  const offset = (+(page || 1) * itemsPerPage) - itemsPerPage;
  const customers = state.url.includes('/admin/customers');

  return admin.getAllUsers({ itemsPerPage, offset, search, location: +location, customers });
}