import {
  Component,
  forwardRef,
  Injector,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  ControlContainer,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MainInputComponent } from '../main-input';

@Component({
  selector: 'app-input',
  template:
    `<div [formGroup]="formGroup" class="input-container">
      <div #group class="input-group">
        <ng-content select=".input-group-prepend"></ng-content>
        <input #input
              type="text"
              [formControlName]="controlName"
              [placeholder]="placeholder"
              (blur)="doBlur($event)"
              [required]="required"
              [ngStyle]="{
                'padding-right': control?.value && !required ? '30px' : undefined
              }"
              [ngClass]="{
                set: control.value,
                first: first,
                last: last
              }"
              [attr.autocomplete]="autocomplete || undefined"
              [attr.readonly]= "(readonly || readonly === '') ? '' : undefined"
              class="form-control">
        <label for="number" *ngIf="!hideLabel && hideLabel !== ''" [ngStyle]="{ left: left }">
          {{ label || niceName }}<span *ngIf="required" class="required">*</span>
        </label>
        <a *ngIf="control?.value && !required && !(readonly || readonly === '') && !control.disabled" (mousedown)="reset()"  (touchstart)="reset()" class="cancel-input" [ngStyle]="{'right': right}">
         <i class="fa fa-times-circle-o"></i>
        </a>
      </div>
    </div>
    <div *ngIf="!hideErrors && hideErrors !== ''" class="error">
      <ng-container *ngIf="control?.errors?.required && control?.touched">
        {{ requiredName || label || niceName }} is required
      </ng-container>
      <ng-container *ngIf="control?.errors?.maxlength">
        {{ requiredName || label || niceName }} exceeds max length
      </ng-container>
    </div>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent extends MainInputComponent {
  right = '';
  @ViewChild('group', { static: true }) group!: ElementRef;

  constructor(
    injector: Injector,
    controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super(injector, controlContainer, elementRef);
  }


  onInit = () => {
    setTimeout(() => this.setCancelPosition(), 0);
    setTimeout(() => this.setLabelPosition(), 0);
  }

  setCancelPosition() {
    const children = this.group.nativeElement.getElementsByClassName('input-group-append');
    let width = 0;
    [].forEach.call(children, (child: HTMLElement) => {
      width = +child.offsetWidth;
    });
    this.right = (8 + width) + 'px';
  }

  reset() {
    this.control.setValue('');
  }
}
