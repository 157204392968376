import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PhotoComponent } from './photo.component';
import { AuthGuard } from '../auth/auth-guard';
import {
  PackageResolver, SinglePhotoResolver, PhotoSizesResolver,
  AlaCarteResolver, UserAppointmentResolver, CartResolver
} from "../resolvers/resolves/appointment.resolve";
import { CurrentUserResolver } from "../resolvers/resolves/user.resolve";

const routes: Routes = [
  {
    path: 'photo/:id',
    component: PhotoComponent,
    canActivate: [AuthGuard],
    resolve: {
      photo: SinglePhotoResolver,
      photoSizes: PhotoSizesResolver,
      user: CurrentUserResolver,
      appt: UserAppointmentResolver,
      cart: CartResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PhotoRoutingModule {
}
