import { pick as _pick } from 'lodash';
import { Appointment } from './appointment';
import { Base } from './base';

export class Order extends Base {
  price: string;
  description: string;
  stripe_ref: string;
  photo_id: number;
  appointment_id: number;
  appointment: Appointment;
  fulfilled: boolean;

  constructor(data?: Partial<Order>) {
    super(data);

    Object.assign(this, _pick(data, [
      'price',
      'description',
      'stripe_ref',
      'photo_id',
      'appointment_id',
      'fulfilled'
    ]));
    
    this.appointment = data?.appointment ? new Appointment(data.appointment) : null;
  }
}


