import {Injectable} from '@angular/core';
import {HttpService} from './api.service';

@Injectable({ providedIn: 'root' })
export class HolidayMagicService {

  /**
   * Error codes sent back by stripe.
   *
   * @type {{CARD_DECLINED: string; INCORRECT_CVC: string; EXPIRED_CARD: string; PROCESSING_ERROR: string}}
   */
  public stripeErrors: any = {
    CARD_DECLINED: 'card_declined',
    INCORRECT_CVC: 'incorrect_cvc',
    EXPIRED_CARD: 'expired_card',
    PROCESSING_ERROR: 'processing_error'
  };

  constructor(private http: HttpService) {
  }

  getOpenHours(day: string): Promise<any> {
    return this.http.get(`/openhours/${day}`).toPromise()
      .then(openHours => openHours.data as any);
  }

  /**
   * Begin the holiday Magic password reset process.
   *
   * @returns {Promise<any>}
   */
  sendForgotPasswordEmail(email): Promise<any> {
    return this.http.post('/person/forgot-password', {
      email: email
    }).toPromise();
  }
}
