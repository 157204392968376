<div [formGroup]="formGroup" class="input-container">
  <select
    #select
    [formControlName]="controlName"
    class="form-select form-control"
    [ngClass]="{
      set: control.value !== null,
      first: first,
      last: last
    }"
    [autocomplete]="autocomplete"
    [required]="required"
  >
    <option
      *ngIf="!required || control.value === null"
      [ngValue]="null"
      [disabled]="
        (required || readonly || readonly === '') &&
        ![null, undefined, ''].includes(control.value)
      "
    >
      <ng-container
        *ngIf="
          [null, undefined, ''].includes(control.value) &&
            selectNone === 'Select None';
          else optionSelected
        "
      >
        {{ control.disabled ? "None" : "Select " + (label || niceName) }}
      </ng-container>
      <ng-template #optionSelected>{{ selectNone }}</ng-template>
    </option>
    <option
      [ngValue]="config.value !== undefined ? option[config.value] : option"
      [ngClass]="{
        selected:
          control.value === (config.value ? option[config.value] : option)
      }"
      *ngFor="let option of options; let i = index"
      [disabled]="
        (readonly || readonly === '') &&
        control.value !== (config.value ? option[config.value] : option)
      "
    >
      {{ option[config.name] }}
      <ng-container *ngIf="option.rating && emoji">
        &nbsp;-&nbsp;
        <ng-container *ngFor="let r of option.rating | appNumberArray"
          >⭐</ng-container
        >
      </ng-container>
    </option>
  </select>
  <label for="text" *ngIf="!hideLabel && hideLabel !== ''">
    {{ label || niceName }}<span *ngIf="required" class="required">*</span>
  </label>
</div>
<div *ngIf="!hideErrors && hideErrors !== ''" class="error">
  <ng-container *ngIf="!!control?.errors?.required && !!control?.touched">
    {{ requiredName || label || niceName }} is required
  </ng-container>
</div>
