import { pick as _pick } from 'lodash';
import { Base } from './base';

export class Deliverable extends Base {
  name: string;
  type: string;
  description: string;
  package_id: number;
  sort_order: number;
  delete: boolean;

  constructor(data?: Partial<Deliverable>) {
    super(data);

    Object.assign(this, _pick(data, [
      'name',
      'type',
      'description',
      'package_id',
      'sort_order'
    ]));
  }
}