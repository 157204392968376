import {
  Component,
  ElementRef,
  forwardRef,
  Injector,
} from '@angular/core';
import {
  ControlContainer,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MainInputComponent } from '../main-input';

@Component({
  selector: 'app-phone-input',
  template:
    `
    <div [formGroup]="formGroup" class="input-container">
        <div class="control input-group">
        <input #input
              type="text"
              (blur)="doBlur($event)"
              [autocomplete]="autocomplete"
              [formControlName]="controlName"
              [ngClass]="{
                set: control.value
              }"
              [mask]="mask"
              [required]="required"
              [attr.autocomplete]="autocomplete || undefined"
              [attr.readonly]= "(readonly || readonly === '') ? '' : undefined"
              class="form-control">
        <label for="number" *ngIf="!hideLabel && hideLabel !== ''">
          {{ label || niceName }}<span *ngIf="required" class="required">*</span>
        </label>
        <div class="input-group-append">
          <span class="input-group-text" [ngClass]="{
            valid: control.valid,
            invalid: !control.valid && control.touched
          }"><i class="fa fa-phone"></i></span>
        </div>  
        </div>
    </div>
    <div *ngIf="!hideErrors && hideErrors !== ''" class="error">
    <ng-container *ngIf="control?.errors?.required && control?.touched">
      {{ requiredName || label || niceName }} is required
    </ng-container>
    <ng-container *ngIf="control?.errors?.mask && control?.touched">
    {{ requiredName || label || niceName }} must be complete (xxx) xxx-xxxx
    </ng-container>
  </div>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberInputComponent),
      multi: true
    }
  ],
  styleUrls: ['./phone-number-input.component.scss'],
})
export class PhoneNumberInputComponent extends MainInputComponent {
  mask = '(000) 000-0000';

  constructor(injector: Injector, controlContainer: ControlContainer, elementRef: ElementRef) {
    super(injector, controlContainer, elementRef);
  }

  doBlur(event: Event) {
    const value = this.control.value.replace(/[\s\(\)\-]/g, '');
    if (!/\S/.test(value)) this.control.setValue('');
    this.blur.emit(event);
  }
}