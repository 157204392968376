import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentTime'
})
export class MomentTimePipe implements PipeTransform {

  transform(m: any, format: string = 'h:mm a'): any {
    if (!moment.isMoment(m)) {
      return m;
    }

    return m.format(format);
  }

}
