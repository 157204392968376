import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { SupportComponent } from '@shared/support/support.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { AuthGuard } from './auth/auth-guard';
import { RoleGuard, RoleGuardChild } from 'app/services/role.guard';
import { ACCESS } from '@enum/access';
import { AsUserResolver } from './resolvers/resolves/user.resolve';

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'route-selector',
    loadChildren: () => import('./route-selector/route-selector.module').then((m) => m.RouteSelectorModule)
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, RoleGuard(ACCESS.LOCATION_ADMIN)],
    canActivateChild: [RoleGuardChild(ACCESS.LOCATION_ADMIN)],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./admin/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'customers',
        loadChildren: () => import('./admin/users/users.module').then((m) => m.UsersModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./admin/users/users.module').then((m) => m.UsersModule)
      },
      {
        path: 'checkin',
        loadChildren: () => import('./admin/checkin/checkin.module').then((m) => m.CheckinModule)
      },
      {
        path: 'calendar',
        loadChildren: () => import('./admin/calendar/calendar.module').then((m) => m.CalendarModule)
      },
      {
        path: 'codes',
        loadChildren: () => import('./admin/codes/codes.module').then((m) => m.CodesModule)
      },
      {
        path: 'locations',
        loadChildren: () => import('./admin/locations/locations.module').then((m) => m.LocationsModule)
      },
      {
        path: 'packages',
        loadChildren: () => import('./admin/packages/packages.module').then((m) => m.PackagesModule)
      },
      {
        path: 'fulfillment',
        loadChildren: () => import('./fulfillment/fulfillment.module').then((m) => m.FulfillmentModule)
      },
      {
        path: 'public/appt-booked',
        loadChildren: () => import('./public/appt-booked/appt-booked.module').then((m) => m.ApptBookedModule)
      }]
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule)
      },
      {
        path: 'user-settings',
        loadChildren: () => import('./user-settings/user-settings.module').then((m) => m.UserSettingsModule)
      },
      {
        path: 'my-photos',
        loadChildren: () => import('./my-photos/my-photos.module').then((m) => m.MyPhotosModule)
      },
      {
        path: 'help',
        loadChildren: () => import('./help/help.module').then((m) => m.HelpModule)
      },
      {
        path: 'support',
        component: SupportComponent
      },
      {
        path: 'book',
        loadChildren: () => import('./book-appointment/book-appointment.module').then((m) => m.BookAppointmentModule)
      },
    ]
  },
  {
    path: 'as-user/:as_user',
    component: MainLayoutComponent,
    canActivate: [AuthGuard, RoleGuard(ACCESS.CORPORATE_ADMIN)],
    canActivateChild: [RoleGuardChild(ACCESS.CORPORATE_ADMIN)],
    resolve: {
      asUser: AsUserResolver
    },
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule)
      },
      {
        path: 'user-settings',
        loadChildren: () => import('./user-settings/user-settings.module').then((m) => m.UserSettingsModule)
      },
      {
        path: 'my-photos',
        loadChildren: () => import('./my-photos/my-photos.module').then((m) => m.MyPhotosModule)
      },
      {
        path: 'help',
        loadChildren: () => import('./help/help.module').then((m) => m.HelpModule)
      },
      {
        path: 'support',
        component: SupportComponent
      },
      {
        path: 'book',
        loadChildren: () => import('./book-appointment/book-appointment.module').then((m) => m.BookAppointmentModule)
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
