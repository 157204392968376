import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateFn,
  CanActivateChildFn,
  Router
} from '@angular/router';

// import moment = require('moment');
import { UserService } from '../services/user.service';
import { Title } from '@angular/platform-browser';

function userIsLoggedIn(route: ActivatedRouteSnapshot, router: Router, userService: UserService, titleService: Title) {
  if (!userService.getToken()) {
    router.navigate(['/login']);
    titleService.setTitle('Holiday Magic - Welcome');
    return false;
  } else {
    try {
      // Check if the token is expired
      const token = userService.getToken(),
        payload = token.split('.')[1],
        exp = JSON.parse(atob(payload)).exp * 1000;

      if (exp < new Date().getTime()) {
        router.navigate(['/login']);
        titleService.setTitle('Holiday Magic - Welcome');
        return false;
      } else {
        if (route.url.length !== 0) {
          titleService.setTitle(`Holiday Magic - ${route.url[0].path[0].toUpperCase()}${route.url[0].path.slice(1)}`);
        } else {
          titleService.setTitle('Holiday Magic');
        }
        return true;
      }
    } catch (e) {
      return false;
    }
  }
}

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  router: Router = inject(Router),
  userService: UserService = inject(UserService),
  titleService: Title = inject(Title)
) => {
  return userIsLoggedIn(route, router, userService, titleService);
};

export const AuthGuardChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => AuthGuard(route, state);
