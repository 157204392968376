import { Pipe, PipeTransform } from '@angular/core';
import { get as _get } from 'lodash';
import { TableColumn } from './table-column';

export enum BtnClasses {
  SECONDARY = 'btn-secondary',
  PRIMARY = 'btn-primary',
  SUCCESS = 'btn-success',
  WARNING = 'btn-warning',
  DANGER = 'btn-danger'
}

@Pipe({ name: 'appBsType' })
export class BsTypePipe implements PipeTransform {
  transform(data: any, col: TableColumn = { type: 'string' }) {
    let value = data;
    if (col.value) {
      value = _get(data, col.value);
    }
    return col.options?.find(option => option.value === value)?.class || '';
  }
}