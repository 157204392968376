import { trigger, transition, style, query, animate, group } from '@angular/animations';

const fadeIn = [
  query(':leave', style({ position: 'absolute', opacity: 1, width: '100%', left: 0, top: 0 }), { optional: true }),
  query(':enter', style({ position: 'absolute', opacity: 0, width: '100%', left: 0, top: 0 }), { optional: true }),
  group([
    query(':leave', animate('200ms', style({ opacity: 0 })), { optional: true }),
    query(':enter', animate('200ms', style({ opacity: 1 })), { optional: true })
  ])
];

const slideLeft = [
  style({ position: 'relative' }),
  query(':leave', style({ position: 'absolute', height: '100%', left: 0, right: 0, transform: 'translateX(0)' }), { optional: true }),
  query(':enter', style({ position: 'absolute', height: '100%', left: 0, right: 0, transform: 'translateX(100%)' }), { optional: true }),
  group([
    query(':leave', animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' })), { optional: true }),
    query(':enter', animate('300ms ease-in-out', style({ transform: 'translateX(0)' })), { optional: true }),
  ])
];

const slideRight = [
  style({ position: 'relative' }),
  query(':leave', style({ position: 'absolute', height: '100%', left: 0, right: 0, transform: 'translateX(0)' }), { optional: true }),
  query(':enter', style({ position: 'absolute', height: '100%', left: 0, right: 0, transform: 'translateX(-100%)' }), { optional: true }),
  group([
    query(':leave', animate('300ms ease-in-out', style({ transform: 'translateX(100%)' })), { optional: true }),
    query(':enter', animate('300ms ease-in-out', style({ transform: 'translateX(0)' })), { optional: true }),
  ])
];

const slideFadeLeft = [
  style({ position: 'relative' }),
  query(':leave', style({ position: 'absolute', height: '100%', left: 0, right: 0, transform: 'translateX(0)', opacity: 1 }), { optional: true }),
  query(':enter', style({ position: 'absolute', height: '100%', left: 0, right: 0, transform: 'translateX(105%)', opacity: 0 }), { optional: true }),
  group([
    query(':leave', animate('500ms ease-in-out', style({ transform: 'translateX(-105%)', opacity: 0 })), { optional: true }),
    query(':enter', animate('500ms ease-in-out', style({ transform: 'translateX(0)', opacity: 1 })), { optional: true }),
  ])
];

const slideFadeRight = [
  style({ position: 'relative' }),
  query(':leave', style({ position: 'absolute', height: '100%', left: 0, right: 0, transform: 'translateX(0)', opacity: 1 }), { optional: true }),
  query(':enter', style({ position: 'absolute', height: '100%', left: 0, right: 0, transform: 'translateX(-105%)', opacity: 0 }), { optional: true }),
  group([
    query(':leave', animate('500ms ease-in-out', style({ transform: 'translateX(105%)', opacity: 0 })), { optional: true }),
    query(':enter', animate('500ms ease-in-out', style({ transform: 'translateX(0)', opacity: 1 })), { optional: true }),
  ])
];



export const routeFadeAnimation =
  trigger('routerAnimation', [
    transition('* <=> *', fadeIn)
  ]);

export const routeSliderAnimation =
  trigger('routeSliderAnimation', [
    transition(':increment', slideLeft),
    transition(':decrement', slideRight),
    transition('* <=> *', fadeIn)
  ]);

export const sliderAnimation =
  trigger('sliderAnimation', [
    transition(':increment', slideFadeLeft),
    transition(':decrement', slideFadeRight),
    transition('* <=> *', fadeIn)
  ]);


