import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output
} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() classNames = 'btn';
  @Input() disableOnClick = true;
  @Input() buttonText = '';
  @Input() isDisabled = false;
  @Output() clicked = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  /**
   * When our button is clicked.
   */
  click() {
    if (this.disableOnClick) {
      this.isDisabled = false;
    }

    this.clicked.emit(true);
  }
}
