
import {of as observableOf,  Subject ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as R from 'ramda';
import { HttpService } from './api.service';
import { AppointmentService } from './appointment.service';
import { Package } from '../models/package';
import { HolidayMagicService } from './holiday-magic.service';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SchedulingService {
  _hours: any[] = [];
  afternoon = 12;
  evening = 17;

  private open = 7;
  private close = 24;
  private MORNING = 'morning';
  private AFTERNOON = 'afternoon';
  private EVENING = 'evening';

  private bookedAppts: any[];

  private scheduleData = new Subject<any>();
  public schedule = this.scheduleData.asObservable();

  private packageData = new Subject<Package>();
  public selectedPackage = this.packageData.asObservable();

  private childData = new Subject<any>();
  public children = this.childData.asObservable();

  public hoursBuilt = new Subject<any>();

  constructor(private http: HttpService,
    private appt: AppointmentService,
    private magic: HolidayMagicService) {
  }

  /**
   * Build the hours for the view.
   *
   * @param seed
   */
  buildHours(seed: any = null) {
    this._hours.splice(0);

    seed = seed || moment();

    const yesterday = seed.clone().subtract(1, 'd')
      .startOf('day').format('YYYY-MM-DDT00:00:00Z'),
      tomorrow = seed.clone().add(1, 'd')
        .startOf('day').format('YYYY-MM-DDT00:00:00Z');

    this.appt.getBookedAppt(yesterday, tomorrow)
      .pipe(
        map(appts => {
          const a = appts.data;
          return a.map(appt => moment(appt.date));
        }),
        switchMap((appts: any[]) => {
          this.bookedAppts = appts;
  
          const day = seed.clone().startOf('day')
            .format('YYYY-MM-DD');
  
          return observableOf(this.magic.getOpenHours(day));
        })
      ).subscribe(openHours => {
        const start = seed.clone().hour(this.open).startOf('hour'), end = seed.clone().hour(this.close).startOf('hour');

        // Transform open hours into functions to test if an hour is between
        // the open and close hours.
        const oh = openHours
          .then(openHours => openHours.map(openHour => {
            const m = seed.clone(),
              openTimePieces = openHour.open.split(':'),
              closeTimePieces = openHour.close.split(':'),
              open = m.clone().hour(openTimePieces[0]).startOf('hour'),
              close = m.clone().hour(closeTimePieces[0]).startOf('hour');

            if (openTimePieces[1]) {
              open.minutes(openTimePieces[1]);
            }

            if (closeTimePieces[1]) {
              close.minutes(closeTimePieces[1]);
            }

            return (hour: any) => hour.isSameOrAfter(open) && hour.isBefore(close);
          }))
          .then(openHours => {
            // Create the array of all time slots
            while (start < end) {
              const val = start.clone(),
                hour = start.hours(),
                group = this.findGroup(hour),
                areNotSameMoment = m => m.isSame(val),
                disabled = !this.bookedAppts.length ? false :
                  R.any(areNotSameMoment)(this.bookedAppts);

              // Remove any hours that are outside of operating hours
              const valBetweenAnyOpenHours =
                openHours.map(test => test(val)).filter(bool => bool);

              if (valBetweenAnyOpenHours.length) {
                this._hours.push({ val, group, disabled, d: val.toDate() });
              }

              start.add(5, 'minutes');
            }

            // Publish the new hours
            this.hoursBuilt.next();
          });
      });
  }

  /**
   * Find what group an hour belongs to.
   *
   * @param {number} hour
   * @returns {string}
   */
  findGroup(hour: number) {
    return hour < this.afternoon ? this.MORNING : hour >= this.afternoon
      && hour < this.evening ? this.AFTERNOON : this.EVENING;
  }

  /**
   * Convenience method to get all hours.
   *
   * @returns {any[]}
   */
  get hours() {
    return this._hours;
  }

  /**
   * Get all morning time slots.
   */
  get mornings() {
    return this.filterFuture(R.filter(R.propEq('group', this.MORNING), this._hours));
  }

  /**
   * Get all afternoon timeslots.
   */
  get afternoons() {
    return this.filterFuture(R.filter(R.propEq('group', this.AFTERNOON), this._hours));
  }

  /**
   * Get all evening timeslots.
   */
  get evenings() {
    return this.filterFuture(this._hours.filter(hour => hour.group === this.EVENING));
  }

  /**
   * Filter for future dates only.
   *
   * @param {any[]} hours
   */
  filterFuture(hours: any[]) {
    const now = moment();
    return hours.filter(hour => hour.val >= now);
  }

  /**
   * Publish the schedule info.
   *
   * @param data
   */
  publishSchedule(data: any) {
    this.scheduleData.next(data);
  }

  /**
   * Publish the package data.
   *
   * @param data
   */
  publishPackage(data: Package) {
    this.packageData.next(data);
  }

  /**
   * Publish that the children data has changed.
   *
   * @param data
   */
  publishChildren(data: any) {
    this.childData.next(data);
  }

  /**
   * Get genders from server.
   *
   * @returns {Observable<any>}
   */
  getGenders() {
    return this.http.get('/gender');
  }
}
