import { pick as _pick } from 'lodash';
import { Base } from "./base";

export class UserRole extends Base {
  public user_id: number;
  public role_type_id: number;

  constructor(data?: Partial<UserRole>) {
    super(data);

    Object.assign(this, _pick(data, [
      'user_id',
      'role_type_id'
    ]));
  }
}
