import { Component, OnInit } from '@angular/core';
import {
  Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel,
  NavigationError,
} from '@angular/router';
import { UserService } from './services/user.service';
import { routeFadeAnimation } from './animations';
import { LocationService } from '@services/location.service';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  template: `
      <div [@routerAnimation]="view" class="position-relative">
        <router-outlet />
      </div>
      <app-toasts />
      <div class="loading-overlay"
           *ngIf="loading">
          <img [src]="imgSource"/>
          <div>Loading...</div>
          <i class="fa fa-spinner fa-spin fa-2x"></i>
      </div>`,
  styleUrls: ['./app.component.scss'],
  animations: [routeFadeAnimation]
})
export class AppComponent {
  title = 'app';
  imgSource: string;
  public loading = true;

  constructor(
    private router: Router,
    private user: UserService,
    private location: LocationService,
  ) {

    router.events
      .subscribe((event: RouterEvent) => {
        this.navigationInterceptor(event);
      });

    router.events
      .pipe(filter((event) => event instanceof NavigationStart),
        distinctUntilChanged((x: NavigationStart, y: NavigationStart) => {
          const left =
            x && x.url && x.url.includes('?') ? x.url.split('?')[0] : x.url;
          const right =
            y && y.url && y.url.includes('?') ? y.url.split('?')[0] : y.url;
          return left === right;
        })
      )
      .subscribe(event => window.scrollTo({ top: 0, behavior: 'smooth' }));

    this.imgSource = '../assets/images/HMS-01.png';
  }

  get view() {
    return !this.router.url.includes('/book') ? this.router.url.split('?')[0] : null;
  }

  navigationInterceptor(event: RouterEvent): void {
    // Set loading state to false in both of the below events to hide the
    // spinner in case a request fails
    if (event instanceof NavigationEnd || event instanceof NavigationCancel ||
      event instanceof NavigationError) {
      this.loading = false;
    }
  }
}
