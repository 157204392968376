<div class="mb-2">
  <div
    (mouseenter)="setHovered(true)"
    (mouseleave)="setHovered(false)"
    (touchstart)="setHovered(true)"
    (touchend)="setHovered(false)"
    class="notification position-relative {{ toast.type || 'info' }}"
    [ngClass]="{ show: toast.visible, hovered: hovered }"
  >
    <h5 *ngIf="toast.title">{{ toast.title }}</h5>
    <p class="toast-msg">{{ toast.msg }}</p>
    <div class="actions">
      <button
        *ngFor="let action of toast.actions || []; trackBy: trackByFn"
        (click)="do(action.action)"
        type="button"
        class="btn btn-light"
      >
        {{ action.name }}
      </button>
      <button
        *ngIf="toast.link"
        (click)="viewLink()"
        type="buton"
        class="btn btn-light"
      >
        View
      </button>
      <button
        *ngIf="toast.dismissible"
        (click)="close()"
        type="button"
        class="btn btn-light btn-close"
      >
        Close
      </button>
    </div>
  </div>
</div>
