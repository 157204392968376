<div class="main container-fluid">
    <h1 class="mb-4">Customer Support</h1>
    <div class="row mb-2">
        <div class="col-md-6 col-lg-8">
            <p class="">Thanks for reaching out to Holiday Magic Studios. Before you contact us, take a look at our FAQs. We just might have the answer you’re looking for.</p>
        </div>
        <div class="col-md-6 col-lg-4 text-right">       
            <button routerLink="/help" class="btn btn-outline-secondary btn-block custom-btn">View FAQs</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-lg-8">
           <app-support-form></app-support-form>
        </div>
        <div class="col-md-6 col-lg-4">
           <app-contact-card></app-contact-card>
        </div>
    </div>
</div>
