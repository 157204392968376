<div click-outside (clickOutside)="onClickOutside($event)">

    <div id="UserPrefs" class="hm-layout-dropdown">
        <a href="javascript:void(0);" (click)="active=!active;" class="user-profile-icon small-hide"><i class="fa fa-user-circle-o"></i></a>
        <a class="small-show small-icon" href="javascript:void(0);" (click)="active=!active;"><i class="fa fa-bars"></i></a>

        <div class="hm-dropdown-menu" *ngIf="active">
            <ul class="desk-menu-list small-hide">
                <li><a href="javascript:void(0);" (click)="navigate('/user-settings')">Account Preferences</a></li>
                <li><a href="javascript:void(0);" (click)="logOut()">Sign Out</a></li>
            </ul>
            <ul class="small-menu-list small-show">
                <li>
                    <a href="javascript:void(0);" (click)="navigate('/home')">
                        <span class="">Home</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" (click)="navigate('/about')">
                        <span class="">About</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" (click)="navigate('/help')">
                        <span class="">Help</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" (click)="navigate('/notifications')">
                        <span class="">Notifications</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" (click)="navigate('/checkout')">
                        <span class="">Cart {{(cart[0] && cart[0].length > 0) ? '(' + cart[0].length + ')': ''}}</span>
                    </a>
                </li>
                <li><a href="javascript:void(0);" (click)="navigate('/user-settings')">Account Preferences</a></li>
                <li><a href="javascript:void(0);" (click)="logOut()">Sign Out</a></li>
            </ul>
            <!--user menu-->

        </div>
    </div>

</div>