import {
  ComponentRef,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { TableComponent } from './table.component';
import { TableMobileComponent } from './table-mobile/table-mobile.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TableColumn } from './table-column';
import { pick as _pick } from 'lodash';
import { TableAction } from '@interface/table-action';

@Directive({
  selector: '[appDynamicTable]'
})
export class DynamicTableDirective implements OnInit, OnChanges {
  @Input() records: any[] = [];
  @Input() columns: TableColumn[] = [];
  @Input() actions: TableAction[] = [];
  @Input() headers: boolean = true;
  @Input() condensed: boolean = false;
  @Input() notStriped: boolean | '' = false;
  @Input() sort?: string;
  @Input() flagged?: string;
  @Input() trackBy = 'id';
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() hover = true;
  @Input() totalItems?: number;
  @Input() pageSize?: number;
  @Input() pageControl?: UntypedFormControl;
  @Input() emptyTitle!: string;
  @Input() maxSize?: number;
  @Input() emptyMessage!: string;

  @Output() update = new EventEmitter<any>();
  @Output() actionChange = new EventEmitter<{ action: string, record: any }>();

  mobile = window.innerWidth < 810;
  tableComponent = TableComponent;
  tableMobileComponent = TableMobileComponent
  componentRef!: ComponentRef<any>;
  componentFactory: any;

  constructor(
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit() {
    this.createComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeKeys = Object.keys(changes);
    if (this.componentRef) {
      Object.assign(this.componentRef.instance, _pick(this, changeKeys));
    }
  }

  createComponent() {
    this.componentRef = this.viewContainerRef.createComponent(
      window.innerWidth < 810 ? TableMobileComponent : TableComponent
    );

    Object.assign(this.componentRef.instance, _pick(this, [
      'records',
      'columns',
      'actions',
      'headers',
      'condensed',
      'notStriped',
      'sort',
      'flagged',
      'trackBy',
      'form',
      'hover',
      'update',
      'actionChange',
      'totalItems',
      'pageSize',
      'maxSize',
      'pageControl',
      'emptyTitle',
      'emptyMessage'
    ]));
  }

  @HostListener('window:resize')
  @HostListener('window:orientationchange')
  getMobile() {
    const mobile = window.innerWidth < 810;
    if (mobile !== this.mobile) {
      const { scrollTop, scrollHeight, clientHeight } = this.componentRef.instance.scroll?.nativeElement;

      if (scrollTop !== undefined && scrollHeight !== undefined && clientHeight !== undefined) {
        const percent = scrollTop / (scrollHeight - clientHeight);

        if (this.componentRef) {
          this.componentRef.destroy();
        }

        this.createComponent();
        this.mobile = window.innerWidth < 810;
        setTimeout(() => {
          const { scrollHeight, clientHeight } = this.componentRef.instance.scroll?.nativeElement;
          const newHeight = scrollHeight - clientHeight;

          this.componentRef.instance.scroll.nativeElement.scrollTop = newHeight * percent;
        }, 0);
      }
    }
  }
}
