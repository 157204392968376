export type ToastType = 'success' | 'danger' | 'warning' | 'info' | 'bomb';
export class Toast {
  public id?: string;
  public title: string = '';
  public msg: string = '';
  public type: ToastType = 'success';
  public timeout: number = 3000;
  public update: boolean = false;
  public size: string = 'md';
  public dismissible: boolean = true;
  public spinner: boolean = false;
  public link?: string;
  public data?: any;
  public visible = false;
  public actions?: { name: string; action: Function }[];

  constructor(data?: any) {
    return Object.assign(this, data);
  }
}
