import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import { InputComponent } from "./input/input.component";
import { SelectComponent } from "./select/select.component";
import { DateInputComponent } from "./date-input/date-input.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { NumberArrayPipe } from "./select/number-array.pipe";
import { MainInputComponent } from "./main-input";
import { EmailInputComponent } from "./email-input/email-input.component";
import { IntegerInputComponent } from "./integer-input/integer-input.component";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { NumberInputComponent } from "./number-input/number-input.component";
import { PasswordInputComponent } from "./password-input/password-input.component";
import { PhoneNumberInputComponent } from "./phone-number-input/phone-number-input.component";
import { TimeInputComponent } from "./time-input/time-input.component";
import { SliderInputComponent } from "./slider-input/slider-input.component";
import { BooleanInputComponent } from "./boolean-input/boolean-input.component";
import { TextareaComponent } from "./textarea/textarea.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsDropdownModule,
    NgxMaskDirective,
    NgxMaskPipe,
    BsDatepickerModule.forRoot(),
  ],
  declarations: [
    BooleanInputComponent,
    MainInputComponent,
    EmailInputComponent,
    InputComponent,
    IntegerInputComponent,
    NumberInputComponent,
    PasswordInputComponent,
    PhoneNumberInputComponent,
    TextareaComponent,
    DateInputComponent,
    SelectComponent,
    SliderInputComponent,
    TimeInputComponent,
    NumberArrayPipe,
  ],
  exports: [
    BooleanInputComponent,
    ReactiveFormsModule,
    EmailInputComponent,
    InputComponent,
    IntegerInputComponent,
    NumberInputComponent,
    PasswordInputComponent,
    PhoneNumberInputComponent,
    TextareaComponent,
    DateInputComponent,
    TimeInputComponent,
    SelectComponent,
    SliderInputComponent
  ],
  providers: [provideNgxMask()],
})
export class InputModule { }
