import { Injectable } from '@angular/core';
import { HttpService } from './api.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Location } from '@models/location';
import { formatTime } from 'app/utils/format-time';

@Injectable({
  providedIn: 'root'
})
export class OpenHoursService {

  constructor(
    private http: HttpService
  ) { }

  get(day: string, locationID?: string | number): Observable<any> {
    return this.http.get(`/openhours/${day}${locationID ? '/' + locationID : ''}`)
      .pipe(map(openHours => openHours?.data?.[0] as any));
  }

  getAll(start: string, end: string, locationID?: string | number): Observable<any> {
    return this.http.get(`/openhours/all/${start}/${end}${locationID ? '/' + locationID : ''}`)
      .pipe(map(openHours => openHours?.data as any));
  }

  bulkSave(openHours: any[], location: Location): Observable<{
    message: string,
    statusCode: number,
    timestamp: number,
    data: { newHours: any[], updatedHours: any[] }
  }> {
    const hoursArray = Object.keys(openHours).map(key => openHours[key]);
    const newHours = hoursArray.filter(oh => !oh.id && (formatTime(oh.open) !== formatTime(location.open) || formatTime(oh.close) !== formatTime(location.close)));
    const updateHours = hoursArray.filter(oh => oh.id && (formatTime(oh.open) !== formatTime(location.open) || formatTime(oh.close) !== formatTime(location.close)));

    if (newHours.length || updateHours.length) {
      return this.http.post('/openhours/save', {
        newHours,
        updateHours,
      });
    } else {
      return of({
        message: 'No bulk updates',
        statusCode: 304,
        timestamp: new Date().valueOf(),
        data: {
          newHours: [],
          updatedHours: [],
        }
      });
    }
  }
}
