<div class="position-relative">
  <ng-container [formGroup]="formGroup">
    <div class="input-container" [attr.data-value]="control.value">
      <textarea
        #textarea
        [style.display]="preview ? 'none' : 'block'"
        [ngClass]="{
          set: control.value,
          first: first,
          last: last
        }"
        (blur)="getSelection()"
        [formControlName]="controlName"
        class="form-control text-control"
        [placeholder]="placeholder"
      ></textarea>
      <label *ngIf="niceName"
        >{{ niceName }}<span *ngIf="required" class="required">*</span>
      </label>
    </div>
  </ng-container>
</div>
<div *ngIf="control?.touched && control?.errors?.required" class="error">
  {{ niceName }} is required.
</div>
<div
  #error
  [hidden]="control?.touched && control?.errors?.required"
  class="error"
></div>
