import {
  Component,
  forwardRef,
  Injector,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  ControlContainer,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MainInputComponent } from '../main-input';

@Component({
  selector: 'app-slider-input',
  styleUrls: ['./slider-input.component.scss'],
  template:
    `<div #group [formGroup]="formGroup" class="input-group d-flex justify-content-between w-100">
        <label for="number" *ngIf="!hideLabel && hideLabel !== ''">
          {{ label || niceName }}<span *ngIf="required" class="required">*</span>
        </label>
        <label class="switch">
          <input #input
                type="checkbox"
                [formControlName]="controlName"
                [placeholder]="placeholder"
                [required]="required"
                [ngStyle]="{
                  'padding-right': control?.value && !required ? '30px' : undefined
                }"
                [ngClass]="{
                  set: control.value,
                  first: first,
                  last: last
                }"
                [attr.autocomplete]="autocomplete || undefined"
                [attr.readonly]= "(readonly || readonly === '') ? '' : undefined"
                class="form-control">
          <span class="slider round"></span>
        </label>
      </div>
    <div *ngIf="!hideErrors && hideErrors !== ''" class="error">
      <ng-container *ngIf="control?.errors?.required && control?.touched">
        {{ requiredName || label || niceName }} is required
      </ng-container>
      <ng-container *ngIf="control?.errors?.maxlength">
        {{ requiredName || label || niceName }} exceeds max length
      </ng-container>
    </div>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderInputComponent),
      multi: true
    }
  ]
})
export class SliderInputComponent extends MainInputComponent {
  right = '';
  @ViewChild('group', { static: true })
  group!: ElementRef;

  constructor(
    injector: Injector,
    controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super(injector, controlContainer, elementRef);
  }


  onInit = () => {
    setTimeout(() => this.setCancelPosition(), 0);
  }

  setCancelPosition() {
    const children = this.group.nativeElement.getElementsByClassName('input-group-append');
    let width = 0;
    [].forEach.call(children, (child: HTMLElement) => {
      width = +child.offsetWidth;
    });
    this.right = (8 + width) + 'px';
  }

  reset() {
    this.control.setValue('');
  }
}
