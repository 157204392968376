<div id="PhotoComponent">
    <div class="main">
        <div class="row full-width">

            <!-- Photo container for large image view -->
            <div class="main-column col-xs-12 col-md-9 no-sm-pad">
                <div class="photo-container">

                    <!--<span class="back-button" (click)="goBack()"> << Back to Session </span>-->

                    <div class="image-box">
                        <img src="{{photo?.signedUrl}}"/>
                    </div>
                </div>
            </div>
            <!-- End of Photo container -->

            <!-- Image options where user can select amount of images to purchase a la cart, etc. -->
            <div class="main-column col-xs-12 col-md-3 no-scroll">
                <div class="content-container ">
                    <div class="row"
                         *ngIf="hideSetPhoto || appointment?.package_id == 3">
                        <div class="col-md-12">
                            <h2 style="text-align: center;">Package Photo</h2>
                            <p>{{ hideSetPhoto ? "You have selected and approved
                                a photo for your package!":'Your photos are
                                ready for download!'}}</p>
                            <button (click)="goBack()"
                                    class="btn btn-secondary btn-block">
                                <i class="fa fa-chevron-left pull-left"></i>
                                Back to Session
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <h2 *ngIf="!hideSetPhoto && appointment?.package_id !== 3"
                                class="text-center padding-15-0">
                                <strong *ngIf="order">{{photo.id ==
                                    order.photo_id ? '':'Select'}} Your Package
                                    Photo!</strong></h2>
                            <div class="alert alert-success text-center"
                                 *ngIf="alert.type"
                                 [ngClass]="alert.type">
                                {{alert.message}}
                            </div>
                        </div>
                    </div>

                    <!-- Purchased Package -->
                    <div class="package-info"
                         *ngIf="!hideSetPhoto && appointment?.package_id !== 3">
                        <div class="row">
                            <div class="col-md-12">
                                <p *ngIf="order"
                                   class="text-center">
                                    <span *ngIf="photo.id == order.photo_id">
                                        This is currently the selected photo to
                                        be printed with your Pre-Purchased Package!
                                        <strong>Don't forget to finalize your order by
                                        clicking the shopping cart and submitting
                                        your photo order.</strong>
                                    </span>
                                    <span *ngIf="photo.id != order.photo_id">
                                        Would you like to use this photo to be
                                    printed with your Pre-Purchased Package?
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div class="row padding-15-0"
                             *ngIf="order">
                            <div class="col-md-12 text-center">
                                <button class="btn btn-block btn-primary"
                                        (click)="photo.id == order.photo_id ? removePhoto(): showAddress = true"
                                        [ngClass]="photo.id == order.photo_id ? 'btn-warning':'btn-primary'">
                                    {{photo.id == order.photo_id ? 'Remove' :
                                    'Use This Photo'}}
                                </button>
                                <button (click)="goBack()"
                                        class="btn btn-secondary btn-block">
                                    <i class="fa fa-chevron-left pull-left"></i>
                                    Back to Session
                                </button>
                            </div>
                        </div>

                        <div class="row verify-address"
                             *ngIf="showAddress">
                            <div class="col-md-12 text-center">
                                <h3>Verify Address </h3>
                                <p>Before selecting your package photo you will
                                    need to verify the address where we will be
                                    sending your photos:</p>
                                <form #addressForm="ngForm">
                                    <div class="form-group row">
                                        <div class="col-12">
                                            <input
                                                    class="form-control"
                                                    name="address"
                                                    placeholder="Address"
                                                    required
                                                    [(ngModel)]="user.person.address"
                                                    type="text">
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-12">
                                            <input
                                                    class="form-control"
                                                    name="city"
                                                    placeholder="City"
                                                    required
                                                    [(ngModel)]="user.person.city"
                                                    type="text">
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-12">
                                            <input
                                                    class="form-control"
                                                    name="state"
                                                    placeholder="State"
                                                    required
                                                    [(ngModel)]="user.person.state"
                                                    type="text">
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-12">
                                            <input
                                                    class="form-control"
                                                    name="zip"
                                                    placeholder="Zip"
                                                    required
                                                    [(ngModel)]="user.person.zip"
                                                    type="text">
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-12">
                                            <button class="btn btn-success btn-block"
                                                    (click)="savePersonAddress()"
                                                    [disabled]="!addressForm.form.valid">
                                                Save
                                            </button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>


                    </div>

                    <!-- More Prints Section -->
                    <div class="options-box">
                        <div class="heading-content">
                            <h3 class="text-center padding-15-0">
                                Want More Prints?
                            </h3>
                            <p>Purchase additional prints by selecting them
                                below.</p>

                        </div>
                        <form #PhotoSizeForm="ngForm">
                            <div class="form-group row pb-1"
                                 *ngFor="let size of carteForm">
                                <div class="col-6">
                                    <input
                                            class="form-control"
                                            name="{{size.size}}"
                                            [(ngModel)]="size.quantity"
                                            type="number"
                                            [min]="0"
                                            id="{{'size-' + size.size}}">
                                </div>
                                <label for="{{'size-' + size.size}}"
                                       class="col-6 col-form-label">{{size.size}}</label>
                            </div>

                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 pb-1">
                                    <button
                                            class="btn btn-success btn-block"
                                            (click)="createAlaCarte()">Add to Cart</button>
                                </div>
                                <div class="col-12">
                                    <button
                                            class="btn btn-primary btn-block"
                                            routerLink="/checkout">Go to Cart</button>
                                </div>
                                <div class="col-md-12 pb-5">
                                    <div class="alert alert-danger"
                                         *ngIf="noPrints">
                                        You must select a quantity!
                                    </div>
                                </div>
                            </div>
                    </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- End of Options Container -->


        </div>
    </div>
</div>
