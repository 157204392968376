<div
  class="hm-table"
  [ngClass]="{ 'has-pagination': false }"
  [style.height]="height"
>
  <div #scroll class="table-scroll">
    <table
      class="table"
      [ngClass]="{
        'table-sm': condensed,
        'table-hover': hover,
        'table-striped': !notStriped && notStriped !== ''
      }"
    >
      <tbody>
        <ng-container *ngIf="records?.length; else noRecords">
          <ng-container
            *ngFor="
              let record of records;
              let rowIndex = index;
              trackBy: trackByFn
            "
          >
            <ng-container
              *ngFor="
                let col of columns;
                let colIndex = index;
                let last = last;
                let first = first;
                trackBy: columnTrackByFn
              "
            >
              <tr [ngClass]="{ 'last-row': last }">
                <ng-container *ngIf="!col.image">
                  <th *ngIf="col.sort; else noSort" class="no-select">
                    <div class="d-flex justify-content-between">
                      <a
                        href="javascript:void(0)"
                        (click)="do($event, { action: 'sort', record: col })"
                        >{{ col.name }}</a
                      >
                      &nbsp;
                      <span class="sort-indicator">
                        <i
                          *ngIf="(sort || '').includes(col.sort)"
                          class="fa"
                          [ngClass]="{
                            'fa-sort-amount-desc': !(sort || '').includes('-'),
                            'fa-sort-amount-asc': (sort || '').includes('-')
                          }"
                        ></i>
                      </span>
                    </div>
                  </th>
                  <ng-template #noSort
                    ><th class="no-select">{{ col.name }}</th></ng-template
                  >
                </ng-container>
                <ng-container *ngIf="col.options; else tdDisplay">
                  <td>
                    <div
                      class="btn-group"
                      dropdown
                      #dropdown="bs-dropdown"
                      placement="bottom right"
                      container="body"
                    >
                      <button
                        (click)="setDDOpen(!this.DDOpen, $event)"
                        dropdownToggle
                        type="button"
                        class="btn btn-sm dd-btn {{ record | appBsType : col }}"
                      >
                        {{ record | appOptionName : col }}
                      </button>
                      <ul
                        *dropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        role="menu"
                        aria-labelledby="button-basic"
                      >
                        <li
                          *ngFor="let option of col.options || []"
                          role="menuitem"
                        >
                          <a href="javascript:void(0)"
                            (click)="
                              confirmUpdateColValue(
                                $event,
                                record,
                                col,
                                option.value,
                                rowIndex
                              );
                              dropdown.hide()
                            "
                            [ngClass]="{
                              active:
                                col.value &&
                                (record | appGet : col.value) === option.value,
                              disabled: option.disabled
                            }"
                            class="dropdown-item"
                            >{{ option.name }}</a
                          >
                        </li>
                      </ul>
                    </div>
                  </td>
                </ng-container>
                <ng-template #tdDisplay>
                  <td
                    *ngIf="
                      col.value && !(record | appGet : col.value | appIsArray);
                      else itemList
                    "
                    (click)="
                      do($event, { action: 'row-action', record: record })
                    "
                  >
                    <div
                      [ngStyle]="{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        '-webkit-line-clamp': col.maxLines || 10,
                        '-webkit-box-orient': 'vertical'
                      }"
                    >
                      <ng-container *ngIf="col.input" [formGroup]="form">
                        <ng-container [formGroupName]="rowIndex">
                          <ng-container
                            *ngIf="col.value.includes('.')"
                            [formGroupName]="col.value.split('.')[0]"
                          >
                            <input
                              (click)="
                                toggleDefaults(
                                  $event,
                                  col.name + '-' + rowIndex
                                )
                              "
                              (blur)="refreshValue(col.value, rowIndex)"
                              [type]="col.input"
                              [formControlName]="col.value.split('.')[1]"
                              class="form-control"
                            />
                          </ng-container>
                          <input
                            *ngIf="!col.value.includes('.')"
                            (click)="
                              toggleDefaults($event, col.name + '-' + rowIndex)
                            "
                            (blur)="refreshValue(col.value, rowIndex)"
                            [type]="col.input"
                            [formControlName]="col.value"
                            class="form-control"
                          />
                          <ul
                            *ngIf="
                              col.defaults &&
                              defaultDDOpen === col.name + '-' + rowIndex
                            "
                            appFadeIn
                            class="dropdown-menu default-dropdown"
                          >
                            <li *ngFor="let option of col.defaults">
                              <a
                                (click)="setDefault(option.value, rowIndex)"
                                class="dropdown-item"
                                >{{ option.name }}</a
                              >
                            </li>
                          </ul>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="!col.input">
                        {{ record | appValue : col }}
                      </ng-container>
                    </div>
                  </td>
                </ng-template>
                <td
                  *ngIf="col.image"
                  class="image-cell"
                  colspan="2"
                  [ngClass]="{
                    'image-condensed': condensed,
                    'profile-image': col.profile
                  }"
                >
                  <div>
                    <img
                      *ngIf="
                        record | appValue : { value: col.image, type: 'string' }
                      "
                      appFadeImg
                      [src]="
                        record | appValue : { value: col.image, type: 'string' }
                      "
                    />
                  </div>
                </td>
                <ng-template #itemList>
                  <td *ngIf="col.value" class="item-container">
                    <div
                      [ngStyle]="{
                        'max-height': (col.maxLines || 10) * 25 + 'px'
                      }"
                    >
                      <span
                        *ngFor="
                          let item of record | appGet : col.value;
                          let last = last
                        "
                        class="list-item"
                        ><ng-container
                          *ngIf="!col.initialize; else initializeList"
                          >{{
                            item
                              | appValue
                                : { value: col.listValue, type: col.type }
                          }}</ng-container
                        >
                        <ng-template #initializeList>{{
                          item
                            | appInitialize
                            | appValue
                              : { value: col.listValue, type: col.type }
                        }}</ng-template>
                        <ng-container *ngIf="!last"> • </ng-container></span
                      >
                    </div>
                  </td>
                </ng-template>
                <td
                  *ngIf="first && actions.length"
                  class="actions"
                  [ngClass]="{ flagged: flagged && record[flagged] }"
                  [attr.rowspan]="columns.length"
                  [style.width]="42 + 'px'"
                  class="actions {{
                    flagged && (record[flagged]?.class || '')
                  }}"
                  [popover]="flagged && (record[flagged]?.message || false)"
                  [adaptivePosition]="false"
                  triggers="mouseenter:mouseleave"
                  placement="auto"
                  container="body"
                >
                  <div [style.width]="42 + 'px'">
                    <ng-container *ngFor="let action of actions">
                      <ng-container
                        *ngIf="
                          !action.activeOn ||
                          (action.activeOn?.notEqual &&
                            (record | appGet : action.activeOn.key) !==
                              action.activeOn.value) ||
                          (action.activeOn?.notEmpty &&
                            (record | appGet : action.activeOn.key)) ||
                          (!action.activeOn?.notEqual &&
                            !action.activeOn?.notEmpty &&
                            (record | appGet : action.activeOn.key) ===
                              action.activeOn.value)
                        "
                      >
                        <span
                          *ngIf="action.options; else actionBtn"
                          dropdown
                          #dropdown="bs-dropdown"
                          container="body"
                          placement="bottom right"
                        >
                          <button
                            dropdownToggle
                            type="button"
                            class="btn btn-sm"
                            (click)="$event.stopPropagation()"
                          >
                            <img
                              *ngIf="action.iconSrc"
                              [src]="action.iconSrc"
                              [alt]="action.iconAlt"
                            />
                          </button>
                          <ul
                            *dropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            role="menu"
                            aria-labelledby="button-basic"
                          >
                            <li
                              *ngFor="let option of action.options || []"
                              role="menuitem"
                            >
                              <a
                                (click)="
                                  do($event, {
                                    action: option.action,
                                    record: record
                                  });
                                  dropdown.hide()
                                "
                                href="javascript:void(0)"
                                class="dropdown-item"
                              >
                                <ng-container *ngIf="option.iconSrc">
                                  <img
                                    [src]="option.iconSrc"
                                    [alt]="option.iconAlt"
                                  />
                                  &nbsp;
                                </ng-container>
                                {{ option.name }}
                              </a>
                            </li>
                          </ul>
                        </span>
                        <ng-template #actionBtn>
                          <button
                            *ngIf="
                              !action.mailTo && !action.phoneTo;
                              else emailTo
                            "
                            (click)="
                              do($event, {
                                action: action.action,
                                record: record
                              })
                            "
                            type="button"
                            class="btn btn-sm"
                            [ngClass]="{ both: action.icon && action.name }"
                            [disabled]="
                              action.nameKey && !record[action.nameKey!]
                            "
                          >
                            <img
                              *ngIf="action.iconSrc"
                              [src]="action.iconSrc"
                              [alt]="action.iconAlt"
                            />
                            <i
                              *ngIf="
                                action.icon &&
                                (!action.nameKey || record[action.nameKey!])
                              "
                              [class]="action.icon"
                              size="2x"
                              [ngStyle]="{
                                shadow: action.color,
                                color: action.color
                              }"
                            ></i>
                            <br
                              *ngIf="
                                (action.name || action.nameKey) && action.icon
                              "
                            />
                            {{
                              record[action.nameKey!]
                                ? record[action.nameKey!]
                                : action.name
                            }}
                          </button>
                        </ng-template>
                        <ng-template #emailTo>
                          <a
                            *ngIf="
                              action.mailTo &&
                                !!(record | appMailTo : action.mailTo);
                              else phoneTo
                            "
                            [href]="record | appMailTo : action.mailTo"
                            class="btn btn-sm"
                            [ngClass]="{ both: action.icon && action.name }"
                            (click)="$event.stopPropagation()"
                            [tooltip]="
                              record | appValue : { value: action.mailTo }
                            "
                            placement="left"
                            container="body"
                          >
                            <i
                              *ngIf="action.icon"
                              [class]="action.icon"
                              [ngClass]="{ shadow: action.color }"
                              [ngStyle]="{ color: action.color }"
                              size="2x"
                            ></i>
                            <br *ngIf="action.name && action.icon" />
                            {{ action.name }}
                          </a>
                        </ng-template>

                        <ng-template #phoneTo>
                          <a
                            *ngIf="!!(record | appPhoneTo : action.phoneTo)"
                            [href]="record | appPhoneTo : action.phoneTo"
                            class="btn btn-sm"
                            [ngClass]="{ both: action.icon && action.name }"
                            (click)="$event.stopPropagation()"
                            [tooltip]="
                              record | appValue : { value: action.phoneTo }
                            "
                            placement="left"
                            container="body"
                          >
                            <i
                              *ngIf="action.icon"
                              [class]="action.icon"
                              [tooltip]="
                                'Phone: ' + record
                                  | appValue : { value: action.phoneTo }
                              "
                              placement="left"
                              container="body"
                              [ngClass]="{ shadow: action.color }"
                              [ngStyle]="{ color: action.color }"
                              size="2x"
                            ></i>
                            <br *ngIf="action.name && action.icon" />
                            {{ action.name }}
                          </a>
                        </ng-template>
                      </ng-container>
                    </ng-container>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<div
  *ngIf="!!pageControl"
  [ngClass]="{
    hidden: totalItems <= pageSize,
    loading: loading
  }"
  class="d-flex justify-content-center"
>
  <pagination
    name="page"
    ngDefaultControl
    [totalItems]="totalItems"
    [itemsPerPage]="pageSize"
    [formControl]="pageControl"
    [maxSize]="maxSize"
    [boundaryLinks]="true"
    [customFirstTemplate]="firstTemplate"
    [customLastTemplate]="lastTemplate"
    [customPreviousTemplate]="prevTemplate"
    [customNextTemplate]="nextTemplate"
  ></pagination>
</div>

<ng-template
  #prevTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <ng-container *ngIf="!disabled">
    <i class="fa fa-angle-left"></i>
  </ng-container>
  <ng-container *ngIf="disabled">
    <i class="fa fa-times"></i>
  </ng-container>
</ng-template>

<ng-template
  #nextTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <ng-container *ngIf="!disabled">
    <i class="fa fa-angle-right"></i>
  </ng-container>
  <ng-container *ngIf="disabled">
    <i class="fa fa-times"></i>
  </ng-container>
</ng-template>

<ng-template
  #firstTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <i class="fa fa-angle-double-left"></i>
</ng-template>

<ng-template
  #lastTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <i class="fa fa-angle-double-right"></i>
</ng-template>

<ng-template #noRecords>
  <tr>
    <td [attr.colspan]="2 + (actions.length ? 1 : 0)" class="py-5">
      <app-no-records
        [title]="emptyTitle"
        [message]="emptyMessage"
      ></app-no-records>
    </td>
  </tr>
</ng-template>
