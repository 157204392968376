import { pick as _pick } from 'lodash';

export class Base {
  id: number;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  active: boolean;

  constructor(data?: any) {
    Object.assign(this, _pick(data, [
      'id',
      'created_by',
      'updated_by',
      'created_at',
      'updated_at',
      'deleted_at',
      'active'
    ]));
  }
}

