import {
  Input,
  Component,
  SimpleChanges,
  OnChanges,
  ViewChild,
  ElementRef,
  Injector,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import {
  get as _get,
  find as _find,
  some as _some,
  filter as _filter,
  findIndex as _findIndex,
  isArray as _isArray,
  isEmpty as _isEmpty
} from 'lodash';
import { MainInputComponent } from '../main-input';
import { AbstractControl, ControlContainer, UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { setNiceName } from 'app/utils/format-string';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends MainInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() maxHeight: number | undefined;
  @Input() placement: 'top' | 'bottom' | 'left' | 'right' = 'bottom';
  @Input() options: any[] = [{ name: 'Active', value: true }, { name: 'Inactive', value: false }];
  @Input() selectNone: string = 'Select None';
  _options: any[] = [{ name: 'Active', value: true }, { name: 'Inactive', value: false }];
  @Input() config: {
    name: string;
    value?: string;
    disabled?: string;
    icon?: string;
    src?: string;
    alt?: string;
  } = {
      name: 'name'
    };
  @ViewChild('select') select: ElementRef | undefined;
  @ViewChild('input')
  input!: ElementRef;
  timeout!: NodeJS.Timer;
  scrollTimeout!: NodeJS.Timer;
  _maxHeight!: string;
  scrolling = false;
  scrollPos!: 'top' | 'bottom';
  isArray = _isArray;
  filter = new UntypedFormControl('');
  open = false;
  emoji = false;
  _isEmpty = _isEmpty;

  constructor(
    private ref: ChangeDetectorRef,
    injector: Injector,
    public controlContainer: ControlContainer,
    public elementRef: ElementRef
  ) {
    super(injector, controlContainer, elementRef);
  }

  ngOnInit() {
    // this.emoji = this.context.context$.getValue()?.browserSupport?.emoji;
    this._options = this.options.slice();
    this.niceName = setNiceName(this.controlName);

    if (this.controlName) {
      this.valid = _get(this.control, 'valid', false);
    }

    setTimeout(() => {
      this.control.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.valid = _get(this.control, 'valid', false);
          this.ref.detectChanges();
        });

      this.control.updateValueAndValidity();
    }, 0);
  }

  get required() {
    if (this.control?.validator) {
      return _get(this.control?.validator({} as AbstractControl), 'required', false);
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.name) setTimeout(() => this.niceName = setNiceName(this.controlName), 0);
    if (changes.maxHeight) setTimeout(() => this._maxHeight = this.maxHeight + 'px', 0);
    if (changes.options) {
      this._options = this.options.slice();
    }
  }

  focus() {
    this.select?.nativeElement.focus();
  }
}
