import {
  Component,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormGroup,
  UntypedFormControl,
  ControlContainer,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { get as _get } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { setNiceName } from 'app/utils/format-string';
import { DateTime } from 'luxon';
import { Toast } from '@shared/toasts/toast';
import { NotifyService } from '@services/notify.service';

// date input usage
// <app-date-input controlName="date" label="Input Name"></app-date-input>

@Component({
  selector: 'app-date-input',
  templateUrl: 'date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent implements OnInit, OnChanges, OnDestroy {
  _control = new UntypedFormControl();
  _formGroup = new UntypedFormGroup({ '': this._control });
  @Input()
  set control(control) {
    if (control) {
      this._control = control;
    }
  };
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() type: 'date' | 'datetime-local' = 'date';
  @Input() max!: string;
  @Input() min!: string;
  @Input() hideLabel!: '' | boolean;
  @Input() hideErrors!: '' | boolean;
  @Output() blur = new EventEmitter<Event>();
  @ViewChild('dateInput')
  dateInput!: ElementRef;
  _date: string = '';
  _time: string = '';
  _prevDate!: string;
  niceName!: string;
  destroyed$: Subject<boolean> = new Subject<boolean>();
  changes = true;
  placement: 'top' | 'bottom' = 'bottom';
  dateTimeSupport = false;
  mask = [/[1-2]/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/, '-', /[0-3]/, /\d/];
  dateGroup = new UntypedFormGroup({
    _dateTime: new UntypedFormControl(''),
    _date: new UntypedFormControl(''),
    _time: new UntypedFormControl(''),
    _pickDate: new UntypedFormControl(''),
  });


  constructor(
    public controlContainer: ControlContainer,
    private notify: NotifyService
  ) {
    this.checkBrowserSupport();
    this.dateGroup.controls._pickDate.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(date => {
        date = date ? new Date(date) : null;
        this.setDate(date);
      });
  }

  ngOnInit() {
    if (this.controlName) {
      this.niceName = setNiceName(this.controlName);
    }
    this.setValidators();
  }

  checkBrowserSupport() {
    if (navigator.userAgent.includes('Firefox')) {
      this.dateTimeSupport = false;
    } else {
      try {
        const input = document.createElement('input');
        const value = 'Hello World!';
        input.setAttribute('type', 'date');
        input.setAttribute('value', value);
        this.dateTimeSupport = (input.value !== value);
      } catch (err) {
        this.dateTimeSupport = false;
      }
    }
  }

  setValidators() {
    Object.keys(this.dateGroup.controls).forEach(control => {
      if (this.required) {
        this.dateGroup.controls[control].setValidators([Validators.required]);
      } else {
        this.dateGroup.controls[control].clearValidators();
      }
    });
  }

  // reset value if changed from parent
  ngOnChanges(changes: SimpleChanges) {
    if (changes.controlName) {
      if (this.controlName) {
        this.niceName = setNiceName(this.controlName);
      }
    }

    if (changes.type) {
      if (!changes.type.firstChange && this.control.value) {
        const { _dateTime } = this.dateGroup.value;
        this.changes = true;

        switch (changes.type.currentValue) {
          case 'date':
            this.control.setValue(`${_dateTime.split('T')[0]}T00:00:00.000Z`);
            break;
          case 'datetime-local':
            const { hour, minute, second } = DateTime.now().setZone('utc').toObject();
            const date = `${DateTime.fromFormat(_dateTime, 'yyyy-MM-dd')
              .toISO()}`;
            this.control.setValue(DateTime.fromISO(date, { zone: 'local' })
              .setZone('utc')
              .set({ hour, minute, second })
              .toISO());
            break;
        }
      }

      setTimeout(() => {
        if (this.type === 'datetime-local') {
          this.mask = [/[1-2]/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/, '-', /[0-3]/, /\d/, ' ', /[0-2]/, /[0-9]/, ':', /[0-6]/, /[0-9]/];
        } else {
          this.mask = [/[1-2]/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/, '-', /[0-3]/, /\d/];
        }
      }, 0);
    }

    if (this.changes) {
      this.dateChange();
    }

    setTimeout(() => {
      if (changes.name) {
        this.niceName = setNiceName(this.controlName);
      }
    }, 0);

    this.changes = true;
  }

  get control() {
    const control = this.formGroup?.get(this.controlName) as UntypedFormControl || this._control;
    if (control.disabled !== this.dateGroup.disabled) {
      control.disabled ? this.dateGroup.disable() : this.dateGroup.enable();
    }
    return control;
  }

  get required() {
    if (this.control?.validator) {
      return _get(this.control?.validator({} as AbstractControl), 'required', false);
    }
    return false;
  }

  get formGroup() {
    return this.controlContainer.control as UntypedFormGroup || this._formGroup;
  }

  dateChange() {
    setTimeout(() => {
      const value = _get(this.control, 'value');
      const { _dateTime, _pickDate, _date, _time } = this.dateGroup.controls;

      if (value) {
        if (this.type === 'date') {
          _dateTime.setValue(value.slice(0, 10));
          _date.setValue(value.slice(0, 10));
          _pickDate.setValue(value ? DateTime.fromISO(value).setZone('utc').toFormat('MM/dd/yyyy') : null);
        } else {
          const luxDate = DateTime.fromJSDate(new Date(value));

          _dateTime.setValue(`${luxDate.toFormat('yyyy-MM-dd')}T${luxDate.toFormat('HH:mm')}`);
          _date.setValue(luxDate.toFormat('yyyy-MM-dd'));
          _time.setValue(luxDate.toFormat('HH:mm'));
          _pickDate.setValue(value ? DateTime.fromISO(value).toJSDate() : null);
        }
        this._prevDate = _dateTime.value;
      } else {
        _dateTime.setValue(null);
      }
    }, 0);
  }

  toggleDate(datePicker: BsDatepickerDirective, input: ElementRef) {
    const { _dateTime, _date } = this.dateGroup.controls;
    const inputDate = this.dateTimeSupport ? _dateTime.value : _date.value;

    if (!datePicker.isOpen) {
      const bottom = input.nativeElement.getBoundingClientRect().bottom + 282;
      if (window.innerHeight <= bottom) {
        this.placement = 'top';
      } else {
        this.placement = 'bottom';
      }

      this.setPickerDate(inputDate);
      setTimeout(() => datePicker.show(), 0);
    } else {
      this.dateInput.nativeElement.focus();
    }
  }

  setDate(date: Date) {
    const { _dateTime, _date, _time } = this.dateGroup.controls;

    if (date) {
      const zone = DateTime.fromJSDate(date).toFormat('ZZ');
      if (this.type === 'date') {
        _dateTime.setValue(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'));
        _date.setValue(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'));
        this.control.setValue(`${_dateTime.value}T00:00:00.000Z`);
      } else {
        const { hour, minute, second } = DateTime.fromISO(_dateTime.value, { zone: 'local' }).setZone('utc').toObject();

        const time = _dateTime.value ? `${_dateTime.value.split('T')[1]}` : `${DateTime.now().toFormat('HH:mm')}`;
        _dateTime.setValue(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd') + 'T' + time);
        _date.setValue(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'));
        _time.setValue(time);

        this.changes = false;
        this.control.setValue(DateTime.fromJSDate(date)
          .setZone('utc')
          .set({ hour, minute, second })
          .toISO());
      }
      this._prevDate = _dateTime.value;
    } else {
      this.control.setValue(null);
    }
  }

  setPickerDate(date: string) {
    const { _dateTime, _pickDate } = this.dateGroup.controls;
    if (date && this._prevDate !== date) {
      date = date.replace(/\s/g, 'T');
      const luxDate = DateTime.fromFormat(date, 'yyyy-MM-dd');
      const validDate = luxDate.isValid;

      if (validDate) {
        if (this.type === 'date') {
          _pickDate.setValue(date ? luxDate.toJSDate() : null);
          this.control.setValue(`${date}T00:00:00.000Z`);
        } else {
          const zone = luxDate.toFormat('ZZ');
          const seconds = luxDate.toFormat(':ss');
          _pickDate.setValue(date ? luxDate.toJSDate() : null);
          this.changes = false;
          this.control.setValue(DateTime.fromJSDate(new Date(`${date}${seconds}${zone}`)).setZone('utc').toISO());
        }
        this._prevDate = date;
      }
    }
  }

  // set touched on blur
  onBlur(event: Event) {
    this.dateGroup.markAllAsTouched();
    this.control.markAsTouched();

    const { _dateTime, _date, _time } = this.dateGroup.controls;
    const inputDate = this.dateTimeSupport || this.type === 'date' ? _dateTime.value : _date.value;

    if (_get(this.dateInput, 'nativeElement.validity.badInput', false)) {
      this.notify.toast({
        msg: `"${this.niceName}" is not a valid date. Resetting to last valid date value.`,
        type: 'warning',
        id: `${this.controlName}-date-error`,
        update: true
      } as Toast);
      _dateTime.setValue(this._prevDate);
      _date.setValue(this._prevDate);
    }

    if (inputDate) {
      if (this.type === 'date') {
        this.control.setValue(`${inputDate}T00:00:00.000Z`);
      } else {
        const luxDate = DateTime.fromJSDate(new Date(inputDate));
        const zone = luxDate.toFormat('ZZ');
        const seconds = luxDate.toFormat(':ss');

        this.control.setValue(DateTime.fromJSDate(new Date(`${inputDate}${this.dateTimeSupport ? '' : 'T' + _time.value
          }${seconds}${zone}`)).setZone('utc').toISO());
      }
    } else {
      this.control.setValue(null);
    }
    this.blur.emit(event);
  }

  dpBlur() {
    this.focus();
    this.blur.emit();
  }

  onFocus(event: FocusEvent) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(event.target as HTMLElement);
    range.collapse(false);
    selection?.removeAllRanges();
    selection?.addRange(range);
  }

  focus() {
    this.dateInput.nativeElement.focus();
  }

  setNiceName() {
    let words: (string | null)[] = (this.controlName || '').split(/(?=[A-Z\s_-])/);
    words = words.map(w => {
      w = (w as string).replace(/[-_\s+]+/g, '');
      if (w[0]) {
        return w[0].toUpperCase() + w.slice(1);
      } else {
        return null;
      }
    });
    return words.filter(w => w !== null).join(' ');
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
