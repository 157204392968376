export function setNiceName(name: string) {
  const wordRegex = /[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g;
  const words = (name || '').match(wordRegex) || [];

  const results = words.map(w => {
    if (w[0]) {
      return w[0].toUpperCase() + w.slice(1);
    } else {
      return null;
    }
  });
  return results.filter(w => w !== null).join(' ');
}