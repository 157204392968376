import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NavigationComponent } from '@shared/navigation/navigation.component';
import { Location } from '@models/location';
import { Subject } from 'rxjs';
import { LocationService } from '@services/location.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-admin-layout',
  templateUrl: '../main-layout/main-layout.component.html',
  styleUrls: ['../main-layout/main-layout.component.scss']
})
export class AdminLayoutComponent implements OnDestroy {
  preLogout = () => {
    this.navigation?.signOut();
  };
  location: Location;
  asUser?: number;
  destroyed$ = new Subject();
  
  navTop = [
    {
      name: "Dashboard",
      url: '/admin/dashboard',
      icon: "/assets/images/navigation/icon-dashboard.svg",
      access: 3,
    },
    {
      name: "Checkin",
      url: "/admin/checkin",
      icon: "/assets/images/navigation/icon-checkin.svg",
      access: 2,
    },
    {
      name: "Customers",
      url: "/admin/customers",
      icon: "/assets/images/navigation/icon-customers.svg",
      access: 3,
    },
    {
      name: "Locations",
      url: '/admin/locations',
      icon: "/assets/images/navigation/icon-locations.svg",
      access: 3,
    },
    {
      name: "Packages",
      url: `/admin/packages/${this.locationId}`,
      icon: "/assets/images/navigation/icon-packages.svg",
      access: 3,
    },
    {
      name: "Calendar",
      url: '/admin/calendar',
      icon: "/assets/images/input-icons/icon-calendar.svg",
      access: 3,
    },
    {
      name: "Codes",
      url: '/admin/codes',
      icon: "/assets/images/book-appointment/icon-gift-card.svg",
      access: 3,
    },

  ];
  navBottom = [
    {
      name: "Users",
      url: "/admin/users",
      access: 4,
    },
    {
      name: "Survey",
      url: undefined,
      access: 4,
    },
    {
      name: "Advertising",
      url: undefined,
      access: 4,
    },
    {
      name: "Log Out",
      fn: this.preLogout,
      access: 2,
    },
  ];
  minNavHeight = 760;
  @ViewChild('navigation') public navigation: NavigationComponent;

  constructor(
    private locations: LocationService
  ) { 
    this.locations.coords$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(coords => {
      this.locations.getClosest(coords)
        .subscribe((location) => this.location = location);
    });

  }

  get locationId() {
    return this.location?.id || 1;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
