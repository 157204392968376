<div class="notifications">
	<h2>Notifications</h2>

	<app-notification *ngFor="let n of notifications"
							[notification]="n">
	</app-notification>

	<div class="alert alert-info no-notifications text-center"
		  *ngIf="notifications && notifications.length == 0">
		No messages to display.
	</div>

	<app-upcoming-appt></app-upcoming-appt>
	<app-support></app-support>
</div>
