<header>
  <div class="nav-container">
    <div class="nav-heading">
      <div class="menu-icons">
        <img
          *ngIf="!open"
          (click)="toggleNav(true)"
          src="/assets/images/navigation/menu.svg"
          class="menu-icon"
        />
        <img
          *ngIf="open"
          (click)="toggleNav(false)"
          src="/assets/images/book-appointment/icon-modal-close.svg"
          class="menu-icon"
        />
      </div>
      <img src="/assets/images/HMS-02.png" class="hm-logo" />
    </div>
    <div
      class="nav-links d-block position-relative h-100"
      [ngStyle]="{ transition: transition }"
      [ngClass]="{ open: open }"
    >
      <div class="nav-scroll">
        <div
          class="scroll-content"
          [ngStyle]="{ 'min-height': minHeight + 'px' }"
        >
          <ul class="nav-top">
            <ng-container *ngFor="let itemTop of navTop">
              <li
                *ngIf="!!itemTop.access ? accessLevel >= itemTop.access : true"
                [ngClass]="{ disabled: !itemTop.url }"
              >
                <a
                  [routerLink]="
                    (asUser?.id ? '/as-user/' + asUser?.id : '') + itemTop.url
                  "
                  (click)="toggleNav(false)"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: false }"
                >
                  <img [src]="itemTop.icon" class="nav-icon" />
                  {{ itemTop.name }}
                </a>
              </li>
            </ng-container>
            <ng-container *ngIf="asUser?.id">
              <li class="as-user-name">
                <span class="d-block"
                  >Viewing: {{ asUser.person.first_name }}
                  {{ asUser?.person?.last_name }}</span
                >
              </li>
              <li>
                <a [routerLink]="'/admin/customers/' + asUser?.id" [queryParams]="{ page: 1 }">
                  <img src="/assets/images/settings.svg" class="nav-icon" />
                  Return to Admin
                </a>
              </li>
            </ng-container>
          </ul>
          <ul class="nav-bottom">
            <ng-container *ngFor="let itemBottom of navBottom">
              <li
                *ngIf="
                  !!itemBottom.access ? accessLevel >= itemBottom.access : true
                "
                [ngClass]="{ disabled: !itemBottom.url && !itemBottom.fn }"
              >
                <a
                  *ngIf="!itemBottom.fn; else fnItem"
                  (click)="toggleNav(false)"
                  [routerLink]="
                    (asUser?.id ? '/as-user/' + asUser?.id : '') +
                    itemBottom.url
                  "
                  routerLinkActive="active"
                  >{{ itemBottom.name }}</a
                >
                <ng-template #fnItem>
                  <a (click)="itemBottom.fn()">{{ itemBottom.name }}</a>
                </ng-template>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
