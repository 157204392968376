import { pick as _pick } from 'lodash';
import { Base } from "./base";
import { Person } from './person';
import { Location } from './location';
import { Appointment } from './appointment';
import { Role } from './role';
import { UserRole } from './user-role';

export class User extends Base {
  email: string;
  username: string;
  password: string;
  User: any;
  person: Person;
  next_appt: any;
  userroles: any[];
  location: Location;
  latest_appointment: Appointment;
  role: Role;
  user_role: UserRole;
  appointments: Appointment[];
  reset?: boolean;
  active: boolean;

  constructor(data?: Partial<User>) {
    super(data);

    Object.assign(this, _pick(data, [
      'email',
      'username',
      'password',
      'User',
      'next_appt',
      'roles',
      'person',
      'active'
    ]));

    if (data?.location && data?.person) data.person.location = data.location;
    this.person = data?.person ? new Person(data.person) : null;
    this.latest_appointment = data?.latest_appointment ? new Appointment(data.latest_appointment) : null;
    this.role = data?.role ? new Role(data.role) : null;
    this.user_role = data?.user_role ? new UserRole(data.user_role) : null;
    this.appointments = data?.appointments ? data.appointments : [];
  }
}

