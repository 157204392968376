import {
  Component,
  Input,
  OnChanges,
  Injector,
  ElementRef,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { MainInputComponent } from '../main-input';

const noop = () => { };


@Component({
  selector: 'app-integer-input',
  template:
    `
      <div [formGroup]="formGroup" class="input-container">
        <div #group class="input-group">
            <ng-content select=".input-group-prepend"></ng-content>
            <input #input
                  type="text"
                  [formControlName]="controlName"
                  [mask]="mask"
                  ngDefaultControl
                  (blur)="toNumber()"
                  [required]="required"
                  [ngClass]="{
                    set: control.value,
                    first: first,
                    last: last  
                  }"
                  [attr.readonly]= "(readonly || readonly === '') ? '' : undefined"
                    class="form-control">
            <label for="integer" *ngIf="!hideLabel && hideLabel !== ''" [ngStyle]="{ left: left }">
              {{ label || niceName }}<span *ngIf="required" class="required">*</span>
            </label>
        </div>
      </div><div class="error">
        <ng-container *ngIf="control?.errors?.required && control?.touched">
          {{ requiredName || label || niceName }} is required
        </ng-container>
      </div>`
})
export class IntegerInputComponent extends MainInputComponent implements OnChanges {
  @Input() maxDigits = 5;
  @Input() asString = false;
  @Input() anyLength: boolean | '' = false;
  public mask = '0000000000000000000000000';
  public variableMask = '9999999999999999999999999';

  constructor(
    injector: Injector,
    controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super(injector, controlContainer, elementRef);
  }

  onInit = () => {
    this.mask = ([true, ''].includes(this.anyLength) ? this.variableMask : this.mask).slice(0, this.maxDigits);
    if (!this.control.value) this.control.setValue(null);
    setTimeout(() => this.setLabelPosition(), 0);
  }

  toNumber() {
    const value = this.control.value;
    if (!this.asString && typeof value !== 'number') {
      this.control.setValue(+(value || '').replace(/\,/g, '') || null);
    }
  }
}
