import { Pipe, PipeTransform } from '@angular/core';
import { get as _get } from 'lodash';

@Pipe({ name: 'appMailTo' })
export class MailToPipe implements PipeTransform {
  transform(record: any, path?: string) {
    if (path) {
      const email = _get(record, path);
      return email ? `mailto:${email}` : '';
    }
    return '';
  }
}
