<div class="container-fluid vh-100">
    <div class="row justify-content-center align-items-center vh-100">
        <div class="col-12 col-md-6 col-xl-4">
            <div class="text-center mb-5">
                <img src="../assets/images/HMS-02.png"/>
            </div>

            <div class="row justify-content-center create-account-hdr"
            *ngIf="mode === 'kiosk'">
                <h3 class="text-center">
                    Welcome to the <strong>Holiday Magic</strong> Kiosk. Fill
                    out form below to get started.
                </h3>
            </div>

            <div class="create-account-box p-4">
                <h2 class="text-center mb-3">{{accountHeading}}</h2>
                <form [formGroup]="createAccountForm"
                          *ngIf="mode === 'kiosk'">
                        <div class="row">
                            <div class="col-md-4">
                                <app-input controlName="firstName"/>
                            </div>
                            <div class="col-md-4">
                                <input type="text"
                                       name="lastName"
                                       formControlName="lastName"
                                       placeholder="Last Name"
                                       class="form-control">
                            </div>
                            <div class="col-md-4">
                                <input type="tel"
                                       name="phone"
                                       formControlName="phone"
                                       placeholder="Phone Number"
                                       class="form-control">
                            </div>
                        </div>
                        <div formGroupName="emails"
                             class="row">
                            <div class="col-md-6">
                                <input type="email"
                                       name="email"
                                       formControlName="email"
                                       placeholder="Email Address"
                                       class="form-control">
                                <div *ngIf="email.errors?.email && email.touched"
                                     class="row">
                                    <div class="col-md-12 text-danger small-warning">
                                        Email must be valid email.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input type="email"
                                       name="emailConfirm"
                                       formControlName="emailConfirm"
                                       placeholder="Confirm Email"
                                       class="form-control">
                                <div *ngIf="emailConfirm.errors?.MatchEmail && email.touched"
                                     class="row">
                                    <div class="col-md-12 text-danger small-warning">
                                        Both emails must match.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div formGroupName="passwords"
                             class="row">
                            <div class="col-md-6">
                                <input type="password"
                                       name="password"
                                       formControlName="password"
                                       placeholder="Enter Password"
                                       class="form-control">
                            </div>
                            <div class="col-md-6">
                                <input type="password"
                                       name="passwordConfirm"
                                       formControlName="passwordConfirm"
                                       placeholder="Confirm Password"
                                       class="form-control">
                                <div *ngIf="passwordConfirm.errors?.MatchPassword && password.touched"
                                     class="row">
                                    <div class="col-md-12 text-danger small-warning">
                                        Both passwords must match.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="alert"
                             class="row">
                            <div class="col">
                                <div class="alert alert-danger">
                                    {{alert.message}}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <app-button #createAccountButton
                                            [classNames]="'btn btn-primary btn-block btn-lg'"
                                            (click)="createAccount(createAccountForm.value)"
                                            [disableOnClick]="false"
                                            [isDisabled]="!createAccountForm.valid">
                                    Create Account
                                </app-button>
                            </div>
                        </div>
                    </form>
                    <form *ngIf="mode !== 'kiosk'"
                          [formGroup]="createAccountForm">
                        <div class="row">
                            <div class="signup-input col-md-6">
                                <app-input controlName="firstName"/>
                            </div>
                            <div class="signup-input col-md-6">
                                <app-input controlName="lastName"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="signup-input col-md-6">
                                <app-phone-input controlName="phone" #phoneFocus/>
                            </div>
                            <div class="signup-input col-md-6" formGroupName="emails">
                                <app-email-input controlName="email"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 signup-input" formGroupName="passwords"
                                *ngIf="!socialLogin">
                                    <app-password-input controlName="password"/>
                            </div>
                        </div>
                        <div *ngIf="email.errors?.email && email.touched"
                             class="row">
                            <div class="col-md-12 text-danger small-warning">
                                Email must be valid email.
                            </div>
                        </div> 
                        <div *ngIf="alert" class="row">
                            <div class="col">
                                <div class="alert alert-danger">
                                    {{alert.message}}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <app-submit-button #createAccountButton
                                            text="CREATE ACCOUNT"
                                            [classNames]="'btn btn-primary btn-block mb-3'"
                                            (clicked)="createAccount(createAccountForm.value)"
                                            [disableOnClick]="false"
                                            [loading]="loading"
                                            [disabled]="!createAccountForm.valid" />
                            </div>
                        </div>
                    </form>
                    <div class="row"
                 *ngIf="!socialLogin">
                <div class="col-md-12 text-center supporting-text">
                    Already have an account?
                    <a href="#" (click)="gotoLogin($event)">
                       Log in
                    </a>
                </div>
            </div>
            </div>
            
        </div>
    </div>  
</div>