import { pick as _pick } from 'lodash';
import { Base } from './base';
import { User } from './user';

export class Folder extends Base {
  name: string;
  type: string;
  description: string;
  node_id: number;
  sm_folder_obj: any;
  user_id: number;
  user?: User;

  constructor(data?: Partial<Folder>) {
    super(data);

    Object.assign(this, _pick(data, [
      'name',
      'type',
      'description',
      'node_id',
      'sm_folder_id',
      'user_id'
    ]));

    this.user = data?.user ? new User(data?.user) : null;
  }
}