import { pick as _pick } from 'lodash';
import { Base } from './base';
import { User } from './user';
import { Location } from './location';

export class Person extends Base {
  public user_id: number;
  public first_name: string;
  public last_name: string;
  public address: string;
  public city: string;
  public state: string;
  public zip: string;
  public phone: string;
  public full_name: string;
  public User: User;
  public location: Location;
  public location_id: number;

  constructor(data?: Partial<Person>) {
    super(data);

    Object.assign(this, _pick(data, [
      'user_id',
      'first_name',
      'last_name',
      'full_name',
      'address',
      'city',
      'state',
      'zip',
      'phone',
      'location_id'
    ]));

    this.User = data?.User ? new User(data.User) : null;
    this.location = data?.location ? new Location(data.location) : null;
    this.phone = data?.phone ? data?.phone.replace('+1', '') : null;
  }
}
