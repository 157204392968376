import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  HostListener,
  ViewChild,
  ElementRef,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { get as _get } from 'lodash';
import { setNiceName } from 'app/utils/format-string';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';

// appTextarea usage

// <app-textarea controlName="controlName"></app-textarea>

@Component({
  selector: 'app-textarea',
  styleUrls: ['./textarea.component.scss'],
  templateUrl: './textarea.component.html',
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class TextareaComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() controlName!: string;
  @Input() maxlength = '1024';
  @Input() label!: string;
  @Input() placeholder = '';
  @Input() appFocus!: boolean | '';
  @Input() appMarkdown!: boolean | '';
  @Input() options: { name: string, value: string, record?: any }[] = [];
  @Output() selectOption = new EventEmitter<any>();
  @ViewChild('text', { static: false }) public text!: ElementRef;
  @ViewChild('textarea', { static: false }) public textarea!: ElementRef;
  @ViewChild('error', { static: true }) private error!: ElementRef;
  truncated: string | null = null;
  cancelled = false;
  niceName: string;
  subscriptions: Subscription[] = [];
  valid = false;
  selectionStart: number = 0;
  selectionEnd: number = 0;
  selection: string = '';
  preview = false;

  constructor(
    public controlContainer: ControlContainer,
    public modalService: BsModalService,
    private elementRef: ElementRef
  ) {
    this.niceName = setNiceName(this.controlName);
  }

  ngAfterViewInit() {
    if (this.appFocus || this.appFocus === '') this.textarea?.nativeElement?.focus();
    setTimeout(() => {
      this.valid = this.control?.valid;
      if (this.control) this.subscriptions.push(this.control.statusChanges.subscribe(status => this.valid = status === 'VALID'));
    }, 0);
  }

  get control() {
    return this.formGroup.get(this.controlName) as UntypedFormControl || new UntypedFormControl();
  }

  get required() {
    if (this.control?.validator) {
      return _get(this.control?.validator({} as AbstractControl), 'required', false);
    }
    return false;
  }

  get formGroup() {
    return this.controlContainer.control as UntypedFormGroup || new UntypedFormGroup({ [this.controlName]: new UntypedFormControl() });
  }

  // reset value if changed from parent
  ngOnChanges(changes: SimpleChanges) {
    this.niceName = this.label || setNiceName(this.controlName);
    if (changes.maxlength) {
      this.setCount();
    }
  }

  // get selection start and end
  getSelection() {
    if (!/\S/.test(this.control.value)) this.control.setValue('');
    else this.control.setValue((this.control.value || '').trim());

    const text = this.control.value === null ? '' : this.control.value;
    this.selectionStart = this.textarea.nativeElement.selectionStart;
    this.selectionEnd = this.textarea.nativeElement.selectionEnd;
    this.selection = text.slice(this.selectionStart, this.selectionEnd);
    this.setCharacterCount();
  }

  focus() {
    this.text.nativeElement.focus();
  }

  // output text on keyup
  @HostListener('keyup') setCharacterCount() {
    const error = this.error.nativeElement;
    const length = (_get(this.control, 'value.length') || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const maxlength = this.maxlength.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (!this.cancelled) {
      error.textContent = _get(this.control, 'value.length') ? `Count: ${length}${maxlength ? ' / ' + maxlength : ''}` : '';
    }
  }

  // limit characters when typing
  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    const error = this.error.nativeElement;
    const length = this.control.value?.length || 0;

    if (
      ((+this.maxlength && +this.maxlength <= length) && (window.getSelection() || 0).toString().length === 0) &&
      [8, 9, 37, 38, 39, 40].indexOf(event.keyCode) === -1 &&
      !(event.metaKey || event.ctrlKey)
    ) {
      event.preventDefault();
      error.textContent = `${this.niceName || 'Value'} must be ${this.maxlength} characters or less.`;
      error.style.color = '#c50000';
      this.cancelled = true;
    } else {
      error.style.color = '#000';
      this.cancelled = false;
    }
  }

  // limit characters when pasting
  @HostListener('cut')
  @HostListener('paste') setCount() {
    setTimeout(() => {
      const error = this.error.nativeElement;
      const maxlength = +this.maxlength;
      const data = this.control.value === null ? '' : this.control.value;
      const truncated = data.slice(0, maxlength);
      this.control.setValue(truncated);

      // error message if content over limit
      if (maxlength && data.length > maxlength) {
        error.textContent =
          `${this.niceName || 'Value'} must be ${maxlength} characters or less. ${this.niceName || 'Value'} has been truncated.`;
        error.style.color = '#c50000';
        this.cancelled = true;
      } else {
        const length = (data.length || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        const max = this.maxlength.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        error.textContent = length ? `Count: ${length}${max ? ' / ' + max : ''}` : '';
        error.style.color = '#000';
        this.cancelled = false;
      }
    }, 0);
  }


  expandSelect(action: string) {
    const range = window.getSelection();
    const textarea = this.textarea.nativeElement;
    let cursor = this.selectionStart;
    // match word characters if not link or all characters used in links if link
    const pattern = action === 'link' ? /[\w\[\]\(\)\:\/\.]/g : /\w/g;


    const linkMatch = [...this.control.value.matchAll(/\[.*\]\(.*\)/g)]
      .filter(match => this.selectionStart >= match.index && this.selectionStart <= match.index + match[0].length)?.[0];

    if (linkMatch) {
      const start = linkMatch.index;
      const end = linkMatch.index + linkMatch[0].length;
      textarea.focus();
      textarea.setSelectionRange(start, end);
    } else {
      const wordMatch = [...this.control.value.matchAll(/\W.[\w\-\.]*\W/g)]
        .filter(match => this.selectionStart >= match.index && this.selectionStart <= match.index + match[0].length)?.[0];

      if (wordMatch) {
        const start = wordMatch.index + 1;
        const end = wordMatch.index + wordMatch[0].length - 1;
        textarea.focus();
        textarea.setSelectionRange(start, end);
      }
    }

    this.getSelection();
  }

  get first() {
    return !this.elementRef?.nativeElement?.getElementsByClassName('input-group-prepend')?.length;
  }

  get last() {
    return !this.elementRef?.nativeElement?.getElementsByClassName('input-group-append')?.length;
  }

  togglePreview() {
    this.preview = !this.preview;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
