import { inject } from "@angular/core";
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChildFn } from "@angular/router";
import { NotifyService } from "@services/notify.service";
import { UserService } from "@services/user.service";


export const RoleGuard = (access: number): CanActivateFn => {
   return (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    router: Router = inject(Router),
    userService: UserService = inject(UserService),
    notify: NotifyService = inject(NotifyService)
  ) => {
  
    const adminFailed = (accessLevel) => {
      if (accessLevel > 1) {
        router.navigate(['admin']);
      } else {
        router.navigate(['home']);
      }
  
      notify.toast({
        id: 'no-admin-access',
        title: 'Access Denied',
        msg: `You do not have access to this page.${router.url.includes('/admin') ? 'Redirecting to home page.' : ''}`,
        type: 'warning'
      });
    };
  
    try {
      const userAccessLevels = (userService.getUser()?.userroles || []).map(userRole => userRole.roles?.access);
      const accessLevel = Math.max(...userAccessLevels);
  
      if (accessLevel < access) {
        adminFailed(accessLevel);
        return false;
      } 
  
      return true;
    } catch (e) {
      adminFailed(0);
      return false;
    }
  };
}
export const RoleGuardChild = (access: number): CanActivateChildFn => {
  return RoleGuard(access);
}