import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MainTableComponent } from './main-table.component';
import { NotifyService } from '@services/notify.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent extends MainTableComponent {
  constructor(
    public modalService: BsModalService,
    public notify: NotifyService
  ) {
    super(modalService, notify)
  }
}
