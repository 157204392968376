
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Notification} from '../models/notification';
import {HttpService} from './api.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import * as k2f from 'kelvin-to-fahrenheit';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  notifications: Notification[];
  temp: any;

  constructor(private http: HttpClient, private httpService: HttpService) {
  }

  /******* DB Queries *******/

  /**
   * Get Notifications
   * res [{}]
   */
  getNotifications(): Promise<Notification[]> {
    return this.httpService.get(`/notification`)
      .toPromise()
      .then((res) => {
        return res.data as Notification[];
      });
  }

  /**
   * Get a Single Notification
   * req: notificationId:number
   * res {}
   */
  getNotification(notificationId: number): Promise<Notification> {
    return this.httpService.get(`/notification/id/${notificationId}`)
      .toPromise()
      .then((res) => {
        return res.data as Notification;
      });
  }

  /**
   * Create a Single Notification
   * req: notification:Notification
   * res {}
   */
  createNotification(notification: Notification): Promise<Notification> {
    return this.httpService.post(`/notification`, notification)
      .toPromise()
      .then((res) => {
        return res.data as Notification;
      });
  }

  /**
   * Update a Single Notification
   * req: notification:Notification
   * res {}
   */
  updateNotification(notification: Notification): Promise<Notification> {
    return this.httpService.put(`/notification/id/${notification.id}`, notification)
      .toPromise()
      .then((res) => {
        return res.data as Notification;
      });
  }

  /**
   * Get User Notifications
   * res [{}]
   */
  getUserNotifications(userId: number): Promise<Notification[]> {
    let data = {
      "get": ["*"],
      "where": {"user_id": userId},
      "sort": {'id': 'desc'}
    };
    return this.httpService.post(`/notification/get`, data)
      .toPromise()
      .then((res) => {
        this.notifications = res.data.data;
        return res.data.data as Notification[];
      });
  }


  /**
   * Helper function to get weather.
   */
  getWeather() {
    if (this.temp) {
      return Observable.create(observer => {
        observer.next(this.temp);
      });
    } else {
      return this.http.get(`${environment.weatherAPI}93721&appid=${environment.weatherToken}`).pipe(
        map(res => res['main'].temp),
        map(k2f),
        map((temperature: number) => temperature.toFixed(0)),
        map(temp => {
          this.temp = temp;
          return temp;
        }),);
    }
  }
}
