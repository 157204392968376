import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";

@Injectable({ providedIn: "root" })
export class HttpService {
  private apiUrl = `${environment.api}/${environment.apiVersion}`;
  private host = `${environment.host}`;
  private errorMessage: string = "Token invalid.";

  constructor(private http: HttpClient, private router: Router) {}

  /**
   * Runs a get request.
   *
   * @param {string} url
   * @param {any} requestOptions
   * @returns {Observable<any>}
   */
  public get(
    url: string,
    params: any = null,
    api = this.apiUrl
  ): Observable<any> {
    return this.http
      .get(api + url, authHeaders(params))
      .pipe(catchError((error: any) => this.handleAuthenticationErrors(error)));
  }

  /**
   * Runs a smugmug get request.
   *
   * @param {string} url
   * @param {any} requestOptions
   * @returns {Observable<any>}
   */
  public v2Get(
    url: string,
    params?: any
  ): Observable<any> {
    return this.http
      .get(this.host + url, authHeaders(params))
      .pipe(catchError((error: any) => this.handleAuthenticationErrors(error)));
  }

  /**
   * Runs a post request.
   *
   * @param {string} url
   * @param body
   * @returns {Observable<any>}
   */
  public post(url: string, body: any, api = this.apiUrl): Observable<any> {
    return this.http
      .post(api + url, body, authHeaders())
      .pipe(catchError((error: any) => this.handleAuthenticationErrors(error)));
  }

  /**
   * Runs a put request.
   *
   * @param {string} url
   * @param body
   * @returns {Observable<any>}
   */
  public put(url: string, body: any = {}, api = this.apiUrl): Observable<any> {
    return this.http
      .put(api + url, body, authHeaders())
      .pipe(catchError((error: any) => this.handleAuthenticationErrors(error)));
  }

  /**
   * Runs a patch request.
   *
   * @param {string} url
   * @param body
   * @returns {Observable<any>}
   */
  public patch(
    url: string,
    body: any = {},
    api = this.apiUrl
  ): Observable<any> {
    return this.http
      .patch(api + url, body, authHeaders())
      .pipe(catchError((error: any) => this.handleAuthenticationErrors(error)));
  }

  /**
   * Runs a delete request.
   *
   * @param {string} url
   * @returns {Observable<any>}
   */
  public del(url: string, _: void, api = this.apiUrl): Observable<any> {
    return this.http
      .delete(api + url, authHeaders())
      .pipe(catchError((error: any) => this.handleAuthenticationErrors(error)));
  }

  googleGet(resource: string, args: any = {}) {
    if (!args.params) {
      args.params = {};
    }
    Object.assign(args.params, { key: environment.gApiKey });
    return this.get(resource, args, environment.gApiUrl);
  }

  /**
   * Throws an error and navigates to the login route.
   *
   * @param {any} error
   * @returns {Observable<any>}
   */
  private handleAuthenticationErrors(error: any): Observable<any> {
    if (error && error.status === 403) {
      console.warn("User token is invalid. Redirecting to login.");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      this.router.navigate(["/login"]);
    }

    return observableThrowError(error);
  }

  saveImage(url: string, image: Blob) {
    // Upload the image to our pre-signed URL.
    return this.http.put(url, image, {
      headers: new HttpHeaders({
        'Content-Type': 'image/jpeg'
      })
    });
  }

  toBlob(dataUrl: string | null | undefined, sliceSize = 512) {
    if (dataUrl && dataUrl.startsWith('data:')) {
      const [_, contentType, base64] = dataUrl.split(/data:|;base64,/);
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: contentType });
    }
    return;
  }
}

// applies authorization headers to google api calls
export function gAuthHeaders(params: any) {
  const headers: any = {
    params: new HttpParams({ fromObject: params }),
    headers: new HttpHeaders(),
  };
  return headers;
}

// applies authorization headers to server calls
export function authHeaders(params: any = {}): any {
  const token = localStorage.getItem("token");
  const newHeaders: any = {
    "Content-Type": "application/json",
  };

  if (token) {
    newHeaders["Authorization"] = `Bearer ${token}`;
  }

  const headers: any = {
    headers: new HttpHeaders(newHeaders),
    params: new HttpParams({ fromObject: params }),
    withCredentials: true,
  };
  return headers;
}

/* Defined here for convenience */
export class ErrorResponse {
  statusCode: string;
  errorMessage: string;
  errorObject?: Object;

  constructor() {
    this.statusCode = null;
    this.errorMessage = null;
  }
}
