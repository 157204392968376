import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-confirm',
  styleUrls: ['./confirm.component.scss'],
  templateUrl: './confirm.component.html'
})
export class ConfirmComponent implements OnInit, AfterViewInit, OnDestroy {
  confirmTitle!: string;
  confirmMsg!: any;
  itemList?: string[];
  record: any;
  typeConfirm!: boolean | '';
  ok: string = 'OK';
  cancel: string = 'Cancel';
  confirmInput: UntypedFormControl = new UntypedFormControl('');
  cb!: (record: any) => void;
  close: () => void = () => { };
  confirmed$!: Subject<boolean>;
  formValid: boolean = true;
  markdown = false;
  @ViewChild('okBtn') okBtn!: ElementRef;
  @ViewChild('cancelBtn') cancelBtn!: ElementRef;

  destroyed$ = new Subject();

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    // this.auth.loggedIn$
    //   .pipe(takeUntil(this.destroyed$))
    //   .subscribe(loggedIn => { if (!loggedIn) this.hide(); });

    if (this.typeConfirm) {
      this.confirmInput.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe(value => {
          this.okBtn.nativeElement.disabled = value !== this.typeConfirm;
        });
    }
  }

  ngAfterViewInit() {
    if (this.typeConfirm) this.okBtn.nativeElement.disabled = true;
  }

  confirm(doCB: boolean = true) {
    if (doCB && !!this.cb) {
      this.cb(this.record);
    }

    if (this.confirmed$) {
      this.confirmed$.next(true);
    }

    this.hide();
  }

  hide() {
    this.bsModalRef.hide();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
