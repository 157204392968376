import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ellipsis',
  pure: false
})
export class EllipsisPipe implements PipeTransform {

  transform(value: any, limit: number, respectWords: boolean = true): any {
    if (typeof value !== 'string') {
      value = value.toString();
    }

    if (value.length < limit || value.length === limit) {
      return value;
    }

    if (!respectWords) {
      return `${value.slice(0, limit)}...`;
    }

    const isSpace = value.charAt(limit) === ' ';

    // We are breaking right on a space, which is what we want!
    if (isSpace) {
      return `${value.slice(0, limit)}...`;
    }

    // It's not a space, fast forward until we find the next space
    while (limit < value.length) {
      ++limit;
      if (value.charAt(limit) === ' ') {
        break;
      }
    }

    // If we found the end of the string just return the whole string
    if (value.length === limit) {
      return value;
    }

    // Our new limit should be breaking on a space, so we can return now.
    return `${value.slice(0, limit)}...`;
  }
}
