import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../models/user';
import { UserService, AuthResponse, ChangePasswordRequest } from '../services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as R from 'ramda';
import { Person } from "../models/person";
import { ErrorResponse } from '../services/api.service';
import { omit as _omit } from 'lodash';
import { NotifyService } from '@services/notify.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  user: any;
  _user: User;
  userForm: FormGroup;
  passwordForm: FormGroup;
  alert: any;
  userPass: any;
  passUpdatePayload: ChangePasswordRequest;
  loading = false;
  whichLoading: 'save' | 'password';
  destroyed$ = new Subject();
  asUser?: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private notify: NotifyService
  ) {
    this.userForm = this.fb.group({
      first_name: [''],
      last_name: [''],
      email: [''],
      phone: [''],
      address: [''],
      city: [''],
      state: [''],
      zip: [''],
    });

    this.passwordForm = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required]
    });

    this.notify.loading$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(loading => this.loading = loading);

    this.asUser = this.activatedRoute.snapshot.params.as_user;
  }

  ngOnInit() {
    this.alert = null;
    this.user = this.activatedRoute.snapshot.data['user'];
    this._user = this.user ? R.clone(this.user) : new User({ person: new Person() });
    this.userPass = { password: null, confirmPass: null };
    this.passUpdatePayload = { id: this.user?.id, email: this.user?.email, oldPassword: null, newPassword: null };
    setTimeout(() => {
      this.userForm.patchValue(_omit(this._user?.person, ['phone']));

      this.userForm.patchValue({
        email: this._user.email,
        phone: this._user.person?.phone || ''
      });
    }, 0);
  }

  /* Database Methods */

  updatePassword(form: any) {
    this.notify.loading = true;
    this.whichLoading = 'password';

    this.passUpdatePayload.oldPassword = form.currentPassword;
    this.passUpdatePayload.newPassword = form.newPassword;

    if (this.asUser) {
      this.userService.updateUserPassword(this.passUpdatePayload)
        .then((res) => {
          this.notify.loading = false;
          if (res.errorMessage) {
            this.showAlert(res.errorMessage + ' There was an error saving! Please check your information and try again.', false);
          } else {
            this.showAlert('Password has been updated.', true);
          }
        })
        .catch((err: ErrorResponse) => {
          this.showAlert('There was an error saving! Please check your information and try again.', false);
        });
    } else {
      this.userService.login(this._user.email, form.currentPassword)
        .then((user: AuthResponse) => {
          if (!user.user) {
            this.showAlert('There was an error! Please check your credentials and try again.', false);
          } else {
            this.userService.updateUserPassword(this.passUpdatePayload)
              .then((res) => {
                this.notify.loading = false;
                if (!res) {
                  this.showAlert(res.errorMessage + ' There was an error saving! Please check your information and try again.', false);
                } else {
                  this.showAlert('Your password has been updated. You will now be logged out!', true);
                  setTimeout(() => {
                    this.router.navigate(['login']);
                  }, 3000);
                }
              })
              .catch((err: ErrorResponse) => {
                this.showAlert('There was an error saving! Please check your information and try again.', false);
              });
          }
        })
        .catch((err: ErrorResponse) => {
          this.showAlert(err['error'].errorMessage, false);
        });
    }
  }

  updateUser(form: any) {
    this.notify.loading = true;
    this.whichLoading = 'save';
    this._user.person = { ...this._user.person, ...form };
    this._user.email = form.email;
    this._user.person.phone = `+1${(form.phone || '').replace(/\D/g, '')}`;

    this.userService.updateUser(this._user, this.asUser)
      .then((res) => {
        this.notify.loading = false;
        if (!res) {
          this._user = this.user;
          this.showAlert('There was an error saving! Please check your information and try again.', false);
          return;
        }
        // move person into user object because we made bad decisions early on
        res.user.person = res?.person;
        this.user = res.user;
        this._user = res.user;
        this.showAlert('Your changes have been successfully saved!', true);
      })
      .catch((err: ErrorResponse) => {
        this.notify.loading = false;
        this._user = this.user;
        this.showAlert('There was an error saving! Please check your information and try again.', false);
      });
  }

  showAlert(message: string, success: boolean) {
    this.notify.toast({
      id: 'update-user-settings-error',
      msg: message,
      type: 'warning',
      update: true
    });
  }

  confirmResetPassword() {
    this.userService.confirmResetPassword(this.user?.email)
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
