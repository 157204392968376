import { pick as _pick } from 'lodash';
import { Base } from './base';
import { Package } from './package';

export class Location extends Base {
  name: string;
  description: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  geocode: string;
  migrate: boolean;
  phone: string;
  open: string;
  close: string;
  node_id: string;
  sm_folder_obj: any;
  cal_email?: string;
  cal_error?: boolean;
  lat?: number;
  lng?: number;
  distance?: number;
  tokens?: boolean;
  photo?: string;
  photoSigned?: string;
  timezone?: string;
  open_for_booking: boolean;
  closed_message: string;
  active: boolean;
  packages: Package[] = [];

  constructor(data?: Partial<Location>) {
    super(data);

    Object.assign(this, _pick(data, [
      'name',
      'description',
      'address',
      'city',
      'state',
      'zip',
      'geocode',
      'migrate',
      'open',
      'close',
      'node_id',
      'sm_folder_obj',
      'cal_email',
      'cal_error',
      'lat',
      'lng',
      'distance',
      'tokens',
      'phone',
      'photo',
      'photoSigned',
      'timezone',
      'open_for_booking',
      'closed_message',
      'active'
    ]));

    this.phone = data?.phone ? data?.phone.replace('+1', '') : null;
    this.packages = data?.packages ? data.packages.map(pkg => new Package(pkg)) : null;
  }
}