import { Component, ViewEncapsulation, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NotifyService } from '@services/notify.service';
import { Subscription } from 'rxjs';
import { Toast } from './toast';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToastsComponent implements OnInit, OnDestroy {

  toast_subscription: Subscription | undefined;
  toasts: Toast[] = [];

  icon = {
    danger: 'fa-exclamation',
    success: 'fa-check',
    warning: 'fa-exclamation-triangle',
    info: 'fa-info-circle',
    bomb: 'fa-bomb',
  };
  size = {
    sm: '11px',
    md: '14px',
    lg: '18px',
    xl: '30px'
  };

  constructor(
    private notify: NotifyService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.toast_subscription = this.notify.toasts$.subscribe((toasts: Toast[]) => {
      this.toasts = toasts;
      this.ref.detectChanges();
    });
  }

  closeToast(closeToast: Toast): void {
    const i = this.toasts.findIndex((toast: Toast) => toast === closeToast);
    this.toasts.splice(i, 1);
    this.notify.update(this.toasts);
    this.ref.detectChanges();
  }

  trackByFn = (i: number, toast: Toast) => toast;
  
  ngOnDestroy() {
    if (this.toast_subscription) this.toast_subscription.unsubscribe();
  }
}
