import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MainTableComponent } from '../main-table.component';
import { NotifyService } from '@services/notify.service';

@Component({
  selector: 'app-table-mobile',
  templateUrl: './table-mobile.component.html',
  styleUrls: ['./table-mobile.component.scss']
})
export class TableMobileComponent extends MainTableComponent {
  @Input() maxSize = 5;

  constructor(
    public modalService: BsModalService,
    public notify: NotifyService
  ) {
    super(modalService, notify)
  }
}
