import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { timeSlots } from './time-slots';
import { BookingService } from '@services/booking.service';
import { DateTime } from 'luxon';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { AppointmentService } from '@services/appointment.service';
import {
  cloneDeep as _cloneDeep
} from 'lodash';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '@services/api.service';
import { NotifyService } from '@services/notify.service';
import { Appointment } from '@models/appointment';
import { Subject, forkJoin, interval } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FreeBusyService } from '@services/free-busy.service';
import { OpenHoursService } from '@services/open-hours.service';
import { formatTime } from 'app/utils/format-time';

@Component({
  selector: 'app-book-time',
  templateUrl: './book-time.component.html',
  styleUrls: ['./book-time.component.scss']
})
export class BookTimeComponent implements OnInit, OnDestroy {
  lastDate: Date;
  timeSlots = _cloneDeep(timeSlots);
  selectedTime: { name: string, value: string };
  loading = false;
  bookingForm: FormGroup;
  openHoursForm: FormGroup;
  reschedule = false;
  currentAppointment: Appointment;
  currentDate = DateTime.now().startOf('day').toJSDate();
  currentTime = DateTime.now().toFormat('HH:mm:ss');
  dateString: string;
  @Input() date: Date;
  @Input() overrides: boolean | '' = false;
  @Input() heading: string = 'Select date and time';
  @Input() location?: string;
  @Input() error: boolean;
  @Input() description: string = 'Select your preferred date and time below. All appointments are 5 minutes long.';
  @ViewChild('dayPartTabs', { static: false }) dayPartTabs?: TabsetComponent;

  resetOpenHours$ = new Subject();
  destroyed$ = new Subject();

  constructor(
    private appointment: AppointmentService,
    private freeBusy: FreeBusyService,
    private openHours: OpenHoursService,
    private booking: BookingService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    private notify: NotifyService,
    private fb: FormBuilder
  ) {
    this.bookingForm = this.booking.bookingForm;
    this.reschedule = route.snapshot?.url?.[0]?.path === 'reschedule';
    this.currentAppointment = route.snapshot.data.appointment;

    if (this.date) {
      this.dateString = DateTime.fromJSDate(this.date).toFormat('yyyy-MM-dd');
    }

    interval(1000 * 15)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.currentDate = DateTime.now().startOf('day').toJSDate();
        this.currentTime = DateTime.now().toFormat('HH:mm:ss');
        this.getUnavailableTimes({});
      });

    this.notify.loading$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(loading => this.loading = loading);
  }

  ngOnInit() {
    this.openHoursForm = this.fb.group({});
    this.setOpenHoursForm();
  }

  ngAfterViewInit() {
    if (this.booking.bookingForm?.value?.date) {
      setTimeout(() => {
        const [date, time] = this.booking.bookingForm.value?.date.split('T');
        this.date = DateTime.fromFormat(date, 'yyyy-MM-dd').startOf('day').toJSDate();
        this.lastDate = this.date;
        this.getUnavailableTimes({});

        this.timeSlots.forEach((dayPart) => {
          dayPart.value.forEach(hour => {
            const selectedTime = hour.value.find(slot => slot.value === time);
            if (selectedTime) {
              this.selectedTime = selectedTime;
              switch (dayPart.name) {
                case 'Morning':
                  this.dayPartTabs.tabs[0].active = true;
                  break;
                case 'Afternoon':
                  this.dayPartTabs.tabs[1].active = true;
                  break;
                case 'Evening':
                  this.dayPartTabs.tabs[2].active = true;
                  break;
              }
            }
          });
        }, []);
      }, 0);
    }
  }

  getOpenHours(date: string) {
    const openHoursControl = this.openHoursForm.get(`${date}`);
    const locationID = this.route.snapshot.data?.location?.id;
    this.openHours.get(date, locationID)
      .subscribe(openHours => {
        if (openHours) {
          openHoursControl.get('id').enable();
          openHoursControl.patchValue({ id: openHours.id, open: formatTime(openHours.open), close: formatTime(openHours.close) });
          openHoursControl.updateValueAndValidity();
        }
      });

    if (this.isOverrides) {
      this.openHoursForm.get(`${date}.blockAll`)
        .valueChanges
        .pipe(takeUntil(this.resetOpenHours$))
        .subscribe((setAll) => {
          this.setAllOverrides(setAll);
        });
    }
  }

  setOpenHoursForm() {
    const date = DateTime.fromJSDate(this.date).toFormat('yyyy-MM-dd');
    const { open, close } = this.booking.bookingForm?.value?.location || { open: null, close: null };

    if (!this.openHoursForm.value[date]) {
      this.openHoursForm.addControl(date, this.fb.group({
        id: [{ value: undefined, disabled: true }],
        open: [formatTime(open), Validators.required],
        close: [formatTime(close), Validators.required],
        date: [date, Validators.required],
        location_id: [this.route.snapshot.data?.location?.id],
        active: [true],
        blockAll: [{ value: false, disabled: this.date < this.currentDate }]
      }));

      this.openHoursForm.get(`${date}.blockAll`)
        .valueChanges
        .pipe(takeUntil(this.resetOpenHours$))
        .subscribe((setAll) => {
          this.setAllOverrides(setAll);
        });

      this.getOpenHours(date);
      this.getUnavailableTimes({});
    }
  }

  get isOverrides() {
    return this.overrides || this.overrides === '';
  }

  get formOverrides() {
    return this.bookingForm?.value?.overrides || [];
  }

  setDate() {
    //reset date and time if new date selected
    if (this.date !== this.lastDate) {
      this.selectedTime = null;
      this.booking.bookingForm.get('date')
        .setValue(null);
      this.getUnavailableTimes({ toast: true });
      this.lastDate = this.date;
      this.dateString = DateTime.fromJSDate(this.date).toFormat('yyyy-MM-dd');
      this.setOpenHoursForm();
      const locationId = this.route.snapshot.data?.location?.id;
      if (this.isOverrides) {
        this.router.navigate(['/admin', 'calendar', 'overrides', locationId, this.dateString]);
      }
    }
  }

  getUnavailableTimes({ reset = false, toast = false }) {
    if (this.date) {
      const date = DateTime.fromJSDate(this.date).toFormat('yyyy-MM-dd');
      const currentDate = DateTime.fromJSDate(this.currentDate).toFormat('yyyy-MM-dd');
      const start = DateTime.fromJSDate(this.date).startOf('day').toFormat('yyyy-MM-dd HH:mm:ssZ').replace(' ', 'T');
      const end = DateTime.fromJSDate(this.date).endOf('day').toFormat('yyyy-MM-dd HH:mm:ssZ').replace(' ', 'T');
      const location = this.booking.bookingForm?.value?.location;

      forkJoin({
        booked: this.appointment.getBookedAppt(start, end, location?.id),
        busy: this.freeBusy.getFreeBusy(start, end, location?.id),
        openHours: this.openHours.get(date, location?.id)
      })
        .subscribe(({ booked, busy, openHours }) => {
          const open = formatTime(openHours?.open || location?.open),
            close = formatTime(openHours?.close || location?.close);

          if (reset) {
            (this.bookingForm.get('overrides') as FormArray).clear();
          }

          // allow selecting booked date if date and time match this rescheduling
          if (this.reschedule) {
            booked.data = (booked.data || []).filter(appointment => {
              const bookedDate = DateTime.fromISO(appointment.date).toFormat('yyyy-MM-dd HH:mm:ss').replace(' ', 'T');
              const selectedDate = this.bookingForm.value.date;
              return bookedDate !== selectedDate;
            });
          }

          const busyTimes = (busy || [])
            .filter(ots => !ots.deleted_at)
            .map(freeBusy => DateTime.fromISO(freeBusy.date).toFormat('HH:mm:ss'));
          const overrideTimes = (busy || []).map(freeBusy => DateTime.fromISO(freeBusy.date).toFormat('HH:mm:ss'));
          const times = (booked.data || []).map(appointment => DateTime.fromISO(appointment.date).toFormat('HH:mm:ss'));
          let showBooked = true;

          this.timeSlots.forEach(dayPart => {
            dayPart.disabled = true;
            dayPart.value.forEach(hour => {
              hour.disabled = true;
              hour.value.forEach(slot => {
                if (slot.value >= open && slot.value < close) {
                  dayPart.disabled = false;
                  hour.disabled = false;
                }

                switch (true) {
                  case (slot.value < open || slot.value >= close): // outside open and close hours
                  case (date === currentDate && slot.value < this.currentTime): // times have passed
                  case (date < currentDate): // date has passed
                  case (times.includes(slot.value)): // appointment booked for this date and time
                  case (!this.isOverrides && busyTimes.includes(slot.value)): // override times disabled when booking
                    slot.disabled = true;
                    break;
                  default:
                    slot.disabled = false;
                    showBooked = false;
                    break;
                }

                if (this.isOverrides && overrideTimes.includes(slot.value)) {
                  const overrideIndex = overrideTimes.findIndex(time => time === slot.value);
                  const freeBusy = busy[overrideIndex];

                  const index = this.formOverrides.findIndex(override => override.date === `${date}T${slot.value}`);

                  if (index === -1) {
                    const overrides = this.booking.bookingForm.get('overrides') as FormArray;
                    overrides
                      .push(this.fb.group({
                        id: this.fb.control(freeBusy.id),
                        date: this.fb.control(`${date}T${slot.value}`),
                        start: this.fb.control(slot.value),
                        location_id: this.fb.control(freeBusy.location_id),
                        deleted_at: this.fb.control(freeBusy.deleted_at),
                        selected: this.fb.control(!freeBusy.deleted_at),
                        delete: this.fb.control(null)
                      }));
                  }
                }
              });
            });
          });

          if (!this.isOverrides) {
            if (showBooked && toast) {
              this.notify.toast({
                id: 'fully-booked',
                msg: 'Santa is fully booked that day.',
                type: 'success',
                timeout: 10000,
                update: true
              });
            }
          } else {
            let notAll = false;
            let allDisabled = true;
            this.timeSlots.forEach(dayPart => {
              dayPart.value.forEach(hour => {
                hour.value.forEach(slot => {
                  const override = this.formOverrides.find(override => override.date === `${date}T${slot.value}`);
                  notAll = (!slot.disabled && !override?.selected) || notAll;
                  if (!slot.disabled) allDisabled = false;
                });
              });
            });
            const control = this.openHoursForm.get(`${this.dateString}.blockAll`);
            control.setValue(!notAll && !allDisabled, { emitEvent: false });
          }
        });
    }
  }

  setTime(selectedTime) {
    // set appointment date and time if both selected
    if (this.date) {
      const date = DateTime.fromJSDate(this.date).toFormat('yyyy-MM-dd');
      const time = selectedTime;
      this.booking.bookingForm.get('date')
        .setValue(`${date}T${time.value}`)

      this.selectedTime = selectedTime;
    }
  }

  setAllOverrides(setAll: boolean) {
    const location = this.booking.bookingForm?.value?.location,
      openHours = this.openHoursForm.get(this.dateString).value,
      open = formatTime(openHours?.open || location?.open),
      close = formatTime(openHours?.close || location?.close),
      date = DateTime.fromJSDate(this.date).toFormat('yyyy-MM-dd'),
      currentDate = DateTime.fromJSDate(this.currentDate).toFormat('yyyy-MM-dd');

    this.timeSlots.forEach(dayPart => {
      dayPart.value.forEach(hour => {
        hour.value.forEach(slot => {
          switch (true) {
            case (slot.value < open || slot.value >= close): // outside open and close hours
            case (date === currentDate && slot.value < this.currentTime): // times have passed
            case (date < currentDate): // date has passed
              break;
            default:
              if (!slot.disabled) {
                this.setOverride(slot, setAll);
              }
              break;
          }
        });
      });
    });
  }

  setOverride(time, setAll?: boolean) {
    if (this.date) {
      const date = DateTime.fromJSDate(this.date).toFormat('yyyy-MM-dd');
      const overrides = this.booking.bookingForm.get('overrides') as FormArray;
      const index = overrides.value.findIndex(override => override.date === `${date}T${time.value}`);
      const location = this.booking.bookingForm?.value?.location;

      if (index === -1) {
        // add new override
        overrides
          .push(this.fb.group({
            date: this.fb.control(`${date}T${time.value}`),
            start: this.fb.control(time.value),
            location_id: this.fb.control(location.id),
            delete: this.fb.control(false),
            selected: this.fb.control(setAll !== undefined ? setAll : true)
          }));
      } else {
        // update existing overrides a null value will prevent the record from being sent in the update.
        const override = overrides.get(index.toString()) as FormGroup;
        let yesDelete = override.value.deleted_at === null ? true : null;
        let noDelete = override.value.deleted_at ? false : null;

        // update existing records
        if (override?.value?.id || setAll) {
          override.patchValue({
            delete: setAll === true ? noDelete : yesDelete,
            selected: setAll !== undefined ? setAll : !override.value.selected
          });
        } else {
          // remove override if not in database
          overrides.removeAt(index);
        }
      }
    }
  }

  rescheduleAppointment() {
    if (this.bookingForm?.valid) {
      this.notify.loading = true;
      const date = DateTime.fromISO(this.bookingForm.value?.date);
      const value = Object.assign({}, this.currentAppointment, {
        id: this.bookingForm.value?.id,
        date: date.toISO()
      });

      this.http.post(`/appointment/update/${value.id}`, value)
        .subscribe((res) => {
          this.notify.loading = false;
          window.history.back();
        });
    } else {
      this.notify.toast({
        id: 'reschedule-form-error',
        title: 'Rescheduling Error',
        msg: 'Please verify a date and time have been selected.',
        type: 'warning'
      });
    }
  }

  saveOverrides() {
    this.notify.loading = true;
    forkJoin({
      openHours: this.openHours.bulkSave(this.openHoursForm.value, this.route.snapshot.data?.location),
      freeBusy: this.freeBusy.bulkSaveFreeBusy(this.bookingForm.value.overrides, this.route.snapshot.data?.location?.id)
    })
      .pipe(map(res => ({ openHours: res.openHours?.data, freeBusy: res.freeBusy.data })))
      .subscribe(({ freeBusy, openHours }) => {
        const { newFreeBusy, updatedFreeBusy, deletedFreeBusy } = freeBusy;
        const { newHours, updatedHours } = openHours;
        this.notify.loading = false;
        const count = (newFreeBusy?.length || 0) + (updatedFreeBusy.length || 0) + (deletedFreeBusy.length || 0);
        const hoursCount = (newHours?.length || 0) + (updatedHours?.length || 0);
        let msg = '';
        if (count) msg += `Successfully updated ${count} calendar override${count === 1 ? '' : 's'}.`;
        if (hoursCount) msg += `Successfully updated ${hoursCount} daily hour${hoursCount === 1 ? '' : 's'}.`;

        if (count + hoursCount) {
          this.notify.toast({
            id: 'updated-free-busy',
            title: 'Updated calendar overrides',
            msg,
            type: 'success',
            update: true
          });
        } else {
          this.notify.toast({
            id: 'no-update-free-busy',
            title: 'Update calendar',
            msg: `No calendar overrides or hours have been updated.`,
            type: 'success',
            update: true
          });
        }
        this.getUnavailableTimes({ reset: true });
        this.resetOpenHours$.next();
        const date = DateTime.fromJSDate(this.date).toFormat('yyyy-MM-dd');
        const hours = this.openHoursForm?.value?.[date];
        this.openHoursForm = this.fb.group({
          [date]: this.fb.group({
            id: [{ value: hours.id, disabled: !hours.id }],
            open: [formatTime(hours.open), Validators.required],
            close: [formatTime(hours.close), Validators.required],
            date: [hours.date, Validators.required],
            location_id: [hours.location_id],
            active: [hours.active],
            blockAll: [{ value: hours.blockAll, disabled: this.date < this.currentDate }]
          })
        });
        this.getOpenHours(date);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.resetOpenHours$.next();
    this.resetOpenHours$.complete();
  }
}