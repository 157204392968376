import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Package } from '@models/package';

@Component({
  selector: 'app-package-cards',
  templateUrl: './package-cards.component.html',
  styleUrls: ['./package-cards.component.scss']
})
export class PackageCardsComponent {
  @Input() packages: Package[] = [];
  @Input() locationId?: number;
  @Input() bookingForm?: FormGroup;

  constructor() { }

  selectPackage(selectedPackage: Package) {
    if (this.bookingForm) this.bookingForm.patchValue({ pkg: selectedPackage });
  }

  trackByFn = (i) => i;
}
