<div class="row">
  <div
    *ngFor="let package of packages; let i = index"
    class="col-lg-6 col-xl-4 pb-3"
  >
    <div
      (click)="selectPackage(package)"
      class="card h-100 overflow-hidden"
      [ngClass]="{ selected: package.id === bookingForm?.value?.pkg?.id }"
    >
      <img
        [src]="
          package.photoSigned ||
          '/assets/images/book-appointment/santa-image.jpg'
        "
        class="pkg-thumb"
        appFadeImg
      />
      <div class="card-body">
        <h2>{{ package.name }}</h2>
        <h3>{{ package.price | currency : "USD" : "symbol" : "1.0" }}</h3>
        <ul class="feature-list">
          <ng-container *ngFor="let deliverable of package.deliverables; trackBy: trackByFn">
            <li *ngIf="deliverable?.id">
              <img src="/assets/images/book-appointment/icon-checkmark.svg" />
              {{ deliverable.name }}
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="card-body d-flex align-items-end">
        <button
          *ngIf="locationId; else packageSelect"
          [routerLink]="'/admin/packages/' + locationId + '/' + package.id"
          type="button"
          class="btn btn-lg btn-light text-uppercase w-100"
        >
          Edit Package
        </button>
        <ng-template #packageSelect>
          <button
            (click)="selectPackage(package)"
            type="button"
            class="btn btn-lg btn-light text-uppercase w-100"
          >
            {{
              package.id === bookingForm?.value?.pkg?.id
                ? "Package Selected"
                : "Select Package"
            }}
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
