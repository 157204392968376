import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'capitalize',
  pure: false
})
export class CapitalizePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return `${value.slice(0, 1).toUpperCase()}${value.slice(1)}`;
  }
}
