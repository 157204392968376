import {Component, EventEmitter, Output, Input} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Cart} from "../../models/cart";

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent {
  @Output() goToProfile = new EventEmitter();
  @Output() signOut = new EventEmitter();
  @Output() toPage = new EventEmitter<String>();
  @Input() cart:Cart[];

  constructor(private userService: UserService) {
  }

  active: boolean = false;

  logOut() {
    this.active = false;
    this.signOut.emit();
  }

  navigate(page: string) {
    this.active = false;
    this.toPage.emit(page);
  }

  onClickOutside(event: Object) {
    if (event && event['value'] === true) {
      this.active = false;
    }
  }

}
