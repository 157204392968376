import { pick as _pick } from 'lodash';
import { Base } from './base';
import { Location } from './location';

export class FreeBusy extends Base {
  date: string;
  start: string;
  end: string;
  description: number;
  location_id: number;
  location: Location;
  delete?: boolean;

  constructor(data?: Partial<FreeBusy>) {
    super(data);

    Object.assign(this, _pick(data, [
      'date',
      'start',
      'end',
      'description',
      'location_id',
      'location',
      'delete'
    ]));
    
    this.location = data?.location ? new Location(data.location) : null;
  }
}