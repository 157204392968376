import { Injectable } from '@angular/core';
import { HttpService } from './api.service';

import { Appointment } from '../models/appointment';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CheckinService {

  constructor(private http: HttpService) {
  }

  /**
   * Get Appointments
   * res [{}]
   */
  getAppointments(itemsPerPage, offset, checkinDate: DateTime): Observable<any> {
    const params = JSON.parse(JSON.stringify({
      itemsPerPage,
      offset
    }));

    if (checkinDate) {
      params.date = checkinDate;
    }

    return this.http.get(`/appointment/all/checkin`, params);
  }

  searchAppointments(
    { search, location, date, checkedIn, flagged }: { search: string, location: string, date: string, checkedIn: string, flagged: string },
    itemsPerPage: number, offset: number
  ): Observable<any> {
    const params = JSON.parse(JSON.stringify({
      search,
      location,
      date,
      checkedIn,
      flagged,
      itemsPerPage,
      offset
    }));

    return this.http.get(`/appointment/search`, params);
  }

  /**
   * Get all flagged appts.
   *
   * @param itemsPerPage
   * @param offset
   * @returns {Promise<Appointment[]>}
   */
  getFlaggedAppointments(itemsPerPage: any, offset: any): Promise<any> {
    const params = JSON.parse(JSON.stringify({
      itemsPerPage,
      offset
    }));

    return this.http.get(`/appointment/all/flagged`, params)
      .toPromise()
      .then((res) => {
        return res.data;
      })
  }

  /**
   * Create a Single Appointment
   * req: appointment:Appointment
   * res {}
   */
  createAppointment(appointment: Appointment): Promise<Appointment> {
    return this.http.post(`/appointment`, appointment)
      .toPromise()
      .then((res) => {
        return res.data as Appointment;
      })
  }

  /**
   * Update a Single Appointment
   * req: appointment:Appointment
   * res {}
   */
  updateAppointment(appointment: Appointment): Promise<Appointment> {
    return this.http.put(`/appointment/id/${appointment.id}`, appointment)
      .toPromise()
      .then((res) => {
        return res.data as Appointment;
      })
  }

  /**
   * Get User Appointments
   * res [{}]
   */
  getUserAppointments(userId: number): Promise<Appointment[]> {
    return this.http.get(`/appointment/get-user-appointments/${userId}`)
      .toPromise()
      .then((res) => {
        return res.data as Appointment[];
      })
  }

  getAppointmentTimeEstimates(locationId: number) {
    return this.http.get(`/appointment/time-estimate/${locationId}`);
  }
}
